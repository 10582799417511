import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation UpdateTemporaryAvailabilityLimitation($id: ID!, $input: UpdateTemporaryAvailabilityLimitationInput!) {
    updateTemporaryAvailabilityLimitation(id: $id, input: $input) {
      id
      service
      startTime
      endTime
      capacity
      message
      isDeleted
      branchId
      createdAt
    }
  }
`);

export default function useUpdateTemporary() {
  return useMutation(MUTATION, {
    refetchQueries: ['getTemporaryAvailabilityLimitations'],
  });
}
