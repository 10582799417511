import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

export const COMPANIES_CONTRACT_QUERY = graphql(`
  query CompaniesWithContractStatus($offset: Int!, $limit: Int!) {
    companyContractStatus(offset: $offset, limit: $limit) {
      id
      name
      category
      companyContractStatusEnum
    }
  }
`);

export default function useCompaniesWithContractStatus(offset: number, limit: number) {
  return useQuery(COMPANIES_CONTRACT_QUERY, {
    variables: {
      offset,
      limit,
    },
  });
}
