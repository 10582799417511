import { useAuth } from '@client/module/auth/auth-context.ts';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '@packages/ui/loading.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import { useTranslate } from '@tolgee/react';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import {
  ContractCategoryFragment,
  ContractChangeFragment,
  ContractFeeChangeFragment,
  ContractFeeFragment,
  ContractFragment,
} from '@client/graphql/contract-fragment.ts';
import { getFragmentData } from '@client/graphql/types';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import ContractFeeTable from '@client/page/management/contract/contract-create/component/contract-fee-table.tsx';
import ContractChange, {
  ContractFeeChangeWithTitle,
} from '@client/page/management/contract/contract-detail/component/contract-change.tsx';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { useUpdateContract } from '@client/page/management/contract/contract-update/logic/use-update-contract.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { paymentData } from '@client/page/management/contract/contract-detail/component/payment-data.ts';
import classNames from 'classnames';
import { useContractWithAllFee } from '@client/page/management/contract/contract-detail/logic/use-contract-with-all-fees.ts';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

export default function ContractDetail() {
  const contractId = useParams().contractId ?? '';
  const companyId = useParams().companyId ?? '';

  const navigate = useNavigate();
  const { auth } = useAuth();
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();

  const { data, error, loading, refetch } = useContractWithAllFee(contractId);
  const [update] = useUpdateContract();

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <>Empty data</>;
  }

  if (error) {
    return <ApplicationErrorView error={error} />;
  }

  const contract = getFragmentData(ContractFragment, data.contractWithAllFees);
  const contractCategory = getFragmentData(ContractCategoryFragment, contract.companyContractCategory);

  const contractFee = getFragmentData(ContractFeeFragment, contract.companyContractFee) ?? [];
  const filterActiveContractFee = contractFee.filter((item) => !item.isDeleted);

  const contractChange = getFragmentData(ContractChangeFragment, contract.companyContractChange);
  const contractFeeChangeWithTitle: ContractFeeChangeWithTitle[] = contractFee.flatMap((item) => ({
    title: item.title,
    change: getFragmentData(ContractFeeChangeFragment, item.companyContractFeeChange),
  }));

  const isContractManager = auth?.role.includes('CONTRACT_MANAGER');

  if (!auth?.role.includes('ADMIN') && !isContractManager) {
    return <>Permission denied</>;
  }

  const onUpdate = (hasPayed: boolean) => {
    update({
      variables: {
        id: contractId,
        contractData: {
          hasPayed,
        },
      },
    })
      .then(() => {
        showAlert({
          title: t('contract-detail.update.contract-status.update.success', 'Successful'),
          status: 'success',
          message: t('contract-detail.update.contract-status.update.success.mess', 'Contract has been updated'),
        });
        void refetch();
      })
      .catch(() => {
        showAlert({
          title: t('contract-detail.update.contract-status.update.error', 'Error'),
          status: 'error',
          message: t('contract-detail.update.contract-status.update.error.mess', 'Something went wrong'),
        });
      });
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader
            title={t('contract.contract-detail.header', 'Contract')}
            withBackButton={true}
            backTarget={`/contract-management/${companyId}/contracts`}
          >
            {isContractManager && (
              <SecondaryButton
                onClick={() => {
                  navigate('update');
                }}
              >
                {t('contract.contract-detail.button.edit-confirm', 'Edit')}
              </SecondaryButton>
            )}
          </CardHeader>
          <CardContent>
            <div className="space-y-8">
              <div className="flex flex-col sm:flex-row gap-y-8 gap-x-16">
                <ContractItem
                  label={t('contract.contract-detail.contract-number.title', 'Contract number')}
                  value={contract.id}
                />
                <ContractItem
                  label={t('contract.contract-detail.contract-category.title', 'Contract category')}
                  value={contractCategory.title}
                />
              </div>

              <ContractTimeItem startTime={contract.startTime} endTime={contract.endTime} />

              <div className="flex flex-col sm:flex-row gap-y-8 gap-x-16">
                <ContractItem
                  label={t('contract.contract-detail.amount.title', 'Amount')}
                  value={formatCurrency(contract.paymentAmount, contract.paymentCurrency)}
                />
                {isContractManager ? (
                  <ContractHasPayedItem label={t('contract.contract-detail.payment-status.title', 'Payment status')}>
                    <MenuSelectionInput
                      title={''}
                      data={paymentData}
                      value={paymentData.find((item) => item.value == contract.hasPayed) ?? paymentData[0]}
                      onChange={(newValue) => {
                        if (newValue.value == contract.hasPayed) {
                          return;
                        }
                        onUpdate(newValue.value);
                      }}
                      build={(item: { title: string; value: boolean }) => {
                        return {
                          id: item.value.toString(),
                          name: t('contract.contract-detail.payment-status.' + item.title),
                          suffixElement: item.value ? (
                            <CheckCircleIcon className="w-6 h-6 text-green-600" />
                          ) : (
                            <ExclamationCircleIcon className="w-6 h-6 text-red-600" />
                          ),
                        };
                      }}
                    />
                  </ContractHasPayedItem>
                ) : (
                  <ContractItem
                    label={t('contract.contract-detail.payment-status.title', 'Payment status')}
                    value={
                      contract.hasPayed
                        ? t('contract.contract-detail.payment-status.payed', 'Payed')
                        : t('contract.contract-detail.payment-status.not-payed', 'Not payed')
                    }
                  />
                )}
              </div>

              <ContractItem
                label={t('contract.contract-detail.contract-name.title', 'Contract name')}
                value={contract.serviceTitle}
              />

              {contract.customerNote && (
                <ContractItem
                  label={t('contract.contract-detail.contract-des.title', 'Note')}
                  value={contract.customerNote}
                  description={true}
                />
              )}

              <ContractItem
                label={t('contract.contract-detail.customer-name.title', 'Customer name')}
                value={contract.customerName}
              />
            </div>
          </CardContent>
        </Card>

        <ContractFeeTable
          contractData={contract}
          data={filterActiveContractFee.map((item) => ({
            title: item.title,
            note: item.note,
            amount: item.amount,
            currency: item.currency,
          }))}
        />

        {contractChange && contractChange.length > 0 && (
          <ContractChange contractChange={contractChange} contractFeeChange={contractFeeChangeWithTitle} />
        )}
      </PrimaryContent>
    </ContentLayout>
  );
}

export function ContractItem(props: { label: string; value: string; className?: string; description?: boolean }) {
  return (
    <div className={props.className}>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center sm:min-w-44">
        <div className="flex-1">
          <p className="mb-2 text-sm text-gray-800">{props.label}</p>
          <p
            className={classNames(
              'text-lg ml-0.5 md:max-w-md break-all text-ellipsis',
              !props.description && 'line-clamp-2'
            )}
          >
            {props.value}
          </p>
        </div>
      </div>
    </div>
  );
}

export function ContractHasPayedItem(props: { label: string; children: React.ReactNode }) {
  return (
    <div className="flex flex-col md:flex-row justify-between items-start md:items-center sm:min-w-44">
      <div className="flex-1">
        <p className="mb-2 text-sm text-gray-800">{props.label}</p>
        <p className="text-lg ml-0.5 md:max-w-md  break-all text-ellipsis">{props.children}</p>
      </div>
    </div>
  );
}

function ContractTimeItem(props: { startTime: string; endTime: string }) {
  const { startTime, endTime } = props;
  const { t } = useTranslate();

  return (
    <div className="flex flex-col sm:flex-row gap-x-32 gap-y-8">
      <div>
        <p className="mb-2 text-sm text-gray-800">{t('contract.contract-detail.booking-date.title', 'Start date')}</p>
        <p className="inline-block py-1.5 px-3 rounded-md border border-gray-300">{startTime}</p>
      </div>

      <div>
        <p className="mb-2 text-sm text-gray-800">{t('contract.contract-detail.end-date.title', 'End date')}</p>
        <p className="inline-block py-1.5 px-3 rounded-md border border-gray-300">{endTime}</p>
      </div>
    </div>
  );
}
