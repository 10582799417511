import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query allergies($companyId: ID!) {
    allergies(companyId: $companyId) {
      code
      title
      description
      companyId
      timestamp
    }
  }
`);

export default function useGetAllergies(companyId: string) {
  return useQuery(QUERY, {
    variables: { companyId },
  });
}
