import { useFocusedBranchProvider } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context';
import { useDialog } from 'ui/src/modal/use-dialog';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { useLocalStorage } from 'usehooks-ts';
import { OnlineOrderBranchSettingFragment } from '@client/graphql/online-order-branch-setting-fragment';
import { getFragmentData } from '@client/graphql/types';
import { useCallback, useEffect } from 'react';
import ToggleInput from 'ui/src/form/input/toggle-input';
import { useTranslate } from '@tolgee/react';
import useUpdateOnlineOrderConfiguration from '../../setting/logic/use-update-online-order-branch-setting';
import useGetOnlineOrderBranchConfiguration from '../logic/use-online-order-branch-config';

export default function OnlineOrderHeaderPickupToggle() {
  const branchProvider = useFocusedBranchProvider();
  const branchID = branchProvider.branch?.id ?? '';
  const { showAlert } = useNotificationCenter();
  const { data: settingRawData } = useGetOnlineOrderBranchConfiguration(branchID);
  const [update] = useUpdateOnlineOrderConfiguration();
  const dialog = useDialog();
  const [pickupEnabled, setPickupEnabled] = useLocalStorage<boolean>('isPickupEnabled', false);

  const onlineOrderBranchSettingData = getFragmentData(
    OnlineOrderBranchSettingFragment,
    settingRawData?.onlineOrderBranchConfiguration
  );

  useEffect(() => {
    if (onlineOrderBranchSettingData) {
      setPickupEnabled(onlineOrderBranchSettingData.pickupEnable);
    } else {
      setPickupEnabled(false);
    }
  }, [onlineOrderBranchSettingData, setPickupEnabled]);
  const { t } = useTranslate();
  const handleAction = useCallback(
    (value: boolean) => {
      dialog.confirmAlert({
        title: t('online-order.dashboard.pickup-toggle.alert.title'),
        message: t('online-order.dashboard.pickup-toggle.alert.message'),
        textButton: t('common.button.confirm'),
        onConfirm: () =>
          update({
            variables: {
              branchID: branchID,
              input: {
                pickupEnable: value,
              },
            },
          })
            .then(() => {
              setPickupEnabled(value);
              showAlert({
                status: 'success',
                title: t('alert.title.success'),
                message: t('online-order.dashboard.alert.message.success'),
              });
            })
            .catch(() => {
              showAlert({
                status: 'error',
                title: t('alert.title.success'),
                message: t('online-order.dashboard.alert.message.error'),
              });
            }),
      });
    },
    [branchID, dialog, setPickupEnabled, showAlert, update, t]
  );
  return (
    <div className="flex items-center justify-center gap-2 px-2 py-1 border border-1 border-gray-300 rounded-md scale-95">
      <p className="font-semibold">{t('online-order.text.pickup')}</p>
      <ToggleInput
        value={pickupEnabled}
        onChange={(newVal) => {
          handleAction(newVal);
        }}
      />
    </div>
  );
}
