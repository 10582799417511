import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';
import { Service } from '@client/graphql/types/graphql.ts';

const QUERY = graphql(`
  query getScheduleAvailabilityLimitations($branchId: ID!, $service: Service!) {
    getScheduleAvailabilityLimitations(branchId: $branchId, service: $service) {
      ...ScheduledAvailabilityLimitationItem
    }
  }
`);

export default function useScheduleAvailabilityLimitations(branchId: string, service: Service) {
  return useQuery(QUERY, {
    variables: {
      branchId,
      service,
    },
  });
}
