import { graphql } from '@packages/core/graphql/types';
import { useQuery } from '@apollo/client';

const COMPANIES_QUERY = graphql(`
  query SingleCompany($id: ID!) {
    company(id: $id) {
      id
      name
      category
      features {
        name
        companyId
      }
      settings {
        customerDefaultLanguage
      }
    }
  }
`);

export function useCompany(id: string) {
  return useQuery(COMPANIES_QUERY, {
    variables: {
      id,
    },
  });
}
