import { Controller, useForm } from 'react-hook-form';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { availableCurrencies, formatRawCurrency, normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { captureException } from '@sentry/browser';
import { useEffect } from 'react';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import { useUpdateContractFee } from '@client/page/management/contract/contract-update/logic/use-update-contract-fee.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useDeleteContractFee } from '../logic/use-delete-contract-fee';

export interface ContractFee {
  id: string;
  title: string;
  note?: string | null;
  amount: number;
  currency: string;
}

export default function ContractFeeUpdateSeparate(props: { data: ContractFee; onClose: () => void }) {
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();

  const [update] = useUpdateContractFee();
  const [deleteContract] = useDeleteContractFee();

  const {
    handleSubmit,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<ContractFee>({
    defaultValues: {
      id: '',
      title: '',
      note: '',
      amount: 0,
      currency: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  useEffect(() => {
    reset({ ...props.data, amount: Number(formatRawCurrency(props.data.amount, props.data.currency).value) });
  }, [props.data, reset]);

  const onSubmit = (form: ContractFee) => {
    update({
      variables: {
        id: form.id,
        input: {
          title: form.title,
          note: form.note,
          currency: form.currency,
          amount: normalizeCurrency(form.amount.toString(), form.currency),
        },
      },
    })
      .then(() => {
        showAlert({
          title: t('contract.update.contract-fee.update.success', 'Successful'),
          status: 'success',
          message: t('contract.update.contract-fee.update.success.mess', 'Contract fee has been updated'),
        });
      })
      .catch(() => {
        showAlert({
          title: t('contract.update.contract-fee.update.error', 'Error'),
          status: 'error',
          message: t('contract.update.contract-fee.update.error.mess', 'Something went wrong'),
        });
      });
    props.onClose();
  };

  const onDelete = () => {
    deleteContract({
      variables: {
        id: watch('id'),
      },
    })
      .then(() => {
        showAlert({
          title: t('contract.update.contract-fee.delete.success', 'Successful'),
          status: 'success',
          message: t('contract.update.contract-fee.delete.success.mess', 'Contract has been deleted'),
        });
      })
      .catch(() => {
        showAlert({
          title: t('contract.update.contract-fee.delete.error', 'Error'),
          status: 'error',
          message: t('contract.update.contract-fee.delete.error.mess', 'Something went wrong'),
        });
      });
    props.onClose();
  };

  return (
    <Card>
      <CardHeader title={t('contract.update.header')} onCrossButton={props.onClose} />

      <CardContent>
        <FormLayout>
          <FormItem className="max-w-sm" title={t('contract-fee.update.title.header')}>
            <Controller
              control={control}
              name="title"
              rules={{
                required: t('contract-fee-update.title.validate.required', 'Title is required'),
                validate: (value) =>
                  value.trim() !== '' || t('contract-fee-create.title.validate.required', 'Title is required'),
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  data-testid="title-input"
                  type="text"
                  label="title"
                  name="title"
                  placeholder={t('contract-fee.update.title.placeholder')}
                  value={value}
                  error={errors.title?.message}
                  autoComplete={'off'}
                  onChange={onChange}
                />
              )}
            />
          </FormItem>

          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-6 max-w-lg">
              <FormItem className="max-w-lg" title={t('contract-fee.update.currency.header')}>
                <Controller
                  control={control}
                  name="currency"
                  rules={{
                    required: t('contract-fee-update.currency.validate.required', 'Currency is required'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <MenuSelectionInput
                        title={''}
                        data={availableCurrencies}
                        value={value}
                        onChange={onChange}
                        build={(item) => {
                          return {
                            id: item,
                            name: item,
                          };
                        }}
                      />
                      {errors.currency && <p className="text-red-500 text-sm mt-2">{errors.currency.message}</p>}
                    </>
                  )}
                />
              </FormItem>

              <FormItem className="max-w-lg" title={t('contract-fee.update.amount.header')}>
                <Controller
                  control={control}
                  name="amount"
                  rules={{
                    required: t('contract-fee-update.amount.validate.required', 'Amount require number'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextInput
                        data-testid="title-input"
                        type="number"
                        label="amount"
                        name="amount"
                        placeholder={t('contract.update.amount.placeholder')}
                        value={value}
                        error={undefined}
                        autoComplete={'off'}
                        onChange={onChange}
                      />
                      {errors.amount && <p className="text-red-500 text-sm mt-2">{errors.amount.message}</p>}
                    </>
                  )}
                />
              </FormItem>
            </div>
          </div>

          <FormItem className="max-w-lg" title={t('contract-fee.update.customer-note.header')}>
            <Controller
              control={control}
              name="note"
              render={({ field: { onChange, value } }) => (
                <textarea
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                             focus:ring-indigo-600 sm:text-sm sm:leading-6 min-h-44"
                  defaultValue={value ?? ''}
                  onChange={(newValue) => {
                    onChange(newValue.target.value.trim());
                  }}
                />
              )}
            />
          </FormItem>
        </FormLayout>
      </CardContent>

      <CardFooter>
        <div className="flex justify-between w-full items-center">
          <DestroyButton onClick={onDelete}>{t('common.button.delete')}</DestroyButton>

          <div className="flex justify-end gap-x-2 w-full items-center">
            <SecondaryButton onClick={props.onClose}>{t('common.button.cancel')}</SecondaryButton>
            <PrimaryButton
              onClick={() => {
                clearErrors();
                handleSubmit(onSubmit)().catch(captureException);
              }}
            >
              {t('common.button.update')}
            </PrimaryButton>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
