import { Service } from '@client/graphql/types/graphql.ts';
import useTemporaryAvailabilityLimitations from '@client/page/management/availability-limitation/logic/use-temporary-availability-limitations.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import useScheduleAvailabilityLimitations from '@client/page/management/availability-limitation/logic/use-schedule-availability-limitations.ts';
import Loading from '@packages/ui/loading.tsx';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import AvailabilityLimitationScheduledList from '@client/page/management/availability-limitation/component/availability-limitation-scheduled-list.tsx';
import AvailabilityLimitationTemporaryList from '@client/page/management/availability-limitation/component/availability-limitation-temporary-list.tsx';
import { getFragmentData } from '@client/graphql/types';
import {
  ScheduledAvailabilityLimitationFragment,
  TemporaryAvailabilityLimitationFragment,
} from '@client/graphql/availability-limitation-fragment.ts';
import CapacityPremiumAlert from '@client/page/management/availability-limitation/component/capacity-premium-alert.tsx';
import CapacitySetting from '@client/page/management/availability-limitation/component/capacity-setting.tsx';

export default function AvailabilityLimitation(props: { service: Service }) {
  const branchId = useFocusedBranchContext().branch?.id ?? '';
  const {
    data: temporaryRawData,
    loading: temporaryLoading,
    refetch: temporaryRefetch,
  } = useTemporaryAvailabilityLimitations(branchId, props.service);
  const {
    data: scheduledRawData,
    loading: scheduledLoading,
    refetch: scheduledRefetch,
  } = useScheduleAvailabilityLimitations(branchId, props.service);

  const loading = temporaryLoading || scheduledLoading;

  if (loading) {
    return <Loading />;
  }

  if (!temporaryRawData || !scheduledRawData) {
    return <>Data is empty</>;
  }

  const temporaryData = getFragmentData(
    TemporaryAvailabilityLimitationFragment,
    temporaryRawData.getTemporaryAvailabilityLimitations
  );
  const scheduledData = getFragmentData(
    ScheduledAvailabilityLimitationFragment,
    scheduledRawData.getScheduleAvailabilityLimitations
  );

  return (
    <ContentLayout>
      <PrimaryContent>
        <CapacityPremiumAlert service={props.service} />
        <AvailabilityLimitationTemporaryList
          service={props.service}
          data={temporaryData}
          refetch={() => {
            void temporaryRefetch();
          }}
        />

        <AvailabilityLimitationScheduledList
          service={props.service}
          data={scheduledData}
          refetch={() => {
            void scheduledRefetch();
          }}
        />

        <CapacitySetting service={props.service} />
      </PrimaryContent>
    </ContentLayout>
  );
}
