import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const MUTATION = graphql(`
  mutation createAllergy($companyId: ID!, $input: CreateAllergyInput!) {
    createAllergy(companyId: $companyId, input: $input) {
      companyId
      code
      description
      timestamp
      title
    }
  }
`);

export default function useCreateAllergy() {
  return useMutation(MUTATION, {
    refetchQueries: ['allergies'],
  });
}
