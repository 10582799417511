import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const MUTATION = graphql(`
  mutation updateAllergy($companyId: ID!, $code: ID!, $input: UpdateAllergyInput!) {
    updateAllergy(companyId: $companyId, code: $code, input: $input) {
      companyId
      code
      description
      timestamp
      title
    }
  }
`);

export default function useUpdateAllergy() {
  return useMutation(MUTATION, {
    refetchQueries: ['allergies'],
  });
}
