import { graphql } from '@client/graphql/types';

export const TemporaryAvailabilityLimitationFragment = graphql(`
  fragment TemporaryAvailabilityLimitationItem on TemporaryAvailabilityLimitation {
    id
    service
    startTime
    endTime
    capacity
    message
    createdAt
  }
`);

export const ScheduledAvailabilityLimitationFragment = graphql(`
  fragment ScheduledAvailabilityLimitationItem on ScheduledAvailabilityLimitation {
    id
    service
    timeRule {
      daysOfWeeks
      startTime
      endTime
    }
    capacity
    message
    createdAt
  }
`);
