import { useNavigate, useParams } from 'react-router-dom';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import MenuProductTable from '@client/page/management/menu/menu-detail/component/menu-product-table.tsx';
import Loading from '@packages/ui/loading.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import { ContentLayout, FullPrimaryContent } from 'ui/src/content-layout';
import { useEffect, useMemo, useState } from 'react';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useTranslate } from '@tolgee/react';
import { Helmet } from 'react-helmet-async';
import EditMenuButton from '@client/page/management/menu/menu-detail/component/menu-edit-button.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { MenuProductPriceMode } from '@client/graphql/types/graphql.ts';
import { Controller, useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/browser';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import useUpdateMenu from '@client/page/management/menu/menu-edit/logic/use-update-menu.ts';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import { ArrowDownTrayIcon } from '@heroicons/react/16/solid';
import { menuPort } from '@client/page/management/menu/menu-detail/logic/import-menu.ts';
import HeaderSearchBar from '@client/page/management/menu/menu-detail/component/search-bar-in-table-header.tsx';
import { getFragmentData } from '@client/graphql/types';
import { MenuProductFragment } from '@packages/network-graphql/common/menu-product-fragment.ts';
import FilterInTableHeader from '@client/page/management/menu/menu-detail/component/filter-in-table-header.tsx';
import { MenuSectionFragment } from '@packages/network-graphql/common/menu-section-fragment.ts';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  groupMenuProductItemsBySection,
} from '@client/page/management/menu/menu-detail/logic/group-menu-product-item-by-section.ts';
import { useGetMenu } from './logic/use-get-menu';

interface FormValues {
  title: string;
  allergics: string;
  preferredProductPriceMode: MenuProductPriceMode;
}

enum MenuFilter {
  NO_DISCOUNT = 'no-discount',
}

function MenuDetail() {
  const menuId = useParams().menuId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();
  const priceModeOption = Object.values(MenuProductPriceMode);

  const { data, loading, error, refetch } = useGetMenu(menuId);


  const form = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
  });
  const { register, handleSubmit, getValues, clearErrors, control, setError, reset } = form;
  useEffect(() => {
    if (data?.menu) {
      reset({
        title: data.menu.title,
        allergics: data.menu.allergics,
        preferredProductPriceMode: data.menu.preferredProductPriceMode,
      });
    }
  }, [data, reset]);

  const [update] = useUpdateMenu();
  const onSubmit = async (updateData: FormValues) => {
    try {
      const result = await update({
        variables: {
          menuId: menuId,
          input: {
            title: updateData.title.trim(),
            allergics: updateData.allergics,
            preferredProductPriceMode: updateData.preferredProductPriceMode,
          },
        },
      });
      if (result.data) {
        await refetch();
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('alert.message.update-success', 'Update menu successfully'),
        });
      } else {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: t('alert.message.update-fail', 'Update menu failed'),
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof FormValues,
            {
              type: 'server',
              message: t(validationError[field] ?? 'validation-error.common.alert'),
            },
            { shouldFocus: true },
          );
        }
      }
      captureException(err);
    }
  };

  const productData = getFragmentData(MenuProductFragment, data?.menu.menuProducts);
  const sectionData = getFragmentData(MenuSectionFragment, data?.menu.sections);


  const [selectedFilters, setSelectedFilters] = useState<MenuFilter[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const finalGroupData = useMemo(() => {
    const regex = new RegExp(searchQuery, 'i');

    const filteredProducts = productData
      ?.filter((product) => regex.test(product.title))
      .filter((product) => {
        if (selectedFilters.includes(MenuFilter.NO_DISCOUNT)) {
          return !product.discountable || !(product.section && getFragmentData(MenuSectionFragment, product.section).discountable);
        }
        return true;
      });

    const groupedData = groupMenuProductItemsBySection(filteredProducts ?? [], sectionData ?? []);

    return groupedData
      .filter((group) => group.items.length > 0) // Ensure groups have matching products
      .filter((group) => {
        if (selectedFilters.includes(MenuFilter.NO_DISCOUNT)) {
          return !group.section?.discountable || group.items.some((product) => !product.discountable);
        }
        return true;
      });
  }, [productData, sectionData, searchQuery, selectedFilters]);


  useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: { breadcrumbName: data?.menu.title },
    });
  }, [navigate, data?.menu.title]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AlertError title={error.name} message={error.message} />;
  }

  if (!data) {
    return <Card>No data</Card>;
  }

  if (!productData) {
    return <Card>No product data</Card>;
  }

  if (!sectionData) {
    return <Card>No section data</Card>;
  }

  const importFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      if (!e.currentTarget.files[0]) {
        showAlert({
          status: 'error',
          title: t('menu.menu-detail.notification.error.import', 'Import menu unsuccessfully'),
          message: t('menu.menu-detail.notification.error.import.description', 'Something went wrong.'),
        });
        return;
      }

      const response = await menuPort.importMenu(menuId, e.currentTarget.files[0]);

      if (response.ok) {
        showAlert({
          status: 'success',
          title: t('menu.menu-detail.notification.success.import', 'Import menu successfully'),
          message: t('menu.menu-detail.notification.success.import.message', 'Menu has been updated'),
        });
        void refetch();
      } else {
        if (response.status === 400) {
          showAlert({
            status: 'error',
            title: t('menu.menu-detail.notification.error.import', 'Import menu unsuccessfully'),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            message: await response.json().then((data) => data.message as string),
          });
        }
      }
    }
  };


  return (
    <ContentLayout>
      <Helmet>
        <title>{t('menu.menu-detail.helmet.title')}</title>
        <meta name="description" content="This page displays the details of a menu"></meta>
      </Helmet>
      <FullPrimaryContent>
        <div className="flex-col space-y-4">
          <Card>
            <CardHeader title={data.menu.title} withBackButton={true}>
              <HeaderSearchBar onChange={setSearchQuery} />
              <SecondaryButton
                onClick={() => {
                  navigate(`sections/create`);
                }}
              >
                {t('menu.menu-detail.table-product.button.add-category')}
              </SecondaryButton>

              <PrimaryButton
                onClick={() => {
                  navigate(`products/create?preferredProductPriceMode=${getValues('preferredProductPriceMode')}`);
                }}
              >
                {t('menu.menu-detail.table-product.button.add-product')}
              </PrimaryButton>

              <PrimaryButton className="">
                <label htmlFor="file">
                  <div
                    className="flex gap-1 items-center"
                  >
                    {t('menu.menu-detail.extra-action.import.title', 'Import')}
                    <ArrowDownTrayIcon className="w-4 h-4" />
                  </div>
                </label>

                <input
                  id="file"
                  type={'file'}
                  accept={'.csv'}
                  onChange={(e) => {
                    void importFile(e);
                  }}
                  className="absolute w-1 h-1 -z-10"
                />
              </PrimaryButton>

              <FilterInTableHeader setSelectedFilters={setSelectedFilters}
                                   selectedFilters={selectedFilters}
              />
              <EditMenuButton menuId={menuId} />
            </CardHeader>

            <CardTableContent>
              {selectedFilters.length > 0 && (
                <div className="min-w-full border-b flex flex-1 items-center px-2 py-1">
                  {/*  label of filter in here*/}
                  <div className="flex gap-2 py-1">
                    {selectedFilters.map((filter) => (
                      <div key={filter}
                           className="flex items-center justify-center border border-gray-300 rounded-lg text-gray-700 gap-1 px-2 py-1 ">
                        <XMarkIcon
                          className="w-5 h-5 cursor-pointer"
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            setSelectedFilters((prevFilters) => prevFilters.filter((selectedFilter) => selectedFilter !== filter));
                          }}
                        />
                        <span>{t(`menu.menu-detail.table-product.filter.label.${filter}`, filter)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <MenuProductTable group={finalGroupData} />
            </CardTableContent>
          </Card>

          <Card>
            <CardHeader title={t('menu.menu-detail.detail.header', 'Menu Detail')} />
            <CardContent>
              <form
                className="space-y-6"
                onSubmit={() => {
                  handleSubmit(onSubmit);
                }}
              >
                <FormItem className="max-w-md" title={t('menu.menu-create.form-input.menu-allergics.title', 'Allergy')}>
                  <textarea
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                    {...register('allergics')}
                    defaultValue={data.menu.allergics || ''}
                  />
                  {form.formState.errors.allergics && (
                    <p className="text-red-500 text-xs pt-1">{form.formState.errors.allergics.message}</p>
                  )}
                </FormItem>

                <div className="max-w-md mt-6">
                  <Controller
                    control={control}
                    name="preferredProductPriceMode"
                    render={({ field }) => (
                      <MenuSelectionInput
                        title={t('menu.menu-create.form-input.menu-price-mode.title', 'Prefer Price mode')}
                        data={priceModeOption}
                        value={field.value}
                        onChange={field.onChange}
                        build={(item) => ({
                          id: item,
                          name: t(
                            `menu.menu-create.form-input.menu-price-mode.${item.toLowerCase()}`,
                            item.toLowerCase(),
                          ),
                        })}
                      />
                    )}
                  />
                </div>
              </form>
            </CardContent>
            <CardFooter>
              <PrimaryButton
                id="create-button"
                data-testid="create-button"
                className="w-32"
                onClick={() => {
                  clearErrors();
                  handleSubmit(onSubmit)().catch(captureException);
                }}
                loading={loading}
              >
                {t('common.button.update')}
              </PrimaryButton>
            </CardFooter>
          </Card>
        </div>
      </FullPrimaryContent>
    </ContentLayout>
  )
    ;
}

export default MenuDetail;
