import { Controller, useForm } from 'react-hook-form';
import { ContractFeeForm } from '@client/page/management/contract/contract-create/contract-create.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { availableCurrencies, normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { captureException } from '@sentry/browser';

export default function ContractFeeCreate(props: { onClose: () => void; create: (item: ContractFeeForm) => void }) {
  const { t } = useTranslate();
  const {
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<ContractFeeForm>({
    defaultValues: {
      title: '',
      note: '',
      amount: 0,
      currency: 'EUR',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit = (form: ContractFeeForm) => {
    props.create({ ...form, amount: normalizeCurrency(form.amount.toString(), form.currency) });
    props.onClose();
  };

  return (
    <Card>
      <CardHeader title={t('contract-fee.create.header')} onCrossButton={props.onClose} />

      <CardContent>
        <FormLayout>
          <FormItem className="max-w-sm" title={t('contract-fee.create.title.header')}>
            <Controller
              control={control}
              name="title"
              rules={{
                required: t('contract-fee-create.title.validate.required', 'Title is required'),
                validate: (value) =>
                  value.trim() !== '' || t('contract-fee-create.title.validate.required', 'Title is required'),
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  data-testid="title-input"
                  type="text"
                  label="title"
                  name="title"
                  placeholder={t('contract-fee.create.title.placeholder')}
                  value={value}
                  error={errors.title?.message}
                  autoComplete={'off'}
                  onChange={onChange}
                />
              )}
            />
          </FormItem>

          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-6 max-w-lg">
              <FormItem className="max-w-lg" title={t('contract-fee.create.currency.header')}>
                <Controller
                  control={control}
                  name="currency"
                  rules={{
                    required: t('contract-fee-create.currency.validate.required', 'Currency is required'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <MenuSelectionInput
                        title={''}
                        data={availableCurrencies}
                        value={value}
                        onChange={onChange}
                        build={(item) => {
                          return {
                            id: item,
                            name: item,
                          };
                        }}
                      />
                      {errors.currency && <p className="text-red-500 text-sm mt-2">{errors.currency.message}</p>}
                    </>
                  )}
                />
              </FormItem>

              <FormItem className="max-w-lg" title={t('contract-fee.create.amount.header')}>
                <Controller
                  control={control}
                  name="amount"
                  rules={{
                    required: t('contract-fee-create.amount.validate.required', 'Amount require number'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextInput
                        data-testid="title-input"
                        type="number"
                        label="amount"
                        name="amount"
                        placeholder={t('contract.create.amount.placeholder')}
                        value={value}
                        error={undefined}
                        autoComplete={'off'}
                        onChange={onChange}
                      />
                      {errors.amount && <p className="text-red-500 text-sm mt-2">{errors.amount.message}</p>}
                    </>
                  )}
                />
              </FormItem>
            </div>
          </div>

          <FormItem className="max-w-lg" title={t('contract-fee.create.customer-note.header')}>
            <Controller
              control={control}
              name="note"
              render={({ field: { onChange, value } }) => (
                <textarea
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                             focus:ring-indigo-600 sm:text-sm sm:leading-6 min-h-44"
                  defaultValue={value ?? ''}
                  onChange={(newValue) => {
                    onChange(newValue.target.value.trim());
                  }}
                />
              )}
            />
          </FormItem>
        </FormLayout>
      </CardContent>

      <CardFooter>
        <SecondaryButton onClick={props.onClose}>{t('common.button.cancel')}</SecondaryButton>
        <PrimaryButton
          onClick={() => {
            clearErrors();
            handleSubmit(onSubmit)().catch(captureException);
          }}
        >
          {t('common.button.create')}
        </PrimaryButton>
      </CardFooter>
    </Card>
  );
}
