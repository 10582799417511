import React from 'react';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { PercentBadgeIcon } from '@heroicons/react/20/solid';
import { uniq } from 'lodash';
import { useTranslate } from '@tolgee/react';

enum MenuFilter {
  NO_DISCOUNT = 'no-discount',
}

interface FilterProps {
  setSelectedFilters: (filters: MenuFilter[]) => void;
  selectedFilters: MenuFilter[]; // Optional prop to set selected filters
}

const FilterInTableHeader: React.FC<FilterProps> = ({ setSelectedFilters, selectedFilters }) => {
  const { t } = useTranslate();
  const filterItems = [
    {
      id: MenuFilter.NO_DISCOUNT,
      icon: <PercentBadgeIcon className="w-5 h-5 text-red-500" />,
      label: t('menu.menu-detail.table-product.filter.no-discount', 'Only not discountable'),
    },
  ];

  const handleFilterClick = (filterId: MenuFilter) => {
    setSelectedFilters(uniq([...selectedFilters, filterId]));
  };

  return (
    <div className="flex gap-2">
      <Menu>
        <MenuButton
          className="flex items-center bg-gray-100 gap-2 shadow-xs rounded-md h-8 px-3 font-semibold text-xl focus:outline-none">
          <FunnelIcon className="w-6 h-6 text-gray-700" />
        </MenuButton>

        <MenuItems
          transition
          anchor="bottom start"
          className="border border-gray-200 rounded-xl bg-white shadow-md [--anchor-gap:8px] [--anchor-padding:8px]"
        >
          {filterItems.map((item) => (
            <MenuItem key={item.id}>
              <button
                onClick={() => {
                  handleFilterClick(item.id);
                }}
                className={`flex w-full items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-100 ${
                  selectedFilters.includes(item.id) ? 'bg-gray-300' : ''
                }`}
              >
                <span> {item.icon}</span>
                <span className="text-sm text-gray-700">{item.label}</span>
              </button>
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    </div>
  );
};

export default FilterInTableHeader;