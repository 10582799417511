import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import useUserCompanyRoleCount from '@client/page/user-company-role/user-list/logic/use-user-count.ts';
import usePagination from '@packages/core/utils/pagination/use-pagination.ts';
import useUserCompanyRoles from '@client/page/user-company-role/user-list/logic/use-user-company-roles.ts';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import Pagination from '@packages/core/utils/pagination/pagination.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import CheckBoxInput from '@packages/ui/form/input/check-box-input.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';

export default function AddMember(props: {
  currentMember: { userId: string }[];
  handleMember: (isChecked: boolean, userId: string) => void;
}) {
  const companyId = useCompanyId();

  const { data: quantityData } = useUserCompanyRoleCount(companyId);
  const paginationInfo = usePagination(quantityData?.userCompanyRolesCount ?? 0);
  const { data, error } = useUserCompanyRoles(
    companyId,
    paginationInfo.info.current * paginationInfo.info.perPage,
    paginationInfo.info.perPage
  );

  if (error) {
    return <ApplicationErrorView error={error} />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  return (
    <Card>
      <CardHeader title="Add Member" />

      <CardTableContent>
        <ul className="divide-y">
          {data.userCompanyRoles.map(({ user }) => (
            <li key={user.id} className="flex items-center gap-1 truncate px-2 py-2.5">
              <CheckBoxInput
                value={props.currentMember.map((member) => member.userId).includes(user.id)}
                onChange={(value) => {
                  props.handleMember(value, user.id);
                }}
              />
              <div>
                {user.firstName} {user.lastName}
                {/*<span className="ml-1 italic text-gray-400 text-sm">(Email: {user.email})</span>*/}
              </div>
            </li>
          ))}
        </ul>
      </CardTableContent>

      <CardFooter>
        <Pagination info={paginationInfo.info} setCurrent={paginationInfo.updateCurrent} />
      </CardFooter>
    </Card>
  );
}
