import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { graphql } from '@packages/core/graphql/types';
import { CompanyCategory } from '@packages/network-graphql/graphql/types/graphql.ts';
import { Feature } from '@packages/core/graphql/types/graphql.ts';

export const GET_COMPANY_QUERY = graphql(`
  query Company($id: ID!) {
    company(id: $id) {
      id
      name
      logo
      category
      instagramLink
      facebookLink
      googleLink
      tiktokLink
      imprintOwner
      imprintOwnerTaxId
      imprintCompanyName
      imprintCompanyTaxId
      imprintPhone
      imprintEmail
      features {
        name
      }
      settings {
        currency
        timezone
        customerDefaultLanguage
        notificationEmails
      }
    }
  }
`);

export interface Company {
  id: string;
  name: string;
  logo?: string | null;
  category: CompanyCategory[];
  instagramLink?: string | null;
  facebookLink?: string | null;
  googleLink?: string | null;
  tiktokLink?: string | null;
  imprintOwner?: string | null;
  imprintOwnerTaxId?: string | null;
  imprintCompanyName?: string | null;
  imprintCompanyTaxId?: string | null;
  imprintPhone?: string | null;
  imprintEmail?: string | null;
  settings: CompanySettings;
  features?: CompanyFeature[] | null;
}

export interface CompanySettings {
  currency: string;
  timezone: string;
  customerDefaultLanguage: string;
  notificationEmails: string[];
}

export interface CompanyFeature {
  name: Feature;
}

export interface CompanyService {
  companyID: string | null;
  company: Company | null;
  loading: boolean;
  error: Error | null;
  setCompanyID: (id: string | null) => void;
  clear: () => void;
}

export const useCompanyService = (): CompanyService => {
  const client = useApolloClient();

  const [companyID, setCompanyID] = useLocalStorage<string | null>('companyID', null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [company, setCompany] = useLocalStorage<Company | null>('company', null);

  const clear = useCallback(() => {
    setCompanyID(null);
    setCompany(null);

    localStorage.removeItem('companyID');
    localStorage.removeItem('company');
    localStorage.removeItem('selectedBranch');
  }, [setCompany, setCompanyID]);

  useEffect(() => {
    // id is not defined.
    if (!companyID) {
      setLoading(false);
      setCompany(null);
      setError(null);
      return;
    }

    setLoading(true);

    client
      .query({
        query: GET_COMPANY_QUERY,
        variables: { id: companyID },
      })
      .then((result) => {
        setLoading(false);
        if (result.data.company) {
          setCompany(result.data.company);
        }
      })
      .catch((error) => {
        setLoading(false);

        if (error instanceof Error) {
          setError(error);
        }

        setCompany(null);
      });
  }, [companyID, client, setCompany]);

  return useMemo(
    () => ({
      companyID,
      company,
      loading,
      error,
      setCompanyID,
      clear,
    }),
    [clear, company, companyID, error, loading, setCompanyID]
  );
};
