import { useTranslate } from '@tolgee/react';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import TableRow from '@packages/ui/table/table-row.tsx';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import { ContractFeeItemFragment } from '@client/graphql/types/graphql.ts';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import ContractFeeCreateSeparate from '@client/page/management/contract/contract-update/component/contract-fee-create-separate.tsx';
import ContractFeeUpdateSeparate from '@client/page/management/contract/contract-update/component/contract-fee-update-separate.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';

export default function ContractFeeUpdateTable(props: { data: ContractFeeItemFragment[] }) {
  const { t } = useTranslate();
  const dialogManager = useModalManagerContext();

  const createContractFeeModal = () => {
    dialogManager.showDialog((onClose) => <ContractFeeCreateSeparate onClose={onClose} />);
  };

  const updateContractFeeModal = (value: ContractFeeItemFragment) => {
    dialogManager.showDialog((onClose) => <ContractFeeUpdateSeparate data={value} onClose={onClose} />);
  };

  if (props.data.length == 0) {
    return (
      <Card>
        <CardHeader title={t('contract.contract-fee-table.header')}>
          <PrimaryButton onClick={createContractFeeModal}>{t('common.button.create')}</PrimaryButton>
        </CardHeader>

        <CardContent>
          <p className="text-base text-gray-500">{t('contract.table.empty')}</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title={t('contract.contract-fee-table.header')}>
        <PrimaryButton onClick={createContractFeeModal}>{t('common.button.create')}</PrimaryButton>
      </CardHeader>

      <CardTableContent>
        <Table
          data={props.data}
          build={(fee, index) => <ContractFeeRow index={index} contractFee={fee} update={updateContractFeeModal} />}
        >
          <TableHeaderColumn alignment={TableCellAlignment.Left}>
            {t('contract-fee.table-header-column.title')}
          </TableHeaderColumn>
          <TableHeaderColumn className={'hidden sm:table-cell'} alignment={TableCellAlignment.Center}>
            {t('contract-fee.table-header-column.note')}
          </TableHeaderColumn>
          <TableHeaderColumn alignment={TableCellAlignment.Right}>
            {t('contract-fee.table-header-column.amount')}
          </TableHeaderColumn>
        </Table>
      </CardTableContent>
    </Card>
  );
}

function ContractFeeRow(props: {
  contractFee: ContractFeeItemFragment;
  index: number;
  update: (value: ContractFeeItemFragment) => void;
}) {
  const { contractFee, index, update } = props;
  return (
    <TableRow
      onClick={() => {
        update(contractFee);
      }}
      key={index}
    >
      <TableHeaderColumn alignment={TableCellAlignment.Left}>{contractFee.title}</TableHeaderColumn>
      <TableHeaderColumn className={'hidden sm:table-cell'} alignment={TableCellAlignment.Center}>
        <p className="m-auto sm:max-w-48 lg:max-w-72 xl:max-w-96 truncate text-sm text-gray-500 font-light">
          {contractFee.note}
        </p>
      </TableHeaderColumn>
      <TableHeaderColumn alignment={TableCellAlignment.Right}>
        {formatCurrency(contractFee.amount, contractFee.currency)}
      </TableHeaderColumn>
    </TableRow>
  );
}
