import { useNavigate } from 'react-router-dom';
import { useAuth } from '@client/module/auth/auth-context.ts';
import { useTranslate } from '@tolgee/react';
import { useCallback, useEffect, useState } from 'react';
import { CompanyCategory, CompanyContractStatusEnum } from '@client/graphql/types/graphql.ts';
import Loading from '@packages/ui/loading.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import HistorySearchBar from '@client/page/management/appointment/history/component/history-search-bar.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import useCompaniesWithContractStatus from '@client/page/management/contract/contract-company-list/logic/use-companies-with-contract-status.ts';
import CompanyContractStatusMessage from '@client/page/management/contract/contract-company-list/component/company-contract-status.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

interface CompanyData {
  __typename?: 'CompanyContractStatus' | undefined;
  id: string;
  name: string;
  category: CompanyCategory[];
  companyContractStatusEnum: CompanyContractStatusEnum;
}

// Thứ tự ưu tiên sắp xếp
const contractStatusOrder = [
  CompanyContractStatusEnum.NoContract,
  CompanyContractStatusEnum.NotPayed,
  CompanyContractStatusEnum.ExpireSoon,
  CompanyContractStatusEnum.Ended,
  CompanyContractStatusEnum.Active,
];

export default function ContractCompaniesList() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { t } = useTranslate();

  const { data, loading, error, refetch } = useCompaniesWithContractStatus(0, 50);

  const [companies, setCompanies] = useState<CompanyData[]>([]);

  useEffect(() => {
    if (data) {
      const sortedCompanies = [...data.companyContractStatus].sort(
        (a, b) =>
          contractStatusOrder.indexOf(a.companyContractStatusEnum) -
          contractStatusOrder.indexOf(b.companyContractStatusEnum)
      );
      setCompanies(sortedCompanies);
    }
  }, [data]);

  const onCompanyClick = (id: string) => {
    navigate(`/contract-management/${id}/contracts`);
  };

  const handleSearchBar = useCallback(
    (searchString: string) => {
      if (data) {
        const regex = new RegExp(searchString.trim(), 'i');
        setCompanies(
          data.companyContractStatus
            .filter((company) => regex.test(company.name))
            .sort(
              (a, b) =>
                contractStatusOrder.indexOf(a.companyContractStatusEnum) -
                contractStatusOrder.indexOf(b.companyContractStatusEnum)
            )
        );
      }
    },
    [data]
  );

  const allowedRoles = ['ADMIN', 'SUPER_ADMIN', 'CONTRACT_MANAGER'];
  if (!allowedRoles.some((role) => auth?.role.includes(role))) {
    return <>Permission denied</>;
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    throw error;
  }

  if (!data) {
    return <div>No companies found</div>;
  }

  return (
    <ContentLayout>
      <FullPrimaryContent>
        <Card>
          <CardHeader title={t('company-contracts.header')}>
            <SecondaryButton
              onClick={() => {
                void refetch();
              }}
            >
              <ArrowPathIcon className="w-5 h-5" />
            </SecondaryButton>
          </CardHeader>

          <CardTableContent>
            <HistorySearchBar onChange={handleSearchBar} />

            <Table
              data={companies}
              build={(item) => {
                return (
                  <TableRow
                    onClick={() => {
                      onCompanyClick(item.id);
                    }}
                    key={item.id}
                  >
                    <TableRowColumn>{item.name}</TableRowColumn>
                    <TableRowColumn className="hidden sm:table-cell">
                      <div
                        onClick={() => {
                          onCompanyClick(item.id);
                        }}
                      >
                        {item.category.map((item) => (
                          <span key={item}>{t(`contract.company.header.category.${item}`)}</span>
                        ))}
                      </div>
                    </TableRowColumn>
                    <TableRowColumn>
                      <div
                        onClick={() => {
                          onCompanyClick(item.id);
                        }}
                      >
                        <CompanyContractStatusMessage companyContractStatus={item.companyContractStatusEnum} />
                      </div>
                    </TableRowColumn>
                  </TableRow>
                );
              }}
              loading={loading}
              error={error}
            >
              <TableHeaderColumn>{t('contract-table.header.company')}</TableHeaderColumn>
              <TableHeaderColumn className="hidden sm:table-cell">
                {t('contract-table.header.category')}
              </TableHeaderColumn>
              <TableHeaderColumn>{t('contract-table.header.status')}</TableHeaderColumn>
            </Table>
          </CardTableContent>
        </Card>
      </FullPrimaryContent>
    </ContentLayout>
  );
}
