import { useTranslate } from '@tolgee/react';
import { CompanyContractStatusEnum } from '@client/graphql/types/graphql.ts';

export default function CompanyContractStatusMessage(props: { companyContractStatus: CompanyContractStatusEnum }) {
  const { companyContractStatus } = props;
  const { t } = useTranslate();

  // No contract status
  if (companyContractStatus == CompanyContractStatusEnum.NoContract) {
    return (
      <p className="flex item-center gap-x-2">
        <div className={'w-4 h-4 rounded-full bg-gray-600'}></div>
        <span>{t('company-contract-status.no_contract')}</span>
      </p>
    );
  }

  // Not payed status
  if (companyContractStatus == CompanyContractStatusEnum.NotPayed) {
    return (
      <p className="flex item-center gap-x-2">
        <div className={'w-4 h-4 rounded-full bg-red-600'}></div>
        <span>{t('company-contract-status.not_payed')}</span>
      </p>
    );
  }

  // Expire soon status
  if (companyContractStatus == CompanyContractStatusEnum.ExpireSoon) {
    return (
      <p className="flex item-center gap-x-2">
        <div className={'w-4 h-4 rounded-full bg-yellow-400'}></div>
        <span>{t('company-contract-status.expire_soon')}</span>
      </p>
    );
  }

  if (companyContractStatus == CompanyContractStatusEnum.Ended) {
    return (
      <p className="flex item-center gap-x-2">
        <div className={'w-4 h-4 rounded-full bg-blue-600'}></div>
        <span>{t('company-contract-status.ended')}</span>
      </p>
    );
  }

  return (
    <p className="flex item-center gap-x-2">
      <div className={'w-4 h-4 rounded-full bg-green-600'}></div>
      <span>{t('company-contract-status.active')}</span>
    </p>
  );
}
