import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const CREATE_CONTRACT = graphql(`
  mutation CreateContract(
    $companyId: ID!
    $categoryCode: ID!
    $contractData: CompanyContractCreateInput!
    $contractFeeData: [CompanyContractFeeCreateInput!]!
  ) {
    createContract(
      companyId: $companyId
      categoryCode: $categoryCode
      contractData: $contractData
      contractFeeData: $contractFeeData
    ) {
      id
    }
  }
`);

export function useCreateContract() {
  return useMutation(CREATE_CONTRACT, {});
}
