import { useNavigate, useParams } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';
import { useCallback, useEffect, useState } from 'react';
import Loading from '@packages/ui/loading.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import Card from '@packages/ui/card/card.tsx';
import { Helmet } from 'react-helmet-async';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useGetService } from '@client/page/management/appointment/service/service-detail/logic/use-get-service.ts';
import EditMenuButton from '@client/page/management/menu/menu-detail/component/menu-edit-button.tsx';
import { useForm } from 'react-hook-form';
import { ServiceFragment } from '@packages/network-graphql/common/service-fragment.ts';
import { getFragmentData } from '@packages/network-graphql/graphql/types';
import HeaderSearchBar from '@client/page/management/menu/menu-detail/component/search-bar-in-table-header.tsx';
import ServiceTable from './component/service-table';

interface FormValues {
  title: string;
}

export default function ServiceMenuDetail() {
  const serviceId = useParams().serviceId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { data, loading, error } = useGetService(serviceId);
  const serviceData = getFragmentData(ServiceFragment, data?.serviceMenu.menuProducts);

  const form = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
  });
  const { reset } = form;
  useEffect(() => {
    if (data?.serviceMenu) {
      reset({
        title: data.serviceMenu.title,
      });
    }
  }, [data, reset]);

  useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: { breadcrumbName: data?.serviceMenu.title },
    });
  }, [navigate, data?.serviceMenu.title]);

  const [filteredData, setFilteredData] = useState(serviceData);

  useEffect(() => {
    if (data?.serviceMenu.menuProducts) {
      setFilteredData(serviceData);
    }
  }, [data, serviceData, setFilteredData]);

  const hanldeSearchBar = useCallback((searchString: string) => {
    if (serviceData) {
      const regex = new RegExp(searchString, 'i');
      setFilteredData(serviceData.filter((product) => regex.test(product.title)));
    }
  }, [serviceData]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AlertError title={error.name} message={error.message} />;
  }

  if (!data) {
    return <Card>No data</Card>;
  }

  if (!serviceData) {
    return <Card>No data</Card>;
  }

  return (
    <div>
      <ContentLayout>
        <Helmet>
          <title>{t('service.service-detail.helmet.title')}</title>
          <meta name="description" content="This page displays the details of a service menu"></meta>
        </Helmet>
        <PrimaryContent>
          <div className="flex-col space-y-4">
            <Card>
              <CardHeader title={data.serviceMenu.title} withBackButton={true}>
                <HeaderSearchBar onChange={hanldeSearchBar} />
                <SecondaryButton
                  onClick={() => {
                    navigate(`sections/create`);
                  }}
                >
                  {t('service.service-detail.table-product.button.add-category', 'Add category')}
                </SecondaryButton>

                <PrimaryButton
                  onClick={() => {
                    navigate('products/create');
                  }}
                >
                  {t('service.service-detail.table-product.button.add-product', 'Add service')}
                </PrimaryButton>
                <EditMenuButton menuId={serviceId} />
              </CardHeader>

              <CardTableContent>
                <ServiceTable products={filteredData ?? serviceData} sections={data.serviceMenu.sections} />
              </CardTableContent>
            </Card>
          </div>
        </PrimaryContent>
      </ContentLayout>
    </div>
  );
}
