import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { TableReservationConfirmationStatus, TableReservationItemFragment } from '@client/graphql/types/graphql.ts';
import { Fragment, ReactElement } from 'react';
import { groupBy } from 'graphql/jsutils/groupBy';
import moment from 'moment-timezone';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { ReservationTableListConfirmationStatus } from '@client/page/management/table-reservation/list/component/reservation-table-list-confirmation-status.tsx';
import { useTranslate } from '@tolgee/react';

export default function TableReservationTableList(props: {
  loading?: boolean;
  items: TableReservationItemFragment[];
  actions?: (ctx: { id: string }) => ReactElement;
  onClick?: (id: string) => void;
  error?: string;
  refetch: () => void;
}) {
  const { items, actions } = props;
  const { t } = useTranslate();

  if (items.length === 0 && props.loading == false) {
    return <div className="px-6 py-4 border-t">{t('reservation.no-reservation')}</div>;
  }

  // Group by date
  const dateGroups = groupBy(items, (item) => {
    return moment(item.startTime).tz(item.timezone).format('DD MMM');
  });

  const dates = Array.from(dateGroups, ([date, reservations]) => {
    const sortedReservations = Array.from(reservations);
    sortedReservations.sort((a, b) => {
      return moment(a.startTime).isAfter(moment(b.startTime)) ? 1 : -1;
    });

    return {
      date,
      reservations: sortedReservations,
    };
  });

  // Sort by date
  dates.sort((a, b) => {
    if (!a.reservations[0] || !b.reservations[0]) {
      return -1;
    }
    return moment(a.reservations[0].startTime).isAfter(moment(b.reservations[0].startTime)) ? 1 : -1;
  });

  return (
    <>
      <Table
        loading={props.loading}
        data={dates}
        error={props.error}
        refetch={props.refetch}
        build={(item) => (
          <Fragment key={item.date}>
            <TableSection key={item.date} colSpan={6}>
              <div className="flex justify-start">
                <span className="font-bold text-xl text-indigo-600">{item.date}</span>
              </div>
            </TableSection>

            {item.reservations.map((reservation) => (
              <ReservationRow
                key={reservation.id}
                item={reservation}
                actions={actions}
                onClick={() => props.onClick?.(reservation.id)}
              />
            ))}
          </Fragment>
        )}
        rounded={false}
      >
        {/*Mobile*/}
        <TableHeaderColumn className="sm:hidden w-14">{t('common.table-header-column.status')}</TableHeaderColumn>
        <TableHeaderColumn className="sm:hidden">{t('common.table-header-column.guest', 'Guest')}</TableHeaderColumn>
        <TableHeaderColumn className="sm:hidden">{t('common.table-header-column.time', 'Time')}</TableHeaderColumn>
        <TableHeaderColumn className="sm:hidden w-2/5">
          {t('common.table-header-column.message', 'Message')}
        </TableHeaderColumn>

        {/*Desktop*/}
        <TableHeaderColumn className="hidden sm:table-cell w-14">
          {t('common.table-header-column.status')}
        </TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">{t('common.table-header-column.guest')}</TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">{t('common.table-header-column.number')}</TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">{t('common.table-header-column.time')}</TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">
          {t('common.table-header-column.message')}
        </TableHeaderColumn>
      </Table>
    </>
  );
}

function ReservationRow(props: {
  item: TableReservationItemFragment;
  actions?: (ctx: { id: string }) => ReactElement;
  onClick?: () => void;
}) {
  const { item, onClick } = props;
  const { t } = useTranslate();

  return (
    <>
      <TableRow key={item.id} className="cursor-pointer" onClick={onClick}>
        {/*Mobile*/}
        <TableRowColumn className="sm:hidden">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="w-full h-full relative"
          >
            <ReservationTableListConfirmationStatus item={item} />
          </div>
        </TableRowColumn>

        {/*guest*/}
        <TableRowColumn className="sm:hidden">
          <p className="font-bold">
            {item.seats}({t('reservation.list.table-row-column.guests', 'guests')})
          </p>
          <p className="text-gray-700 truncate break-words text-wrap">{item.guestName}</p>
        </TableRowColumn>

        {/* Start time */}
        <TableRowColumn className="sm:hidden">
          <p className="font-bold">{tableReservationTimeFormat(item.startTime, item.timezone)}</p>
        </TableRowColumn>

        {/*message*/}
        <TableRowColumn className="sm:hidden">
          <p className="truncate break-words text-wrap line-clamp-2">{item.message}</p>
        </TableRowColumn>

        {/*Desktop*/}
        <TableRowColumn className="hidden sm:table-cell">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="w-full h-full relative"
          >
            <ReservationTableListConfirmationStatus item={item} />
          </div>
        </TableRowColumn>

        {/* Name */}
        <TableRowColumn className="hidden sm:table-cell">
          <span>{item.guestName}</span>
          <span className="md:hidden"> ({item.seats})</span>
        </TableRowColumn>

        <TableRowColumn className="hidden sm:table-cell">
          <p className="font-bold">
            {item.seats}({t('reservation.list.table-row-column.guests', 'guests')})
          </p>
        </TableRowColumn>

        {/* Start time */}
        <TableRowColumn className="hidden sm:table-cell font-bold">
          {tableReservationTimeFormat(item.startTime, item.timezone)}
        </TableRowColumn>

        {/* Message */}
        <TableRowColumn className="hidden sm:table-cell">
          <p className="truncate break-words text-wrap line-clamp-2">{item.message}</p>
        </TableRowColumn>
      </TableRow>
    </>
  );
}

export function TableReservationIcon(props: { status: TableReservationConfirmationStatus }) {
  const { status } = props;

  switch (status) {
    case TableReservationConfirmationStatus.Confirmed:
      return <CheckCircleIcon className="w-6 h-6 fill-green-500" />;
    case TableReservationConfirmationStatus.Cancelled:
      return <XCircleIcon className="w-6 h-6 fill-red-500" />;
    case TableReservationConfirmationStatus.Waiting:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
    default:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
  }
}

function tableReservationTimeFormat(dateString: string, timezone: string): string {
  const date = moment(dateString).tz(timezone);
  return date.format('HH:mm');
}
