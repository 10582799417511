import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useCompany } from '@packages/core/company/hook/use-company.ts';
import { useParams } from 'react-router-dom';
import Loading from '@packages/ui/loading.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { Feature } from '@client/graphql/types/graphql.ts';
import FeatureEnable from '@client/page/management/organisation-manager/company-feature/component/feature-enable.tsx';

const features = [Feature.NailAppointmentCapacity, Feature.OnlineOrderCapacity, Feature.TableReservationCapacity];

export default function CompanyFeature() {
  const companyId = useParams().companyId ?? '';

  const { data, loading } = useCompany(companyId);

  if (loading) {
    return <Loading />;
  }

  if (!data?.company) {
    return <>Empty data</>;
  }

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={data.company.name} withBackButton={true} />

          <CardTableContent>
            {features.map((item) => {
              const enabled = data.company?.features?.find((feature) => feature.name == item) ? true : false;
              return (
                <div key={item}>
                  <FeatureEnable companyId={data.company?.id ?? ''} feature={item} enabled={enabled} />
                </div>
              );
            })}
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
