import { Feature, Service } from '@client/graphql/types/graphql.ts';

export default function mapCapacityServiceToFeature(service: Service) {
  const featureMap: Record<Service, Feature> = {
    [Service.TableReservation]: Feature.TableReservationCapacity,
    [Service.NailAppointment]: Feature.NailAppointmentCapacity,
    [Service.OnlineOrder]: Feature.OnlineOrderCapacity,
  };

  return featureMap[service];
}
