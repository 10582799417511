import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const CONTRACTS = graphql(`
  query Contracts($companyId: ID!) {
    contracts(companyId: $companyId) {
      ...ContractItemWithStatus
    }
  }
`);

export function useContracts(companyId: string) {
  return useQuery(CONTRACTS, {
    variables: {
      companyId,
    },
  });
}
