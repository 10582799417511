import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const CREATE_CONTRACT_FEE = graphql(`
  mutation CreateContractFee($contractId: ID!, $input: CompanyContractFeeCreateInput!) {
    createContractFee(contractId: $contractId, input: $input) {
      id
    }
  }
`);

export function useCreateContractFee() {
  return useMutation(CREATE_CONTRACT_FEE, { refetchQueries: ['Contract'] });
}
