import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const CONTRACT_CATEGORIES = graphql(`
  query ContractCategories($companyId: ID!) {
    contractCategories(companyId: $companyId) {
      title
      code
    }
  }
`);

export function useContractCategories(companyId: string) {
  return useQuery(CONTRACT_CATEGORIES, {
    variables: { companyId },
  });
}
