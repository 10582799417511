import Card from '@packages/ui/card/card.tsx';
import { ScheduledAvailabilityLimitationItemFragment, Service } from '@client/graphql/types/graphql.ts';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import Table from '@packages/ui/table/table.tsx';
import { capitalize } from 'lodash';
import { ArrowRightIcon } from '@heroicons/react/16/solid';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import AvailabilityScheduledCreate from '@client/page/management/availability-limitation/component/availability-scheduled-create.tsx';
import AvailabilityScheduledUpdate from '@client/page/management/availability-limitation/component/availability-scheduled-updated.tsx';

export default function AvailabilityLimitationScheduledList(props: {
  service: Service;
  data: ScheduledAvailabilityLimitationItemFragment[];
  refetch: () => void;
}) {
  const { t } = useTranslate();
  const dialogManager = useModalManagerContext();

  const createModal = () => {
    dialogManager.showDialog((onClose) => <AvailabilityScheduledCreate service={props.service} onClose={onClose} />);
  };

  const updateModal = (data: ScheduledAvailabilityLimitationItemFragment) => {
    dialogManager.showDialog((onClose) => (
      <AvailabilityScheduledUpdate service={props.service} data={data} onClose={onClose} />
    ));
  };

  return (
    <Card>
      <CardHeader title={t('availability-limitation-scheduled-list.title')}>
        <PrimaryButton onClick={createModal}>{t('common.button.create')}</PrimaryButton>
        <SecondaryButton onClick={props.refetch}>
          <ArrowPathIcon className="w-5 h-5" />
        </SecondaryButton>
      </CardHeader>

      <CardTableContent>
        <Table
          data={props.data}
          refetch={props.refetch}
          build={(schedule) => <ScheduleRow schedule={schedule} updateModal={updateModal} />}
        >
          <TableHeaderColumn alignment={TableCellAlignment.Left}>
            {t('availability-limitation-scheduled-list.table-header-column.period')}
          </TableHeaderColumn>
          <TableHeaderColumn alignment={TableCellAlignment.Center}>
            {t('availability-limitation-scheduled-list.table-header-column.during')}
          </TableHeaderColumn>
          <TableHeaderColumn className="hidden md:table-cell" alignment={TableCellAlignment.Center}>
            {t('availability-limitation-temporary-list.table-header-column.note')}
          </TableHeaderColumn>
          <TableHeaderColumn alignment={TableCellAlignment.Right}>
            {t('availability-limitation-scheduled-list.table-header-column.capacity')}
          </TableHeaderColumn>
        </Table>
      </CardTableContent>
    </Card>
  );
}

function ScheduleRow(props: {
  schedule: ScheduledAvailabilityLimitationItemFragment;
  updateModal: (data: ScheduledAvailabilityLimitationItemFragment) => void;
}) {
  const { schedule, updateModal } = props;

  return (
    <TableRow
      key={schedule.id}
      onClick={() => {
        updateModal(schedule);
      }}
    >
      <TableHeaderColumn alignment={TableCellAlignment.Left}>
        <p className="flex flex-wrap gap-x-2 font-normal tracking-wide">
          {schedule.timeRule.daysOfWeeks.map((item) => capitalize(item).slice(0, 3)).join(', ')}
        </p>
      </TableHeaderColumn>

      <TableHeaderColumn className="" alignment={TableCellAlignment.Center}>
        <p className="flex flex-col sm:flex-row flex-wrap gap-x-2 font-normal tracking-wide w-full justify-center items-center">
          <span>{schedule.timeRule.startTime}</span>
          <ArrowRightIcon className="hidden sm:inline-block w-3 h-3" />
          <span>{schedule.timeRule.endTime}</span>
        </p>
      </TableHeaderColumn>
      <TableHeaderColumn className="hidden md:table-cell " alignment={TableCellAlignment.Center}>
        <div className="font-normal tracking-wide max-w-60 truncate text-gray-600  mx-auto">{schedule.message}</div>
      </TableHeaderColumn>
      <TableHeaderColumn className="" alignment={TableCellAlignment.Right}>
        <p className="font-normal tracking-wide">{schedule.capacity}</p>
      </TableHeaderColumn>
    </TableRow>
  );
}
