import { RouteObject } from 'react-router-dom';
import OrganisationManager from '@client/page/management/organisation-manager/organisation-manager.tsx';
import CompanyFeature from '@client/page/management/organisation-manager/company-feature/company-feature.tsx';

export const OrganisationManagerRouter: RouteObject = {
  path: 'organisation-manager',
  children: [
    {
      path: '',
      element: <OrganisationManager />,
    },
    {
      path: 'company-feature/:companyId',
      element: <CompanyFeature />,
    },
  ],
};
