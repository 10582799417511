import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { useEffect, useState } from 'react';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import useUpdateAllergy from '@client/page/management/menu/allergy/logic/use-update-allergy.ts';
import useDeleteAllergy from '@client/page/management/menu/allergy/logic/use-delete-allergy.ts';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';

interface AllergyInput {
  code: string;
  title: string;
  description: string;
}

// const DESCRIPTION_MAX_LENGTH = 200;

export default function UpdateAllergyDialog(props: { onClose: () => void; allergy: AllergyInput }) {
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();
  const companyId = useCompanyId();

  const [update, { error, loading }] = useUpdateAllergy();
  const [deleteAllergy] = useDeleteAllergy();
  const dialog = useDialog();

  const [allergy, setAllergy] = useState<AllergyInput>({ code: '', title: '', description: '' });
  const [emptyValidation, setEmptyValidation] = useState<Record<string, boolean>>({
    code: false,
    title: false,
  });

  useEffect(() => {
    setAllergy({
      code: props.allergy.code,
      title: props.allergy.title,
      description: props.allergy.description,
    });
  }, [props.allergy]);

  const isPassValidation = () => {
    let flag = true;
    if (allergy.code == '') {
      setEmptyValidation((item) => ({
        ...item,
        code: true,
      }));
      flag = false;
    }

    if (allergy.title == '') {
      setEmptyValidation((item) => ({
        ...item,
        title: true,
      }));
      flag = false;
    }

    return flag;
  };

  const onSubmit = () => {
    if (!isPassValidation()) return;

    update({
      variables: {
        companyId: companyId,
        code: props.allergy.code,
        input: {
          ...allergy,
          code: allergy.code.trim(),
          title: allergy.title.trim(),
        },
      },
    })
      .then(() => {
        props.onClose();
        showAlert({
          status: 'success',
          message: t('menu.allergy.update.alert.success.message', 'Successfully updated allergy'),
          title: t('menu.allergy.alert.success.title', 'Success'),
        });
      })
      .catch((e: Error) => {
        showAlert({
          status: 'error',
          message: e.message,
          title: t('menu.allergy.alert.error.title', 'Error'),
        });
      });
  };

  const deleteAllergyDialog = () => {
    dialog.destructAlert({
      message: t('menu-allergy.table.delete.message', 'Are you sure? this action can be undone'),
      textButton: t('menu-allergy.delete.button', 'Delete'),
      title: t('menu-allergy.delete.title', 'Delete allergy'),
      onConfirm: async () => {
        try {
          await deleteAllergy({
            variables: {
              companyId,
              code: allergy.code,
            },
          });
          showAlert({
            status: 'success',
            title: t('menu-allergy.delete.alert.success.title', 'Success'),
            message: t('menu-allergy.delete.alert.success.message', 'Successfully delete allergy'),
          });
        } catch (e) {
          showAlert({
            status: 'error',
            title: t('menu-allergy.delete.alert.error.title', 'Error'),
            message: t('menu-allergy.delete.alert.success.message', 'Delete allergy error'),
          });
        }
      },
    });
  };

  return (
    <Card>
      <CardHeader title={t('menu.allergy.update.header', 'Update Allergy')} onCrossButton={props.onClose} />

      <ApplicationErrorView error={error} />

      <CardContent>
        <FormLayout>
          <FormItem title={t('menu.allergic.code.title', 'Code')}>
            <TextInput
              type="text"
              label="code"
              value={allergy.code}
              placeholder={t('menu.allergy.code.placeholder', 'Code')}
              name={`code`}
              error={emptyValidation.code ? t('allergy.create.empty-error.code', 'Code is require') : undefined}
              onChange={(e) => {
                setAllergy({
                  ...allergy,
                  code: e.target.value,
                });
              }}
            />
          </FormItem>

          <FormItem title={t('menu.allergic.title.title', 'Title')}>
            <TextInput
              type="text"
              label="title"
              value={allergy.title}
              placeholder={t('menu.allergy.title.placeholder', 'Title')}
              name={`title`}
              error={emptyValidation.title ? t('allergy.create.empty-error.title', 'Title is require') : undefined}
              onChange={(e) => {
                setAllergy({
                  ...allergy,
                  title: e.target.value,
                });
              }}
            />
          </FormItem>

          {/*<FormItem title={t('menu.allergic.code.description', 'Description')}>*/}
          {/*  <textarea*/}
          {/*    className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 min-h-36*/}
          {/*    placeholder:text-gray-400"*/}
          {/*    placeholder={t('menu.allergy.description.placeholder', 'Description')}*/}
          {/*    value={allergy.description}*/}
          {/*    name="description"*/}
          {/*    onChange={(e) => {*/}
          {/*      setAllergy({*/}
          {/*        ...allergy,*/}
          {/*        description: e.target.value,*/}
          {/*      });*/}
          {/*    }}*/}
          {/*    maxLength={DESCRIPTION_MAX_LENGTH}*/}
          {/*  />*/}
          {/*</FormItem>*/}
        </FormLayout>
      </CardContent>

      <CardFooter>
        <div className="grow">
          <DestroyButton onClick={deleteAllergyDialog}>{t('common.button-delete', 'Delete')} </DestroyButton>
        </div>
        <PrimaryButton loading={loading} onClick={onSubmit}>
          {t('common.button-udpate', 'Update')}
        </PrimaryButton>
        <SecondaryButton onClick={props.onClose}>{t('common.button.cancel', 'Cancel')}</SecondaryButton>
      </CardFooter>
    </Card>
  );
}
