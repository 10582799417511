import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation DeleteScheduleAvailabilityLimitation($id: ID!) {
    deleteScheduleAvailabilityLimitation(id: $id) {
      id
    }
  }
`);

export default function useDeleteSchedule() {
  return useMutation(MUTATION, {
    refetchQueries: ['getScheduleAvailabilityLimitations'],
  });
}
