import Card from '@packages/ui/card/card.tsx';
import { Service, TemporaryAvailabilityLimitationItemFragment } from '@client/graphql/types/graphql.ts';
import { useTranslate } from '@tolgee/react';
import CardHeader from '@packages/ui/card/card-header.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import TableRow from '@packages/ui/table/table-row.tsx';
import { ArrowRightIcon } from '@heroicons/react/16/solid';
import moment from 'moment-timezone';
import { useFocusedCompanyContext } from '@packages/core/company/focused-company-context.ts';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import AvailabilityTemporaryCreate from '@client/page/management/availability-limitation/component/availability-temporary-create.tsx';
import AvailabilityTemporaryUpdate from '@client/page/management/availability-limitation/component/availability-temporary-update.tsx';

export default function AvailabilityLimitationTemporaryList(props: {
  service: Service;
  data: TemporaryAvailabilityLimitationItemFragment[];
  refetch: () => void;
}) {
  const { t } = useTranslate();
  const timezone = useFocusedCompanyContext().settings.timezone;
  const dialogManager = useModalManagerContext();

  const createModal = () => {
    dialogManager.showDialog((onClose) => <AvailabilityTemporaryCreate service={props.service} onClose={onClose} />);
  };

  const updateModal = (data: TemporaryAvailabilityLimitationItemFragment) => {
    dialogManager.showDialog((onClose) => (
      <AvailabilityTemporaryUpdate service={props.service} data={data} onClose={onClose} />
    ));
  };

  return (
    <Card>
      <CardHeader title={t('availability-limitation-temporary-list.title')}>
        <PrimaryButton onClick={createModal}>{t('common.button.create')}</PrimaryButton>
        <SecondaryButton onClick={props.refetch}>
          <ArrowPathIcon className="w-5 h-5" />
        </SecondaryButton>
      </CardHeader>

      <CardTableContent>
        <Table
          data={props.data}
          refetch={props.refetch}
          build={(temporary) => <TemporaryRow temporary={temporary} timezone={timezone} updateModal={updateModal} />}
        >
          <TableHeaderColumn alignment={TableCellAlignment.Left}>
            {t('availability-limitation-temporary-list.table-header-column.period')}
          </TableHeaderColumn>
          <TableHeaderColumn alignment={TableCellAlignment.Center}>
            {t('availability-limitation-temporary-list.table-header-column.during')}
          </TableHeaderColumn>
          <TableHeaderColumn className="hidden md:table-cell" alignment={TableCellAlignment.Center}>
            {t('availability-limitation-temporary-list.table-header-column.note')}
          </TableHeaderColumn>
          <TableHeaderColumn alignment={TableCellAlignment.Right}>
            {t('availability-limitation-temporary-list.table-header-column.capacity')}
          </TableHeaderColumn>
        </Table>
      </CardTableContent>
    </Card>
  );
}

function TemporaryRow(props: {
  temporary: TemporaryAvailabilityLimitationItemFragment;
  timezone: string;
  updateModal: (data: TemporaryAvailabilityLimitationItemFragment) => void;
}) {
  const { temporary } = props;
  const { startHour, endHour, startDate, endDate } = resolveTemporaryTime(
    temporary.startTime,
    temporary.endTime,
    props.timezone
  );

  return (
    <TableRow
      onClick={() => {
        props.updateModal(props.temporary);
      }}
      key={temporary.id}
    >
      <TableHeaderColumn alignment={TableCellAlignment.Left}>
        {startDate == endDate ? (
          <p className="flex flex-col  sm:flex-row flex-wrap gap-x-2 font-normal tracking-wide w-full justify-start items-start sm:items-center">
            <span>{endDate}</span>
          </p>
        ) : (
          <p className="flex flex-col  sm:flex-row flex-wrap gap-x-2 font-normal tracking-wide w-full justify-start items-start sm:items-center">
            <span>{startDate}</span>
            <ArrowRightIcon className="hidden sm:inline-block w-3 h-3" />
            <span>{endDate}</span>
          </p>
        )}
      </TableHeaderColumn>

      <TableHeaderColumn className="" alignment={TableCellAlignment.Center}>
        <p className="flex flex-col sm:flex-row flex-wrap gap-x-2 font-normal tracking-wide w-full justify-center items-center">
          <span>{startHour}</span>
          <ArrowRightIcon className="hidden sm:inline-block w-3 h-3" />
          <span>{endHour}</span>
        </p>
      </TableHeaderColumn>

      <TableHeaderColumn className="hidden md:table-cell " alignment={TableCellAlignment.Center}>
        <div className="font-normal tracking-wide max-w-60 truncate text-gray-600  mx-auto">{temporary.message}</div>
      </TableHeaderColumn>

      <TableHeaderColumn className="" alignment={TableCellAlignment.Right}>
        <p className="font-normal tracking-wide">{temporary.capacity}</p>
      </TableHeaderColumn>
    </TableRow>
  );
}

function resolveTemporaryTime(startTimeParam: string, endTimeParam: string, timezone: string) {
  const startTime = moment.tz(startTimeParam, timezone);
  const endTime = moment.tz(endTimeParam, timezone);

  return {
    startHour: startTime.format('HH:mm'),
    endHour: endTime.format('HH:mm'),
    startDate: startTime.format('YYYY-MM-DD'),
    endDate: endTime.format('YYYY-MM-DD'),
  };
}
