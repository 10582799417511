import { OnlineOrderListItemFragment, OnlineOrderState } from '@client/graphql/types/graphql.ts';
import CurrencyView from '@packages/core/utils/currency-view.tsx';
import { useTranslate } from '@tolgee/react';

const OnlineOrderDailySummarize = (props: { order: readonly OnlineOrderListItemFragment[] }) => {
  const totalOrders = props.order.length;
  // Params
  const { t } = useTranslate();
  // const branchProvider = useFocusedBranchContext();
  // const branchID = branchProvider.branch?.id ?? '';
  // const { data: capacityData } = useGetCurrentOnlineOrderCapacity(branchID);

  const sumTotalOrders = props.order.reduce((acc, order) => acc + (order.totalPrice ?? 0), 0);
  const averageRevenue = totalOrders > 0 ? sumTotalOrders / totalOrders : 0;

  const canceledOrders = props.order.filter((order) => order.state === OnlineOrderState.Canceled).length;

  // if (!capacityData) {
  //   return null;
  // }
  //
  // const capacity =
  //   // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  //   capacityData.getCurrentOnlineOrderCapacity !== null
  //     ? capacityData.getCurrentOnlineOrderCapacity > 0
  //       ? capacityData.getCurrentOnlineOrderCapacity
  //       : 0
  //     : undefined;

  return (
    <div className="px-6 py-2 flex flex-col space-y-0">
      <div className="text-lg text-gray-500">
        <span>
          {t('online-order.dashboard.daily-summarize.revenue', 'Revenue')}: <CurrencyView price={sumTotalOrders} /> /{' '}
          {t('online-order.dashboard.daily-summarize.average', 'Average')}: <CurrencyView price={averageRevenue} /> /{' '}
          {t('online-order.dashboard.daily-summarize.received', 'Received')}: {totalOrders}{' '}
          {t('online-order.dashboard.daily-summarize.orders', 'Orders')} /{' '}
          {t('online-order.dashboard.daily-summarize.cancelled', 'Cancelled')}: {canceledOrders}{' '}
          {t('online-order.dashboard.daily-summarize.orders', 'Orders')}
        </span>
      </div>

      {/*{capacity !== undefined && (*/}
      {/*  <div>*/}
      {/*    <span className="text-lg text-gray-500">*/}
      {/*      {t('reservation.schedule.capacity', 'Current capacity')}: {capacity}*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default OnlineOrderDailySummarize;
