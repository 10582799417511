import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const UPDATE_CONTRACT_FEE = graphql(`
  mutation UpdateContractFee($id: ID!, $input: CompanyContractFeeUpdateInput!) {
    updateContractFee(id: $id, input: $input) {
      id
    }
  }
`);

export function useUpdateContractFee() {
  return useMutation(UPDATE_CONTRACT_FEE, { refetchQueries: ['Contract'] });
}
