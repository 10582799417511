import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@client/app/component/global-error-boundary.tsx';
import App from '@client/app/app.tsx';
import Root from '@client/app/root.tsx';
import FocusedCompany from '@client/page/splash/focused-company.tsx';
import { TableReservationRouter } from '@client/page/management/table-reservation/table-reservation.router.tsx';
import { MenuRouter } from '@client/page/management/menu/menu.router.tsx';
import { BannerRouter } from '@client/page/management/banner/banner.router.tsx';
import { BranchRouter } from '@client/page/management/branch/branch.router.tsx';
import { TableRouter } from '@client/page/management/table/table.router.tsx';
import { UserCompanyRouter } from '@client/page/user-company-role/user-company.router.tsx';
import { OnlineOrderRouter } from '@client/page/management/online-order/online-order.router';
import { AuthProtection } from '@client/app/protections/auth-protection.tsx';
import FocusedBranch from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch.tsx';
import SignIn from '@client/page/auth/signin.tsx';
import CompanySelectionPage from '@client/page/company/company-selection-page.tsx';
import UserProfile from '@client/page/user-profile/user-profile';
import UserNotificationListPage from '@client/page/management/user-notificartion/user-notification-list/user-notification-list-page';
import ResetPassword from '@client/page/reset-password/reset-password';
import CompanySettings from '@client/page/management/company-settings/company-settings';
import { DashboardRouter } from '@client/page/dashboard/dashboard.router.tsx';
import { InvoiceRouter } from '@client/page/management/invoice/invoice.router.tsx';
import BranchEdit from '@client/page/management/branch/branch-edit/components/branch-edit.tsx';
import { ApiKeyRouter } from '@client/page/api-key/api-key.router.tsx';
import { OpeningTimeServiceType } from '@client/graphql/types/graphql';
import OpeningTimeSetupPage from '@client/page/management/opening-time/opening-time-setup/opening-time-setup-page';
import { AppointmentRouter } from '@client/page/management/appointment/appointment.router.tsx';
import { TeamRouter } from '@client/page/management/team/team.router.tsx';
import HubPageSetting from '@client/page/management/company-settings/hubpage-setting.tsx';
import CompanyQuickCreateForm from '@client/page/company/company-quick-create-form.tsx';
import CustomLocationProvider from '@client/app/research/custom-location-provider.tsx';
import QuickCreateResult from '@client/page/quick-create-form/result/quick-create-result.tsx';
import { PrinterRouter } from '@client/page/management/printer/printer.router.tsx';
import { HolidayRouter } from '@client/page/management/holiday/holiday-router.tsx';
import { CustomerRoute } from '@client/page/management/customer/customer.route.tsx';
import { SettingsRouter } from '@client/page/settings/settings.router.tsx';
import AdminConfig from '@client/page/management/admin-config/admin-config.tsx';
import Logging from '@client/page/management/logging/logging.tsx';
import ContractListPage from '@client/page/management/contract/contract-list/contract-list.tsx';
import ContractCreate from '@client/page/management/contract/contract-create/contract-create.tsx';
import WithoutNavLayout from '@client/app/without-nav-layout.tsx';
import NotificationCenterProvider from '@packages/ui/notification/notification-center-provider.tsx';
import ModalProvider from '@packages/ui/modal/modal-provider.tsx';
import { PushServiceContextProvider } from '@client/module/push-notification/push-service-context-provider.tsx';
import Allergy from '@client/page/management/menu/allergy/allergy.tsx';
import ContractDetail from '@client/page/management/contract/contract-detail/contract-detail.tsx';
import ContractUpdate from '@client/page/management/contract/contract-update/contract-update.tsx';
import ContractCompaniesList from '@client/page/management/contract/contract-company-list/contract-companies-list.tsx';
import { OrganisationManagerRouter } from '@client/page/management/organisation-manager/organisation-manager.router.tsx';

export function lazyDefaultBuilder(loader: () => Promise<{ readonly default: () => JSX.Element }>) {
  return async () => {
    return {
      Component: (await loader()).default,
    };
  };
}

export const router = createBrowserRouter(
  [
    {
      errorElement: <ErrorBoundary />,
      element: <Root />,
      children: [
        {
          path: 'login',
          element: <SignIn />,
        },
        {
          element: (
            <AuthProtection>
              <FocusedCompany>
                <FocusedBranch>
                  <CustomLocationProvider>
                    <NotificationCenterProvider>
                      <ModalProvider>
                        <PushServiceContextProvider>
                          <Outlet />
                        </PushServiceContextProvider>
                      </ModalProvider>
                    </NotificationCenterProvider>
                  </CustomLocationProvider>
                </FocusedBranch>
              </FocusedCompany>
            </AuthProtection>
          ),
          children: [
            {
              path: 'company-selection',
              children: [
                {
                  path: '',
                  element: <CompanySelectionPage />,
                },
                {
                  path: 'create',
                  children: [
                    {
                      path: '',
                      element: <CompanyQuickCreateForm />,
                    },
                    {
                      path: 'account',
                      element: <QuickCreateResult />,
                    },
                  ],
                },
              ],
            },
            {
              children: [
                {
                  element: <App />,
                  children: [
                    {
                      index: true,
                      element: <Navigate to={'dashboard'} />,
                    },
                    {
                      path: 'current-branch/:branchId',
                      element: <BranchEdit focusBranch={true} />,
                    },
                    {
                      path: 'admin-configuration',
                      element: <AdminConfig />,
                    },
                    {
                      path: 'logging',
                      element: <Logging />,
                    },
                    {
                      path: 'allergy',
                      element: <Allergy />,
                    },

                    {
                      path: 'contract-management',
                      element: <WithoutNavLayout />,
                      children: [
                        {
                          path: '',
                          element: <ContractCompaniesList />,
                        },
                        {
                          path: ':companyId',
                          children: [
                            {
                              path: 'contracts',
                              element: <ContractListPage />,
                            },
                            {
                              path: 'create',
                              element: <ContractCreate />,
                            },
                            {
                              path: 'detail/:contractId',
                              children: [
                                {
                                  path: '',
                                  element: <ContractDetail />,
                                },
                                {
                                  path: 'update',
                                  element: <ContractUpdate />,
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },

                    DashboardRouter,
                    BannerRouter,
                    BranchRouter,
                    TableReservationRouter,
                    MenuRouter,
                    TableRouter,
                    UserCompanyRouter,
                    OnlineOrderRouter,
                    InvoiceRouter,
                    ApiKeyRouter,
                    AppointmentRouter,
                    TeamRouter,
                    PrinterRouter,
                    CustomerRoute,
                    SettingsRouter,
                    OrganisationManagerRouter,
                    // User profile
                    {
                      path: 'user-profile',
                      handle: {
                        crumb: () => 'User Profile',
                      },
                      element: <UserProfile />,
                    },

                    // User notification
                    {
                      path: 'user-notification',
                      handle: {
                        crumb: () => 'User Notification',
                      },
                      element: <UserNotificationListPage />,
                    },

                    // Reset password
                    {
                      path: 'reset-password',
                      handle: {
                        crumb: () => 'Reset password',
                      },
                      children: [
                        {
                          path: ':userID',
                          element: <ResetPassword />,
                        },
                      ],
                    },

                    // Settings
                    {
                      path: 'settings',
                      handle: {
                        crumb: () => 'Company Settings',
                      },
                      children: [
                        {
                          index: true,
                          path: 'company',
                          handle: {
                            crumb: () => 'Company Settings',
                          },
                          element: <CompanySettings />,
                        },
                        {
                          path: 'opening-time',
                          element: (
                            <OpeningTimeSetupPage title="Opening Time" service={OpeningTimeServiceType.Location} />
                          ),
                        },

                        HolidayRouter,

                        //hubpage
                        {
                          path: 'hubpage',
                          handle: {
                            crumb: () => 'Hubpage',
                          },
                          element: <HubPageSetting />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  {}
);
