import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation CreateScheduleAvailabilityLimitation(
    $branchId: ID!
    $timeRuleInput: ScheduledLimitationTimeRuleInput!
    $input: CreateScheduledAvailabilityLimitationInput!
  ) {
    createScheduleAvailabilityLimitation(branchId: $branchId, timeRuleInput: $timeRuleInput, input: $input) {
      id
      service
      timeRule {
        daysOfWeeks
        startTime
        endTime
      }
      capacity
      message
      isDeleted
      branchId
      createdAt
    }
  }
`);

export default function useCreateSchedule() {
  return useMutation(MUTATION, {
    refetchQueries: ['getScheduleAvailabilityLimitations'],
  });
}
