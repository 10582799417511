import { useContracts } from '@client/page/management/contract/contract-list/logic/use-get-contracts.ts';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useTranslate } from '@tolgee/react';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import ContractTable from '@client/page/management/contract/contract-list/component/contract-table.tsx';
import { getFragmentData } from '@client/graphql/types';
import { ContractWithStatusFragment } from '@client/graphql/contract-fragment.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@client/module/auth/auth-context.ts';
import Loading from '@packages/ui/loading.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export default function ContractListPage() {
  const companyId = useParams().companyId ?? '';
  const navigate = useNavigate();
  const { auth } = useAuth();

  const { t } = useTranslate();

  const { data, error, loading, refetch } = useContracts(companyId);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <>Empty data</>;
  }

  const isContractManager = auth?.role.includes('CONTRACT_MANAGER');
  if (!auth?.role.includes('ADMIN') && !isContractManager) {
    return <>Permission denied</>;
  }

  const contracts = getFragmentData(ContractWithStatusFragment, data.contracts);

  return (
    <>
      <ApplicationErrorView error={error} />
      <Card>
        <CardHeader title={t('contract.list.header')} withBackButton={true} backTarget={'/contract-management'}>
          <SecondaryButton
            onClick={() => {
              void refetch();
            }}
          >
            <ArrowPathIcon className="w-4 h-4" />
          </SecondaryButton>
          {isContractManager && (
            <PrimaryButton
              onClick={() => {
                navigate('../create');
              }}
            >
              {t('common.button.create')}
            </PrimaryButton>
          )}
        </CardHeader>
        <CardTableContent>
          <ContractTable
            contracts={contracts}
            loading={loading}
            refetch={() => {
              void refetch();
            }}
          />
        </CardTableContent>
      </Card>
    </>
  );
}
