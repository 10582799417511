import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { useTranslate } from '@tolgee/react';
import UpdateAllergyDialog from '@client/page/management/menu/allergy/component/update-allergy-dialog.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import Loading from '@packages/ui/loading.tsx';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';

interface Allergy {
  code: string;
  title: string;
  description: string;
}

export default function AllergyTable(props: { allergy: Allergy[]; loading?: boolean }) {
  const { t } = useTranslate();
  const { allergy, loading } = props;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Table data={allergy} build={(item) => <TableGroupRow key={item.code} allergy={item} />}>
        <TableHeaderColumn className="w-32 md:w-48" alignment={TableCellAlignment.Center}>
          <div className="w-full">{t('menu.allergy.table.code', 'Short code')}</div>
        </TableHeaderColumn>
        <TableHeaderColumn alignment={TableCellAlignment.Left}>
          {t('menu.allergy.table.title', 'Title')}
        </TableHeaderColumn>
        <TableHeaderColumn></TableHeaderColumn>
      </Table>
    </>
  );
}

function TableGroupRow(props: { allergy: Allergy }) {
  const { allergy } = props;
  const dialogManager = useModalManagerContext();

  const editAllergy = () => {
    dialogManager.showDialog((onClose) => <UpdateAllergyDialog allergy={allergy} onClose={onClose} />);
  };

  return (
    <>
      <TableRow onClick={editAllergy} key={allergy.code} className="hover:bg-gray-100">
        <TableRowColumn className="w-32 md:w-48" alignment={TableCellAlignment.Center}>
          <p className="w-full">{allergy.code}</p>
        </TableRowColumn>
        <TableRowColumn alignment={TableCellAlignment.Left}>
          <p className="w-full">{allergy.title}</p>
        </TableRowColumn>
        <TableRowColumn className="text-end">
          <PencilSquareIcon className="w-6 h-6 text-gray-500 hover:scale-110 inline" />
        </TableRowColumn>
      </TableRow>
    </>
  );
}
