export const paymentData = [
  {
    title: 'NOT_PAYED',
    value: false,
  },
  {
    title: 'PAYED',
    value: true,
  },
];
