import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation setCompanyFeature($companyId: ID!, $name: Feature!, $enabled: Boolean!) {
    setCompanyFeature(companyId: $companyId, name: $name, enabled: $enabled) {
      name
      companyId
    }
  }
`);

export function useSetFeature() {
  return useMutation(MUTATION, { refetchQueries: ['SingleCompany'] });
}
