import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation DeleteTemporaryAvailabilityLimitation($id: ID!) {
    deleteTemporaryAvailabilityLimitation(id: $id) {
      id
    }
  }
`);

export default function useDeleteTemporary() {
  return useMutation(MUTATION, {
    refetchQueries: ['getTemporaryAvailabilityLimitations'],
  });
}
