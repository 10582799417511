import { ForwardedRef, forwardRef } from 'react';

interface CheckBoxInputProps {
  value: boolean;
  name?: string;
  onChange: (value: boolean) => void;
  children?: React.ReactNode;
}

function CheckBox(
  props: CheckBoxInputProps,
  ref?: ForwardedRef<HTMLInputElement>
) {
  return (
    <div className='flex items-center'>
      <input
        type="checkbox"
        ref={ref}
        name={props.name}
        className="left-4 top-1/2 -mt-0 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
        checked={props.value}
        onChange={(event) => {
          props.onChange(event.target.checked);
        }}
      />
      {props.children ?? props.name ?? ''}
    </div>
  );
}

const CheckBoxInput = forwardRef(CheckBox);

export default CheckBoxInput;