import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const DELETE_CONTRACT_FEE = graphql(`
  mutation DeleteContractFee($id: ID!) {
    deleteContractFee(id: $id) {
      id
    }
  }
`);

export function useDeleteContractFee() {
  return useMutation(DELETE_CONTRACT_FEE, { refetchQueries: ['Contract'] });
}
