import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const UPDATE_CONTRACT = graphql(`
  mutation UpdateContract($id: ID!, $contractData: CompanyContractUpdateInput!) {
    updateContract(id: $id, contractData: $contractData) {
      id
    }
  }
`);

export function useUpdateContract() {
  return useMutation(UPDATE_CONTRACT, { refetchQueries: ['Contract'] });
}
