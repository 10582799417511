import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { useTranslate } from '@tolgee/react';

export default function HeaderSearchBar(props: { onChange: (searchString: string) => void }) {
  const { t } = useTranslate();

  return (
    <div className="flex items-center">
      <div className="relative w-full max-w-xs">
        <label htmlFor="search" className="sr-only">
          {t('history.search-bar.title', 'Search')}
        </label>
        <div
          className="flex items-center border border-gray-300 rounded-lg bg-white px-2 py-1 shadow-sm focus-within:ring-1 focus-within:ring-gray-400">
          <MagnifyingGlassIcon aria-hidden="true" className="h-4 w-4 text-gray-400" />
          <input
            id="search"
            name="search"
            type="search"
            placeholder={t('history.search-bar.placeholder', 'Search')}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
            className="h-6  w-full border-none bg-transparent text-sm placeholder-gray-400 focus:outline-none focus:ring-0"
          />
        </div>
      </div>
    </div>
  );
}
