import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { useTranslate } from '@tolgee/react';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import useGetAllergies from '@client/page/management/menu/allergy/logic/use-get-allergies.ts';
import AllergyTable from '@client/page/management/menu/allergy/component/allergy-table.tsx';
import CreateAllergyDialog from '@client/page/management/menu/allergy/component/create-allergy-dialog.tsx';

export default function Allergy() {
  const companyId = useCompanyId();
  const { t } = useTranslate();
  const dialogManager = useModalManagerContext();

  const { data, loading } = useGetAllergies(companyId);

  if (!data) {
    return <>Data is empty</>;
  }

  const createAllergyDialog = () => {
    dialogManager.showDialog((onClose) => <CreateAllergyDialog onClose={onClose} />);
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('allergy.header', 'Allergy')}>
            <SecondaryButton onClick={createAllergyDialog} data-testid="list-create-button">
              {t('allergy.button.create-virtual-user', 'Create')}
            </SecondaryButton>
          </CardHeader>

          <CardTableContent>
            <AllergyTable allergy={data.allergies} loading={loading} />
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
