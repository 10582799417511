import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMenuProduct } from '@client/page/management/menu/menu-product-edit/logic/use-menu-product';
import { useUpdateMenuProduct } from '@client/page/management/menu/menu-product-edit/logic/use-update-menu-product';
import { formatGraphQlError, validationErrors } from '@client/module/error/error';
import CardContent from '@packages/ui/card/card-content';
import Center from '@packages/ui/center';
import Spinner from '@packages/ui/spinner';
import FormLayout from '@packages/ui/form/form-layout';
import TextInput from '@packages/ui/form/input/text-input';
import FormItem from '@packages/ui/form/form-item';
import Card from '@packages/ui/card/card';
import CardHeader from '@packages/ui/card/card-header';
import CardFooter from '@packages/ui/card/card-footer';
import SecondaryButton from '@packages/ui/button/secondary-button';
import PrimaryButton from '@packages/ui/button/primary-button';
import MenuSectionSelection from '@client/page/management/menu/menu-section/menu-section-selection-input/menu-section-selection.tsx';
import { ContentLayout, FullPrimaryContent } from 'ui/src/content-layout';
import ImageInput from '@packages/core/uploading/image-input.tsx';
import { client } from '@client/graphql/client.ts';
import { useTranslate } from '@tolgee/react';
import { Helmet } from 'react-helmet-async';
import MenuProductLabelSelect from '@client/page/management/menu/menu-product-create/component/menu-product-label-select.tsx';
import {
  MenuProductConfiguration,
  MenuProductLabel,
  MenuProductPriceMode,
  ProductConfigurationType,
} from '@client/graphql/types/graphql';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { captureException } from '@sentry/browser';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import ConvertPriceModeButton from '@client/page/management/menu/menu-product-create/component/covert-price-mode-button.tsx';
import SimpleProductPriceModeConfiguration from '@client/page/management/menu/menu-product-create/component/simple-product-price-mode-configuration.tsx';
import MenuProductConfigurationListContent from '@client/page/management/menu/menu-product-configuration-list/component/menu-product-configuration-list-content.tsx';
import ProductMenuDelete from '@client/page/management/menu/menu-product-edit/components/product-menu-delete.tsx';
import { v4 as uuidv4 } from 'uuid';
import { ApolloError } from '@apollo/client';
import { formatRawCurrency, normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import CheckBoxInput from '@packages/ui/form/input/check-box-input.tsx';
import classNames from 'classnames';
import AllergyInput from '@client/page/management/menu/allergy/component/allergy-input.tsx';
import { removeLeadingAndTrailingCommas } from '../menu-product-create/logic/remove-leading-and-trailing-commas';

interface MenuProductForm {
  title: string;
  description: string;
  images: string[];
  ingredients: string[];
  sectionId: string | null;
  configurations: MenuProductConfiguration[];
  code: string;
  discountable: boolean;
  labels: MenuProductLabel[];
  allergics: string;
  priceMode: MenuProductPriceMode;
}

const DESCRIPTION_MAX_LENGTH = 200;

export default function MenuProductEdit() {
  const productId = useParams().productId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();
  const dialog = useDialog();
  const currency = useCompanyCurrency();
  const rawOptionConfigurationId = useRef(uuidv4());

  const {
    handleSubmit,
    setError,
    clearErrors,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<MenuProductForm>({
    defaultValues: {
      title: '',
      description: '',
      images: [],
      ingredients: [],
      sectionId: null,
      discountable: false,
      configurations: [],
      code: '',
      allergics: '',
      labels: [],
      priceMode: MenuProductPriceMode.Simple,
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { update: updateConfiguration } = useFieldArray({
    control,
    name: 'configurations',
  });
  const { data, loading, error: loadingError } = useMenuProduct(productId);

  const [update, { error: updateError }] = useUpdateMenuProduct();

  const error = loadingError ?? updateError;
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  // Check configuation just have 2 values: base config and option config
  const baseAndOption =
    getValues('configurations').length == 2 &&
    getValues('configurations').find((config) => config.type == ProductConfigurationType.Base) &&
    getValues('configurations').find((config) => config.type == ProductConfigurationType.Option);

  useEffect(() => {
    if (data) {
      reset({
        title: data.menuProduct.title,
        description: data.menuProduct.description,
        images: data.menuProduct.images,
        ingredients: data.menuProduct.ingredients,
        sectionId: data.menuProduct.sectionId,
        code: data.menuProduct.code,
        discountable: !data.menuProduct.discountable,
        allergics: data.menuProduct.allergics,
        labels: data.menuProduct.labels ?? [],
        priceMode: data.menuProduct.priceMode,
        // priceMode: changePriceModeForOldVersion(data.menuProduct.configurations),
        configurations: processDisConfigurationBeforeDisplay(
          data.menuProduct.title,
          data.menuProduct.configurations,
          rawOptionConfigurationId.current,
          currency
        ),
      });
    }
  }, [data, data?.menuProduct, currency, reset]);

  const onSubmit = (data: MenuProductForm) => {
    update({
      variables: {
        id: productId,
        input: {
          ...data,
          allergics: removeLeadingAndTrailingCommas(data.allergics),
          discountable: !data.discountable,
          configurations: processConfigurationData(data.configurations),
        },
      },
    })
      .then(() => {
        navigate('..');
        showAlert({
          status: 'success',
          title: 'Success',
          message: 'Menu product has been updated',
        });
        // navigate('..');
      })
      .catch((err) => {
        if (err instanceof ApolloError) {
          const applicationErrors = formatGraphQlError(err.graphQLErrors);
          const validationError = validationErrors(applicationErrors);

          for (const field in validationError) {
            setError(
              field as keyof MenuProductForm,
              {
                type: 'server',
                message: t(validationError[field] ?? 'validation-error.common.alert'),
              },
              { shouldFocus: true }
            );
          }
        }
        captureException(err);
      });
  };

  if (!data) {
    if (loading) {
      return (
        <Center>
          <Spinner />
        </Center>
      );
    } else {
      return <div>Product not found</div>;
    }
  }
  const updatePriceModeWhenConvert = (data: MenuProductForm) => {
    update({
      variables: {
        id: productId,
        input: { ...data, configurations: processConfigurationData(data.configurations) },
      },
    }).catch((err) => {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof MenuProductForm,
            {
              type: 'server',
              message: t(validationError[field] ?? 'validation-error.common.alert'),
            },
            { shouldFocus: true }
          );
        }
      }
      captureException(err);
    });
  };

  // convert between 2 price mode
  const converPriceMode = () => {
    const priceMode = watch('priceMode');

    if (!baseAndOption) {
      dialog.destructAlert({
        title: 'Convert problem',
        message: 'You can not convert from matrix mode to simple mode',
        textButton: 'Done',
        onConfirm: async () => {
          return Promise.resolve();
        },
      });
    }

    if (priceMode == MenuProductPriceMode.Simple) {
      setValue('priceMode', MenuProductPriceMode.Matrix);
      updatePriceModeWhenConvert(getValues());
      return;
    }
    setValue('priceMode', MenuProductPriceMode.Simple);
    updatePriceModeWhenConvert(getValues());
  };

  // const changePriceModeForOldVersion = (configurations: MenuProductConfiguration[]) => {
  //   if (configurations.length == 1 && configurations[0].type == ProductConfigurationType.Base) {
  //     return MenuProductPriceMode.Simple;
  //   }
  //
  //   if (
  //     configurations.length == 2 &&
  //     configurations.find((config) => config.type == ProductConfigurationType.Option) &&
  //     configurations.find((config) => config.type == ProductConfigurationType.Base)
  //   ) {
  //     return MenuProductPriceMode.Simple;
  //   }
  //
  //   return MenuProductPriceMode.Matrix;
  // };

  function processConfigurationData(configurations: MenuProductConfiguration[]) {
    return configurations
      .map(({ id: configId, title, type, values }) => {
        return {
          id: configId,
          title,
          type,
          values: values.map(({ id: valueId, name, price }, valueIndex) => {
            let processName = name;
            if (
              getValues('priceMode') == MenuProductPriceMode.Simple &&
              type == ProductConfigurationType.Base &&
              valueIndex == 0 &&
              values.length == 1
            ) {
              processName = getValues('title');
            }
            return {
              id: valueId,
              name: processName,
              price: normalizeCurrency(String(price), currency),
            };
          }),
        };
      })
      .filter(({ type, values }) => !(type === ProductConfigurationType.Option && values.length === 0));
  }

  return (
    <>
      <div className="grid grid-cols-1  xl:grid-cols-7 w-full  gap-4">
        <ContentLayout className="xl:col-span-4">
          <Helmet>
            <title>{t('menu.menu-detail.product.create.helmet.title')}</title>
            <meta name="description" content="This page allows you to create a new product" />
          </Helmet>
          <FullPrimaryContent>
            <Card>
              <CardHeader title={t('menu.menu-detail.product.edit.header')} withBackButton={true}>
                <ConvertPriceModeButton covertPriceMode={converPriceMode} />
              </CardHeader>

              <CardContent>
                <FormLayout>
                  <FormItem className="max-w-lg" title={''}>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name="sectionId"
                      render={({ field: { onChange, value } }) => (
                        <MenuSectionSelection menuId={data.menuProduct.menuId} value={value} onChange={onChange} />
                      )}
                    />
                  </FormItem>

                  <div className="max-w-lg grid sm:grid-cols-4 gap-3" title={''}>
                    <FormItem
                      className="max-w-lg sm:col-span-1"
                      title={t('menu.menu-detail.product.edit.form-input.code.title')}
                    >
                      <Controller
                        rules={{
                          required: t(
                            'menu.menu-detail.product.edit.form-input.code.validate.required',
                            'Code is required'
                          ),
                          minLength: {
                            value: 1,
                            message: t(
                              'menu.menu-detail.product.edit.form-input.code.validate.min-length',
                              'Min length is 1'
                            ),
                          },
                        }}
                        defaultValue={''}
                        control={control}
                        name="code"
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            data-testid="code-input"
                            type="text"
                            label="code"
                            name=""
                            placeholder={t('menu.menu-detail.product.edit.form-input.code.placeholder')}
                            value={value}
                            error={errors.code?.message}
                            autoComplete={'off'}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormItem>

                    <FormItem
                      className="max-w-lg sm:col-span-3"
                      title={t('menu.menu-detail.product.edit.form-input.name.title', 'Title')}
                    >
                      <Controller
                        rules={{
                          required: t(
                            'menu.menu-detail.product.edit.form-input.name.validate.required',
                            'Name is required'
                          ),
                          minLength: {
                            value: 3,
                            message: t(
                              'menu.menu-detail.product.edit.form-input.name.validate.min-length',
                              'Min length is 3'
                            ),
                          },
                          maxLength: {
                            value: 100,
                            message: t(
                              'menu.menu-detail.product.edit.form-input.name.validate.max-length',
                              'Max length is 100'
                            ),
                          },

                          pattern: {
                            value: /^\S.*\S$/,
                            message: t(
                              'menu.menu-detail.product.edit.form-input.name.validate.no-whitespace',
                              'Menu product title must not start or end with whitespace'
                            ),
                          },
                        }}
                        defaultValue={''}
                        control={control}
                        name="title"
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            data-testid="title-input"
                            type="text"
                            label="title"
                            name=""
                            placeholder={t('menu.menu-detail.product.edit.form-input.name.placeholder')}
                            value={value}
                            error={errors.title?.message}
                            autoComplete={'off'}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormItem>
                  </div>

                  <FormItem
                    subtitle={`(${String(getValues('description').length)}/${String(DESCRIPTION_MAX_LENGTH)})`}
                    className="max-w-lg"
                    title={t('menu.menu-detail.product.edit.form-input.description.title')}
                  >
                    <Controller
                      control={control}
                      name="description"
                      defaultValue={''}
                      render={({ field: { onChange, value } }) => (
                        <textarea
                          className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 min-h-36"
                          placeholder={t('menu.menu-detail.product.edit.form-input.description.placeholder')}
                          value={value}
                          name=""
                          onChange={onChange}
                          maxLength={DESCRIPTION_MAX_LENGTH}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem
                    className="max-w-lg"
                    title={t('menu.menu-detail.product.create.form-input.allergic', 'Allergic')}
                  >
                    <Controller
                      control={control}
                      name="allergics"
                      rules={{
                        validate: (value) =>
                          !value.includes(';') || t('validation.no-semicolon', 'Allergic field cannot contain ";"'),
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <AllergyInput
                          errorMessage={error?.message}
                          allergyString={value}
                          handleEachAllergyCode={(value: string) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem title="Label" className="max-w-md">
                    <Controller
                      control={control}
                      name="labels"
                      defaultValue={[]}
                      render={({ field: { onChange, value } }) => (
                        <MenuProductLabelSelect
                          label={value}
                          handleLabel={(value, selected) => {
                            if (selected) {
                              onChange([...getValues('labels'), value]);
                            } else {
                              onChange(getValues('labels').filter((label) => label !== value));
                            }
                          }}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem
                    className="max-w-xl"
                    title={t('menu.menu-detail.product.edit.form-input.discountable.title', 'Additional options')}
                  >
                    <div
                      className={classNames(
                        !data.menuProduct.section?.discountable ? 'opacity-45 relative flex items-center' : ''
                      )}
                    >
                      <Controller
                        control={control}
                        name="discountable"
                        render={({ field: { onChange, value } }) => (
                          <CheckBoxInput value={value} onChange={onChange}>
                            <div className="flex items-baseline gap-x-1">
                              {/*<PercentBadgeIcon className="w-5 h-5 fill-red-300" />*/}
                              {t('menu.menu-detail.product.edit.form-input.discountable.checkbox', 'Not discountable')}
                              {data.menuProduct.section?.discountable === false && (
                                <p className="text-xs">
                                  (
                                  {t(
                                    'menu-product.detail.discountable.category-discountable-overwrite',
                                    'Category "{name}" is not discountable',
                                    { name: data.menuProduct.section.name }
                                  )}
                                  )
                                </p>
                              )}
                            </div>
                          </CheckBoxInput>
                        )}
                      />
                    </div>
                  </FormItem>

                  <div>
                    <FormItem title={'Logo'}>
                      <Controller
                        control={control}
                        name="images"
                        defaultValue={[]}
                        render={({ field: { onChange, value } }) => (
                          <ImageInput client={client} images={value} onChange={onChange} multiple={true} />
                        )}
                      />
                      <p className="text-red-500 text-sm mt-2">{validationError.images}</p>
                    </FormItem>
                  </div>

                  <div>
                    {validationError.configurations && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {validationError.configurations}
                      </p>
                    )}
                  </div>

                  <ApplicationErrorView error={error} />
                </FormLayout>
              </CardContent>

              <CardFooter>
                <div className="flex justify-center space-x-4 w-96">
                  <SecondaryButton
                    className="flex-1"
                    onClick={() => {
                      navigate('..');
                    }}
                  >
                    {t('common.button.cancel')}
                  </SecondaryButton>
                  <PrimaryButton
                    data-testid="update-button"
                    className="w-32 flex-1"
                    onClick={() => {
                      clearErrors();
                      handleSubmit(onSubmit)().catch(captureException);
                    }}
                    loading={loading}
                  >
                    {t('common.button.update')}
                  </PrimaryButton>
                </div>
              </CardFooter>
            </Card>
          </FullPrimaryContent>
        </ContentLayout>

        <div className="xl:col-span-3 [1300px]:col-span-2 xl:max-w-[600px] flex flex-col gap-4">
          <SimpleProductPriceModeConfiguration
            fields={getValues('configurations')}
            update={updateConfiguration}
            control={control}
            errors={errors}
            isDisplayed={getValues('priceMode') == MenuProductPriceMode.Simple}
          />

          <MenuProductConfigurationListContent isDisplayed={getValues('priceMode') == MenuProductPriceMode.Matrix} />

          <ContentLayout>
            <FullPrimaryContent>
              <Card>
                <CardHeader title={t('menu.menu-detail.product.edit.extra-action.header')} />
                <CardContent>
                  <ProductMenuDelete productId={productId} />
                </CardContent>
              </Card>
            </FullPrimaryContent>
          </ContentLayout>
        </div>
      </div>
    </>
  );
}

function processDisConfigurationBeforeDisplay(
  productTitle: string,
  configurations: MenuProductConfiguration[],
  rawOptionConfigurationId: string,
  currency: string
) {
  let result = [...configurations];
  // Trường hợp dữ liệu lấy lên chỉ có base, phải add thêm 1 configuration option để render ra bảng extra
  if (configurations.length == 1 && configurations[0] && configurations[0].type == ProductConfigurationType.Base) {
    result.push({
      id: rawOptionConfigurationId,
      title: 'Option',
      type: ProductConfigurationType.Option,
      values: [],
    });
  }

  // Sắp xếp để product variation luôn hiển thị trên product extra
  result = result.sort((a, b) => {
    if (a.type === ProductConfigurationType.Base && b.type !== ProductConfigurationType.Base) {
      return -1; // đưa 'Base' lên trước
    }
    if (a.type !== ProductConfigurationType.Base && b.type === ProductConfigurationType.Base) {
      return 1; // đưa các phần tử khác xuống sau 'Base'
    }
    return 0; // giữ nguyên thứ tự cho các phần tử còn lại
  });

  return result.map(({ id, title, type, values }) => ({
    id,
    title,
    type,
    values: values
      .map(({ id, name, price }) => {
        return {
          id,
          name,
          price: Number(formatRawCurrency(price, currency).value.replace(',', '.')),
        };
      })
      .sort((a, b) => {
        if (a.name == productTitle && b.name !== productTitle) {
          return -1;
        }
        if (a.name != productTitle && b.name == productTitle) {
          return 1;
        }
        return 0;
      }),
  }));
}
