import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import {
  DaysOfWeek, MenuProductConfiguration, MenuProductLabel, MenuProductPriceMode,
  MenuSectionTimeRuleType,
} from '@client/graphql/types/graphql.ts';
import {
  useCreateMenuSection,
} from '@client/page/management/menu/menu-section-create/logic/use-create-menu-section.ts';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import classNames from 'classnames';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { Controller, useForm, UseFormSetValue, useWatch } from 'react-hook-form';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { ApolloError } from '@apollo/client';
import { captureException } from '@sentry/browser';
import CheckBoxInput from '@packages/ui/form/input/check-box-input.tsx';
import SelectDaysOfWeek from '@client/page/management/menu/menu-section-create/component/select-days-of-week.tsx';

interface FormData {
  name: string;
  description: string;
  code: string;
  enabled: boolean;
  discountable: boolean;
  timeRuleType: MenuSectionTimeRuleType;
  start: string;
  end: string;
  daysOfWeek: DaysOfWeek[];
  isAllDays: boolean;
}

interface MenuProductForm {
  title: string;
  description: string;
  images: string[];
  ingredients: string[];
  sectionId: string | null;
  configurations: MenuProductConfiguration[];
  code: string;
  discountable: boolean;
  labels: MenuProductLabel[];
  allergics: string;
  priceMode: MenuProductPriceMode;
}

const menuSectionTypeList = [
  { key: 'Always', value: MenuSectionTimeRuleType.Always },
  { key: 'Range time', value: MenuSectionTimeRuleType.RangeTime },
];

interface QuickCreateSectionProps {
  onClose: () => void;
  menuId: string;
  setValue: UseFormSetValue<MenuProductForm>;
}


// eslint-disable-next-line react/prop-types
const QuickCreateSection: React.FC<QuickCreateSectionProps> = ({ onClose, menuId, setValue }) => {

  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();

  const [create, { loading, error }] = useCreateMenuSection();

  const form = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      code: '',
      enabled: false,
      discountable: false,
      daysOfWeek: [],
      isAllDays: false,
      timeRuleType: MenuSectionTimeRuleType.RangeTime,
      start: '00:00',
      end: '23:59',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { formState: { errors }, register, handleSubmit, control, setError } = form;
  const timeRuleType = useWatch({ control, name: 'timeRuleType' });

  const onSubmit = async (data: FormData) => {
    try {
      const result = await create({
        variables: {
          menuId: menuId,
          input: {
            discountable: !data.discountable,
            name: data.name.trim(),
            description: data.description.trim(),
            code: data.code.trim(),
            enabled: true,
          },
          timeRuleInput:
            data.timeRuleType == MenuSectionTimeRuleType.Always
              ? {
                type: data.timeRuleType,
              }
              : {
                type: data.timeRuleType,
                start: data.start.trim(),
                end: data.end.trim(),
                daysOfWeek: data.daysOfWeek,
                isAllDays: data.isAllDays,
              },
        },
      });
      if (result.data?.createMenuSection.id) {
        setValue('sectionId', result.data.createMenuSection.id);
        showAlert({
          status: 'success',
          title: 'Success',
          message: 'New category has been created',
        });
        onClose();
      }
    } catch (e) {
      if (e instanceof ApolloError) {

        const applicationErrors = formatGraphQlError(error?.graphQLErrors);
        const validationError = validationErrors(applicationErrors);
        for (const field in validationError) {
          setError(
            field as keyof FormData,
            {
              type: 'sever',
              message: t(validationError[field] ?? 'validation-error.common.alert'),
            },
            { shouldFocus: true },
          );
        }
      }
      captureException(e);
    }
  };


  return (
    <form>
      <Card>
        <CardHeader title={t('menu.menu-product.menu-section-quick-create.title', 'Create category')} />
        <CardContent>
          <FormLayout>
            <FormItem className="max-w-md" title={t('menu.menu-detail.category.create.form-input.position.title')}>
              <Controller
                rules={{
                  required: t(
                    'menu.menu-detail.category.create.form-input.code.validate.required',
                    'Code is required',
                  ),
                  minLength: {
                    value: 1,
                    message: t(
                      'menu.menu-detail.category.create.form-input.code.validate.min-length',
                      'Min length is 1',
                    ),
                  },
                  maxLength: {
                    value: 10,
                    message: t(
                      'menu.menu-detail.category.create.form-input.code.validate.max-length',
                      'Max length is 10',
                    ),
                  },
                }}
                control={control}
                name="code"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    data-testid="code-input"
                    type="text"
                    label="code"
                    name=""
                    placeholder={t('menu.menu-detail.category.create.form-input.code.placeholder', 'Code')}
                    value={value}
                    error={errors.code?.message}
                    autoComplete={'off'}
                    onChange={onChange}
                  />
                )}
              />

            </FormItem>

            <FormItem className="max-w-md" title={t('menu.menu-detail.category.create.form-input.name.title')}>
              <Controller
                rules={{
                  required: t(
                    'menu.menu-detail.category.create.form-input.name.validate.required',
                    'Name is required',
                  ),
                  minLength: {
                    value: 3,
                    message: t(
                      'menu.menu-detail.category.create.form-input.name.validate.min-length',
                      'Min length is 3',
                    ),
                  },
                  maxLength: {
                    value: 100,
                    message: t(
                      'menu.menu-detail.category.create.form-input.name.validate.max-length',
                      'Max length is 100',
                    ),
                  },
                }}
                control={control}
                name="name"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    data-testid="name-input"
                    type="text"
                    label="name"
                    name=""
                    placeholder={t('menu.menu-detail.category.create.form-input.name.placeholder')}
                    value={value}
                    error={errors.name?.message}
                    autoComplete={'off'}
                    onChange={onChange}
                  />
                )}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('menu.menu-detail.category.create.form-input.description.title')}>
              <Controller
                control={control}
                name="description"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    data-testid="code-input"
                    type="text"
                    label="description"
                    name="description"
                    placeholder={t('menu.menu-detail.category.create.form-input.description.placeholder', 'Description')}
                    value={value}
                    error={errors.description?.message}
                    autoComplete={'off'}
                    onChange={onChange}
                  />
                )}
              />
            </FormItem>

            <FormItem className="max-w-md"
                      title={t('menu.menu-detail.category.create.form-input.discountable.title', 'Additional options')}>
              <Controller name="discountable" control={control} render={({ field: { onChange, value } }) => (
                <CheckBoxInput
                  value={value}
                  onChange={onChange}
                  name={t('menu.menu-detail.category.create.form-input.discountable.checkbox', 'No Discountable')}
                />
              )} />

            </FormItem>
            <FormItem title={'Time rule type'} className="max-w-md">
              <div className="flex flex-col gap-4">
                <Controller
                  control={control}
                  name="timeRuleType"
                  render={({ field: { onChange, value } }) => (
                    <MenuSelectionInput
                      title=""
                      data={menuSectionTypeList}
                      value={menuSectionTypeList.find((item) => item.value === value) ?? menuSectionTypeList[0]}
                      onChange={(selectedItem) => {
                        onChange(selectedItem?.value);
                      }}
                      build={(item) => ({
                        id: item?.value ?? '',
                        name: item?.key ?? MenuSectionTimeRuleType.Always,
                      })}
                      className="-my-2 w-full"
                    />
                  )}
                />

              </div>
              {timeRuleType == MenuSectionTimeRuleType.RangeTime && (
                <>
                  <div className="mt-8">
                    <label className=" flex items-center gap-1.5 text-sm font-medium leading-6 text-gray-900">
                      <span>Range</span>
                    </label>
                    <FormItem title={''}
                              className={classNames('relative', form.getValues('isAllDays') ? 'opacity-45' : '')}>
                      <div className="max-w-full flex gap-2">
                        <input
                          className="rounded-md max-w-full border-gray-300"
                          {...register('start', {
                            deps: ['end'],
                            validate: (openTime, form) => {
                              if (openTime > form.end) {
                                return t(
                                  'create-form.form-input.open-time.validation.invalid',
                                  'Open time must be before close time',
                                );
                              }
                            },
                          })}
                          aria-label="Start Date"
                          id="start"
                          {...register('start')}
                          type="time"
                        />

                        <input
                          className="rounded-md max-w-full border-gray-300"
                          {...register('end', {
                            deps: ['start'],
                            validate: (closeTime, form) => {
                              if (closeTime < form.start) {
                                return t(
                                  'create-form.form-input.close-time.validation.invalid',
                                  'Close time must be after open time',
                                );
                              }
                            },
                          })}
                          aria-label="End Date"
                          id="end"
                          {...register('end')}
                          type="time"
                        />
                      </div>

                      {form.getValues('isAllDays') && <div className="absolute inset-0"></div>}
                    </FormItem>
                  </div>

                  <FormItem className="" title={''}>
                    <div className="flex items-center">
                      <Controller control={control} name={'isAllDays'} render={({ field: { onChange, value } }) => (
                        <CheckBoxInput
                          value={value}
                          onChange={onChange}
                        />
                      )} />
                      <span
                        className="text-xs italic text-gray-600">Apply for all time on current day opening time</span>
                    </div>
                  </FormItem>
                  <div>
                    {form.getValues('start') > form.getValues('end') && !form.getValues('isAllDays') && (
                      <p className="mt-2 text-xs italic text-red-600" id="email-error">
                        {'Choose startDate and endDate, startDate must be smaller than endDate'}
                      </p>
                    )}
                  </div>

                  <FormItem className="mt-8" title="Days of week">
                    <Controller
                      rules={{
                        required: t('validation-error.common.alert', 'This field is required'),
                      }}
                      control={control} name={'daysOfWeek'} render={({ field: { onChange, value } }) => (
                      <SelectDaysOfWeek
                        value={value}
                        onChange={onChange}
                      />
                    )} />
                    {errors.daysOfWeek && (
                      <p className="mt-2 text-xs italic text-red-600" id="email-error">
                        {errors.daysOfWeek.message}
                      </p>
                    )}
                  </FormItem>
                </>
              )}

              {form.getValues('timeRuleType') == MenuSectionTimeRuleType.Always && <div></div>}
            </FormItem>

          </FormLayout>
        </CardContent>
      </Card>
      <CardFooter>
        <div className="flex justify-center space-x-4 w-96">
          <SecondaryButton
            className="flex-1"
            onClick={() => {
              onClose();
            }}
          >
            {t('common.button.cancel')}
          </SecondaryButton>
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32 flex-1"
            onClick={() => {
              handleSubmit(onSubmit)().catch(captureException);
            }}
            loading={loading}
          >
            {t('menu.menu-detail.category.create.button.create', 'Create')}
          </PrimaryButton>
        </div>
      </CardFooter>
    </form>
  );
};
export default QuickCreateSection;