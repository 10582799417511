import { useCompanies } from '@packages/core/company/hook/use-companies.ts';
import Loading from '@packages/ui/loading.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { useTranslate } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import useCheckAdminRole from '@client/module/auth/check-role-hook.ts';

export default function OrganisationManager() {
  const { data, loading, error } = useCompanies(0, 50);
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { isAdmin, isSuperAdmin } = useCheckAdminRole();

  if (!isAdmin && !isSuperAdmin) {
    return <>Permission denied</>;
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    throw error;
  }

  if (!data) {
    return <div>No companies found</div>;
  }

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title="Company" />
          <CardTableContent>
            <Table
              data={data.companies}
              build={(item) => {
                if (!item.features) {
                  return null;
                }
                return (
                  <TableRow
                    onClick={() => {
                      navigate(`./company-feature/${item.id}`);
                    }}
                    key={item.id}
                  >
                    <TableRowColumn>
                      <div
                        onClick={() => {
                          // no action
                        }}
                      >
                        {item.name}
                      </div>
                    </TableRowColumn>
                    <TableRowColumn>
                      <div>
                        {t(`organization.company.category.${item.category.toString()}`, item.category.toString())}
                      </div>
                    </TableRowColumn>
                    <TableRowColumn>
                      <div className="text-sm text-gray-500">
                        <span>
                          {item.features.at(0) &&
                            t(`organisation.${item.features.at(0)?.name.toString() ?? ''}`, item.features.at(0)?.name)}
                        </span>
                        {item.features.length > 1 && <span> (+{item.features.length - 1})</span>}
                      </div>
                    </TableRowColumn>
                  </TableRow>
                );
              }}
              loading={loading}
              error={error}
            >
              <TableHeaderColumn>{t('company-name.header', 'Company')}</TableHeaderColumn>
              <TableHeaderColumn>{t('company-name.category.header', 'Category')}</TableHeaderColumn>
              <TableHeaderColumn>{t('company-name.active-feature.header', 'Active feature')}</TableHeaderColumn>
            </Table>
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
