import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import { MenuSource, NailAppointmentConfigurationItemFragment } from '@client/graphql/types/graphql.ts';
import { Controller, useForm } from 'react-hook-form';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { useMenuList } from '@client/page/management/menu/menu-list/logic/use-menu-list.ts';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { useTranslate } from '@tolgee/react';
import ToggleInput from '@packages/ui/form/input/toggle-input.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import Spinner from '@packages/ui/spinner.tsx';
import useUpdateAppointmentConfiguration from '@client/page/management/appointment/configuration/logic/use-update-appointment-configuration.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/browser';
import AppointmentStorefrontSetting from '@client/page/management/appointment/configuration/component/appointment-storefront-setting.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import { useCallback, useEffect } from 'react';
import { BlockerFunction, useBlocker } from 'react-router-dom';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { getFragmentData } from '@client/graphql/types';
import { NailAppointmentConfigurationFragment } from '@client/graphql/nail-appointment-configuration-fragment.ts';

interface AppointmentBranchConfiguration {
  menuId: string;
  enable: boolean;
  autoConfirm: boolean;
  hourBlocking: string;
  appointmentLeadMinutes: string;
  appointmentTraitMinutes: string;
  reminderTime: string;
  capacityEnabled: boolean;
}

export default function AppointmentBranchConfigurationGeneral(props: {
  configuration: NailAppointmentConfigurationItemFragment;
  refetchData: () => Promise<void>;
}) {
  const { t } = useTranslate();
  const { configuration } = props;
  const branch = useFocusedBranchContext();
  const dialogManager = useModalManagerContext();
  const dialog = useDialog();

  const { data: menuList, loading: menuLoading } = useMenuList(MenuSource.NailAppointment);
  const [update] = useUpdateAppointmentConfiguration();
  const { showAlert } = useNotificationCenter();

  const {
    control,
    setValue,
    setError,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm<AppointmentBranchConfiguration>({
    defaultValues: {
      menuId: configuration.menuId ?? '',
      enable: configuration.enable,
      autoConfirm: configuration.autoConfirm,
      hourBlocking: configuration.hourBlocking.toString(),
      appointmentLeadMinutes: configuration.appointmentLeadMinutes.toString(),
      appointmentTraitMinutes: configuration.appointmentTraitMinutes.toString(),
      reminderTime: configuration.reminderTime.toString(),
      capacityEnabled: configuration.capacityEnabled,
    },
  });

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
    [isDirty]
  );

  const blocker = useBlocker(shouldBlock);

  const resetForm = useCallback(() => {
    reset({}, { keepDefaultValues: true });
    blocker.reset?.();
  }, [blocker, reset]);

  useEffect(() => {
    const handleBlocker = () => {
      dialogManager.showDialog((onClose) => (
        <Card>
          <CardHeader title={t('appointment.configuration.update-dialog.title', 'Content has been changed')} />

          <CardContent>
            <p className="text-lg text-gray-600">
              {t('appointment.configuration.update-dialog.content', 'The content will be lost if you do not save.')}
            </p>
          </CardContent>

          <CardFooter>
            <div className="w-full flex justify-between">
              <PrimaryButton
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t('common.button.update')}
              </PrimaryButton>

              <div className="flex gap-2">
                <DestroyButton
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  {t('common.button.discard')}
                </DestroyButton>
                <SecondaryButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('common.button.cancel')}
                </SecondaryButton>
              </div>
            </div>
          </CardFooter>
        </Card>
      ));
    };

    if (blocker.state === 'blocked') {
      handleBlocker();
    }

    return () => {
      blocker.reset?.();
    };
  });

  if (menuLoading) {
    return <Spinner />;
  }

  const handleSubmit = () => {
    dialog.confirmAlert({
      title: t('appointment.configuration.alert.confirm.title', 'Update Appointment'),
      message: t(
        'appointment.configuration.alert.confirm.message',
        'Are you sure you want to update this configuration?'
      ),
      textButton: t('common.button.update'),
      onConfirm: async () => {
        return update({
          variables: {
            branchId: branch.branch?.id ?? '',
            input: {
              enable: getValues('enable'),
              menuId: getValues('menuId'),
              autoConfirm: getValues('autoConfirm'),
              hourBlocking: Number(getValues('hourBlocking')),
              appointmentLeadMinutes: Number(getValues('appointmentLeadMinutes')),
              appointmentTraitMinutes: Number(getValues('appointmentTraitMinutes')),
              reminderTime: Number(getValues('reminderTime')),
              capacityEnabled: getValues('capacityEnabled'),
            },
          },
        })
          .then(async (e) => {
            await props.refetchData();
            const result = getFragmentData(
              NailAppointmentConfigurationFragment,
              e.data?.updateAppointmentBranchConfiguration
            );

            if (!result) {
              showAlert({
                title: t('alert.title.error'),
                message: t('appointment.configuration.alert.value-return.message', 'Can not get result after update.'),
                status: 'error',
              });
              return;
            }

            reset({
              menuId: result.menuId ?? '',
              enable: result.enable,
              autoConfirm: result.autoConfirm,
              hourBlocking: result.hourBlocking.toString(),
              appointmentLeadMinutes: result.appointmentLeadMinutes.toString(),
              appointmentTraitMinutes: result.appointmentTraitMinutes.toString(),
              reminderTime: result.reminderTime.toString(),
              capacityEnabled: result.capacityEnabled,
            });
            blocker.reset?.();

            showAlert({
              title: t('alert.title.success'),
              message: t(
                'appointment.configuration.alert.confirm.message',
                'Your appoitment configuration is updated successfully.'
              ),
              status: 'success',
            });
          })
          .catch((err: Error) => {
            if (err instanceof ApolloError) {
              const applicationErrors = formatGraphQlError(err.graphQLErrors);
              const validationError = validationErrors(applicationErrors);
              for (const field in validationError) {
                setError(
                  field as keyof AppointmentBranchConfiguration,
                  {
                    type: 'server',
                    message: t(validationError[field] ?? 'validation-error.common.alert'),
                  },
                  { shouldFocus: true }
                );
              }
            }

            captureException(err);
          });
      },
    });
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardContent>
            <FormLayout>
              <FormItem className="max-w-md pb-4 border-b" title="">
                <Controller
                  control={control}
                  name="menuId"
                  render={({ field: { value } }) => (
                    <MenuSelectionInput
                      title={t('appointment.configuration.edit.general.menu-selection.title', 'Menu')}
                      data={menuList?.menus ?? []}
                      value={menuList?.menus.find((item) => item.id == value) ?? null}
                      onChange={(newValue) => {
                        setValue('menuId', newValue.id);
                      }}
                      build={(item) => {
                        return {
                          id: item.id,
                          name: item.title,
                        };
                      }}
                      className="max-w-sm mb-2"
                    />
                  )}
                />
              </FormItem>

              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('appointment.configuration.edit.general.description.title', 'Active')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.configuration.form-input.active.description',
                    'If you enable this option, customers could make an appointment'
                  )}
                </p>
                <Controller
                  control={control}
                  name="enable"
                  render={({ field: { value, onChange } }) => <ToggleInput value={value} onChange={onChange} />}
                />
              </FormItem>

              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('appointment.configuration.edit.general.hour-blocking.title', 'Hour blocking')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.configuration.form-input.hour-blocking.description',
                    'How many appointment options are there in 1 hour'
                  )}
                </p>
                <Controller
                  control={control}
                  name="hourBlocking"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      label="hour-blocking"
                      name="hourBlocking"
                      placeholder={t('appointment.configuration.form-input.hour-blocking.placeholder', 'Hour blocking')}
                      suffix={<span className="text-gray-500 text-sm">{t('blocks.suffix', 'blocks')}</span>}
                      type="number"
                    />
                  )}
                />
              </FormItem>

              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('appointment.configuration.edit.general.auto-confirm.title', 'Auto confirm')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.configuration.form-input.auto-confirm.description',
                    'If you enable this option, appointment request is auto confirmed.'
                  )}
                </p>
                <Controller
                  control={control}
                  name="autoConfirm"
                  render={({ field: { value, onChange } }) => <ToggleInput value={value} onChange={onChange} />}
                />
              </FormItem>

              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('appointment.configuration.edit.general.lead-minutes.title', 'Lead minutes')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.setting.form-input.lead-minutes.description',
                    'Minimum time a customer can begin booking appointments.'
                  )}
                </p>

                <Controller
                  control={control}
                  name="appointmentLeadMinutes"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      label="appointment-lead-minutes"
                      name="appointmentLeadMinutes"
                      placeholder={t('appointment.configuration.edit.general.lead-minutes.placeholder', 'Lead minutes')}
                      suffix={<span className="text-gray-500 text-sm">{t('minutes.suffix', 'minutes')}</span>}
                      type="number"
                    />
                  )}
                />
              </FormItem>

              <FormItem
                className="max-w-md pb-4 border-b"
                title={t('appointment.configuration.edit.general.trait-minutes.title', 'Trait minutes')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.configuration.form-input.trait-minutes.description',
                    'The last appointment could be allowed before closing (minutes).'
                  )}
                </p>

                <Controller
                  control={control}
                  name="appointmentTraitMinutes"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      label="appointment-trait-minutes"
                      name="appointmentTraitMinutes"
                      placeholder={t(
                        'appointment.configuration.edit.general.trait-minutes.placeholder',
                        'Trait minutes'
                      )}
                      suffix={<span className="text-gray-500 text-sm">{t('minutes.suffix', 'minutes')}</span>}
                      type="number"
                    />
                  )}
                />
              </FormItem>

              <FormItem
                className="max-w-md pb-4"
                title={t('appointment.configuration.edit.general.reminder-time.title', 'Reminder time')}
              >
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.configuration.form-input.reminder-time.description',
                    'The system will send an email reminder to the customer before the appointment (Minutes).'
                  )}
                </p>
                <Controller
                  control={control}
                  name="reminderTime"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      label="reminder-time"
                      name="reminderTime"
                      suffix={<span className="text-gray-500 text-sm">{t('minutes.suffix', 'minutes')}</span>}
                      placeholder={t(
                        'appointment.configuration.edit.general.reminder-time.placeholder',
                        'Reminder time'
                      )}
                      type="number"
                    />
                  )}
                />
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            <PrimaryButton
              onClick={() => {
                handleSubmit();
              }}
            >
              {t('common.button.update', 'Update')}
            </PrimaryButton>
          </CardFooter>
        </Card>

        <AppointmentStorefrontSetting branchID={branch.branch?.id ?? ''} />
      </PrimaryContent>
    </ContentLayout>
  );
}
