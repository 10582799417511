import TextInput from '@packages/ui/form/input/text-input.tsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import CreateAllergyDialog from '@client/page/management/menu/allergy/component/create-allergy-dialog.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import { PlusIcon } from '@heroicons/react/24/outline';
import useGetAllergies from '@client/page/management/menu/allergy/logic/use-get-allergies.ts';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';

export default function AllergyInput(props: {
  allergyString: string;
  handleEachAllergyCode: (value: string) => void;
  errorMessage?: string;
}) {
  const { allergyString, handleEachAllergyCode, errorMessage } = props;

  // Services
  const companyId = useCompanyId();
  const { t } = useTranslate();
  const dialogManager = useModalManagerContext();

  // Queries
  const { data } = useGetAllergies(companyId);

  // State
  const [searchString, setSearchString] = useState<string>('');
  const [isFocusing, setFocusing] = useState<boolean>(false);
  const [position, setPosition] = useState<'top' | 'bottom'>('bottom');
  const textRef = useRef<HTMLDivElement>(null);
  const isFocusingRef = useRef(false);

  const filterAllergies = useMemo(() => {
    return data?.allergies.filter((item) => item.code.includes(searchString)) ?? [];
  }, [data?.allergies, searchString]);

  useEffect(() => {
    const updatePosition = () => {
      if (textRef.current) {
        const rect = textRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        setPosition(rect.top < viewportHeight / 2 ? 'bottom' : 'top');
      }
    };

    updatePosition();
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, []);

  const handleTyping = (value: string) => {
    const lastValue = value.split(',').at(-1);

    if (lastValue == undefined) {
      setSearchString(value);
    } else {
      setSearchString(lastValue.replaceAll(' ', ''));
    }

    handleEachAllergyCode(value);
  };

  const addNewAllergy = (value: string) => {
    const currentAllergies = allergyString
    .split(',')
    .map((item) => item.trim().toLowerCase())
    .filter((item) => item !== '');
    const lowerCaseValue = value.toLowerCase();

    for (const allergy of currentAllergies) {
      if (allergy == lowerCaseValue) {
        return;
      }
    }

    const updatedAllergyString = currentAllergies.concat(value).join(', ') + ', ';
    handleEachAllergyCode(updatedAllergyString);
  };

  const createAllergyDialog = () => {
    dialogManager.showDialog((onClose) => <CreateAllergyDialog addNewAllergy={addNewAllergy} onClose={onClose} />);
  };

  return (
    <div
      ref={textRef}
      onFocus={() => {
        isFocusingRef.current = true; // Lưu trạng thái isFocusing vào biến tham chiếu
        setFocusing(true);
      }}
      onBlur={(e) => {
        // Đảm bảo rằng trạng thái chỉ bị thay đổi nếu mất focus toàn bộ container
        if (!e.currentTarget.contains(e.relatedTarget)) {
          isFocusingRef.current = false;
          setFocusing(false);
        }
      }}
      className="relative w-full"
    >
      <TextInput
        data-testid="code-input"
        type="text"
        label="allergy"
        name=""
        placeholder={''}
        value={allergyString}
        autoComplete={'off'}
        onChange={(e) => {
          handleTyping(e.target.value);
        }}
      />

      {errorMessage && <p className="text-red-500 text-sm mt-1 ml-0.5">{errorMessage}</p>}

      {isFocusing && (
        <div
          className={`absolute inset-x-0 left-0 z-40 min-w-32 rounded-md border bg-white shadow divide divide-y-2 max-h-48 overflow-scroll
        ${position === 'bottom' ? 'top-full mt-2' : 'bottom-full mb-2'}`}
        >
          <div
            onMouseDown={(e) => {
              e.preventDefault(); // Ngăn sự kiện blur xảy ra
              createAllergyDialog();
            }}
            className="px-4 py-2 flex items-center justify-between hover:bg-gray-50"
          >
            <span className="text-base tracking-wide">{t('menu-product.allergy.add', 'Create a new allergy')}</span>
            <PlusIcon className="w-6 h-6 text-gray-500" />
          </div>
          {filterAllergies.length == 0 && (
            <div className="p-3 text-center">
              <span className="text-sm italic tracking-wide text-gray-500">
                {`${t('menu-product.allergy.no-have', 'Allergy code not found')} '${searchString}'`}
              </span>
            </div>
          )}
          {filterAllergies.map((item) => (
            <div
              onMouseDown={(e) => {
                e.preventDefault(); // Ngăn sự kiện blur xảy ra
                addNewAllergy(item.code);
              }}
              className="px-4 py-3 text-sm flex items-center hover:bg-gray-50"
              key={item.code}
            >
              <span className="min-w-12 font-semibold">{item.code}</span>
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
