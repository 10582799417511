import { useTranslate } from '@tolgee/react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useCreateContract } from '@client/page/management/contract/contract-create/logic/use-contract-create.tsx';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/browser';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { useContractCategories } from '@client/page/management/contract/common-logic/use-get-contract-category.ts';
import { availableCurrencies, normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import ContractFeeTable from '@client/page/management/contract/contract-create/component/contract-fee-table.tsx';
import moment from 'moment-timezone';
import { useCompanySettings } from '@client/page/management/company-settings/logic/use-company-setting.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useAuth } from '@client/module/auth/auth-context.ts';
import Loading from '@packages/ui/loading.tsx';

export interface ContractFeeForm {
  title: string;
  note?: string | null;
  amount: number;
  currency: string;
}

interface ContractCategory {
  code: string;
  title: string;
}

interface ContractForm {
  contractCategory: ContractCategory | null;
  hasPayed: boolean;
  startTime: string;
  endTime: string;
  serviceTitle: string;
  customerName: string;
  customerNote?: string;
  paymentAmount: number;
  paymentCurrency: string;
  contractFeeData: ContractFeeForm[];
}

const DESCRIPTION_LIMIT = 500;

export default function ContractCreate() {
  const companyId = useParams().companyId ?? '';
  const { t } = useTranslate();
  const { auth } = useAuth();
  const { showAlert } = useNotificationCenter();
  const dialog = useDialog();
  const navigate = useNavigate();

  const { data: companySettingData, loading: companySettingLoading } = useCompanySettings(companyId);
  const { data: categoryRawData, loading: categoryLoading } = useContractCategories(companyId);
  const [create] = useCreateContract();

  const {
    handleSubmit,
    setError,
    clearErrors,
    control,
    watch,
    formState: { errors },
  } = useForm<ContractForm>({
    defaultValues: {
      contractCategory: null,
      hasPayed: false,
      startTime: '',
      endTime: '',
      serviceTitle: '',
      customerName: '',
      customerNote: '',
      paymentAmount: 0,
      paymentCurrency: 'EUR',
      contractFeeData: [],
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { fields, update, append, remove } = useFieldArray({
    control,
    name: 'contractFeeData',
  });

  if (companySettingLoading || categoryLoading) {
    return <Loading />;
  }

  if (!categoryRawData) {
    return <>Category data is empty</>;
  }

  if (!companySettingData) {
    return <>Company data is empty</>;
  }

  const isContractManager = auth?.role.includes('CONTRACT_MANAGER');

  if (!isContractManager) {
    return <>Permission denied</>;
  }

  const categoryData = categoryRawData.contractCategories ?? [];

  const onSubmit = async (form: ContractForm) => {
    if (!form.contractCategory) {
      setError('contractCategory', {
        type: 'validate',
        message: t('contract.create.category-empty.message', 'Contract category is required'),
      });
      return;
    }

    try {
      const result = await create({
        variables: {
          companyId: companyId,
          categoryCode: form.contractCategory.code,
          contractData: {
            hasPayed: form.hasPayed,
            startTime: form.startTime,
            endTime: form.endTime,
            serviceTitle: form.serviceTitle,
            customerName: form.customerName,
            customerNote: form.customerNote,
            paymentAmount: normalizeCurrency(form.paymentAmount.toString(), form.paymentCurrency),
            paymentCurrency: form.paymentCurrency,
          },
          contractFeeData: form.contractFeeData,
        },
      });

      if (result.data?.createContract) {
        showAlert({
          status: 'success',
          title: t('contract.create.success.title'),
          message: t('contract.create.success.message'),
        });

        navigate('../contracts');
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof ContractForm,
            {
              type: 'server',
              message: t(validationError[field] ?? 'validation-error.common.alert'),
            },
            { shouldFocus: true }
          );
        }
      }
      captureException(err);
    }
  };

  const isDuplicate = (newItem: ContractFeeForm) => {
    return fields.some(
      (item) =>
        item.title === newItem.title &&
        item.amount === newItem.amount &&
        item.currency === newItem.currency &&
        item.note == newItem.note
    );
  };

  const contractFeeAppend = (item: ContractFeeForm) => {
    if (isDuplicate(item)) {
      dialog.destructAlert({
        title: t('contract.create-fee.duplicate.title', 'Duplicate'),
        message: t('contract.create-fee.duplicate.des', 'This data has already been added'),
        textButton: 'Done',
        onConfirm: async () => {
          return Promise.resolve();
        },
      });
      return;
    }
    append(item);
  };

  const contractFeeUpdate = (index: number, item: ContractFeeForm) => {
    if (isDuplicate(item)) {
      dialog.destructAlert({
        title: t('contract.create-fee.duplicate.title', 'Duplicate'),
        message: t('contract.create-fee.duplicate.des', 'This data has already been added'),
        textButton: 'Done',
        onConfirm: async () => {
          return Promise.resolve();
        },
      });
      return;
    }
    update(index, item);
  };

  const contractFeeRemove = (index: number) => {
    remove(index);
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader
            title={t('contract.create.header')}
            withBackButton={true}
            backTarget={`/contract-management/${companyId}/contracts`}
          >
            <PrimaryButton
              onClick={() => {
                clearErrors();
                handleSubmit(onSubmit)().catch(captureException);
              }}
            >
              {t('common.button.create')}
            </PrimaryButton>
          </CardHeader>
        </Card>

        <Card>
          <CardHeader title={t('contract.create.data.header')} />
          <CardContent>
            <FormLayout>
              <FormItem className="max-w-lg" title={''}>
                <Controller
                  control={control}
                  name="contractCategory"
                  rules={{
                    required: t('contract-create.category.validate.required', 'Contract category is required'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <MenuSelectionInput
                      title={t('contract.create.category.header')}
                      data={categoryData}
                      value={value}
                      onChange={onChange}
                      build={(item: { code: string; title: string }) => {
                        return {
                          id: item.code,
                          name: item.title,
                        };
                      }}
                    />
                  )}
                />
                {errors.contractCategory && (
                  <p className="text-red-500 text-sm mt-2">{errors.contractCategory.message}</p>
                )}
              </FormItem>

              {/*paymentAmount*/}
              {/*paymentCurrency*/}
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-6 max-w-lg">
                  <FormItem className="max-w-lg" title={t('contract.create.currency.header')}>
                    <Controller
                      control={control}
                      name="paymentCurrency"
                      rules={{
                        required: t(
                          'contract-create.paymentCurrency.validate.required',
                          'Payment currency is required'
                        ),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <MenuSelectionInput
                            title={''}
                            data={availableCurrencies}
                            value={value}
                            onChange={onChange}
                            build={(item) => {
                              return {
                                id: item,
                                name: item,
                              };
                            }}
                          />
                          {errors.paymentCurrency && (
                            <p className="text-red-500 text-sm mt-2">{errors.paymentCurrency.message}</p>
                          )}
                        </>
                      )}
                    />
                  </FormItem>

                  <FormItem className="max-w-lg" title={t('contract.create.amount.header')}>
                    <Controller
                      control={control}
                      name="paymentAmount"
                      rules={{
                        required: t('contract-create.amount.validate.required', 'Payment amount require number'),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextInput
                            data-testid="title-input"
                            type="number"
                            label="paymentAmount"
                            name="paymentAmount"
                            placeholder={t('contract.create.amount.placeholder')}
                            value={value}
                            error={undefined}
                            autoComplete={'off'}
                            onChange={onChange}
                          />
                          {errors.paymentAmount && (
                            <p className="text-red-500 text-sm mt-2">{errors.paymentAmount.message}</p>
                          )}
                        </>
                      )}
                    />
                  </FormItem>
                </div>
              </div>

              {/*startTime*/}
              {/*endTime*/}
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-x-4 gap-y-6 max-w-lg">
                  <FormItem title={t('contract.create.start-date.header')}>
                    <Controller
                      control={control}
                      name="startTime"
                      rules={{
                        required: t('contract-create.startTIime.validate.required', 'Start time is required'),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <input
                          className="rounded-md w-full border-gray-300"
                          aria-label="Start Date"
                          id="start"
                          name="start"
                          value={value}
                          type="date"
                          onChange={(newValue) => {
                            if (watch('endTime') && moment(newValue.target.value).isAfter(moment(watch('endTime')))) {
                              setError('startTime', {
                                type: 'validate',
                                message: t('contract.create.time-error.message', 'Start time must be before end time'),
                              });
                              return;
                            }
                            onChange(newValue);
                          }}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem title={t('contract.create.end-date.header')}>
                    <Controller
                      control={control}
                      name="endTime"
                      rules={{
                        required: t('contract-create.endTime.validate.required', 'End time is required'),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <input
                          className="rounded-md w-full border-gray-300"
                          aria-label="Start Date"
                          id="end"
                          name="end"
                          value={value}
                          type="date"
                          onChange={(newValue) => {
                            if (
                              watch('startTime') &&
                              moment(newValue.target.value).isBefore(moment(watch('startTime')))
                            ) {
                              setError('endTime', {
                                type: 'validate',
                                message: t('contract.create.time-error.message', 'End time must be after start time'),
                              });
                              return;
                            }
                            onChange(newValue);
                          }}
                        />
                      )}
                    />
                  </FormItem>
                </div>
                {errors.startTime && <p className="text-red-500 text-sm mt-2">{errors.startTime.message}</p>}
                {errors.endTime && <p className="text-red-500 text-sm mt-2">{errors.endTime.message}</p>}
              </div>

              {/*serviceTitle*/}
              <FormItem className="max-w-lg" title={t('contract.create.serviceTitle.header')}>
                <Controller
                  control={control}
                  name="serviceTitle"
                  rules={{
                    required: t('contract-create.serviceTitle.validate.required', 'Service title is required'),
                    validate: (value) =>
                      value.trim() !== '' ||
                      t('contract-create.serviceTitle.validate.required', 'Service title is required'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      data-testid="title-input"
                      type="text"
                      label="serviceTitle"
                      name="serviceTitle"
                      placeholder={t('contract.create.serviceTitle.placeholder')}
                      value={value}
                      error={errors.serviceTitle?.message}
                      autoComplete={'off'}
                      onChange={onChange}
                    />
                  )}
                />
              </FormItem>

              {/*customerNote*/}
              <FormItem className="max-w-lg" title={t('contract.create.customer-note.header')}>
                <Controller
                  control={control}
                  name="customerNote"
                  render={({ field: { onChange, value } }) => (
                    <textarea
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                             focus:ring-indigo-600 sm:text-sm sm:leading-6 min-h-44"
                      maxLength={DESCRIPTION_LIMIT}
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormItem>

              {/*customerName*/}
              <FormItem className="max-w-lg" title={t('contract.create.customerName.header')}>
                <Controller
                  control={control}
                  name="customerName"
                  rules={{
                    required: t('contract-create.customerName.validate.required', 'Customer name is required'),
                    validate: (value) =>
                      value.trim() !== '' ||
                      t('contract-create.customerName.validate.required', 'Customer name is required'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      data-testid="title-input"
                      type="text"
                      label="customerName"
                      name="customerName"
                      placeholder={t('contract.create.customerName.placeholder')}
                      value={value}
                      error={errors.customerName?.message}
                      autoComplete={'off'}
                      onChange={onChange}
                    />
                  )}
                />
              </FormItem>
            </FormLayout>
          </CardContent>
        </Card>

        <ContractFeeTable
          data={watch('contractFeeData')}
          action={{
            append: contractFeeAppend,
            update: contractFeeUpdate,
            remove: contractFeeRemove,
          }}
        />
      </PrimaryContent>
    </ContentLayout>
  );
}
