import { useCompanyService } from '@packages/core/company/company-context.ts';
import { useCompanies } from '@packages/core/company/hook/use-companies.ts';
import { useNavigate } from 'react-router-dom';

import Card from 'ui/src/card/card';
import CardHeader from 'ui/src/card/card-header';
import PrimaryButton from 'ui/src/button/primary-button';
import Table from '@packages/ui/table/table.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import Loading from '@packages/ui/loading.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useAuth } from '@client/module/auth/auth-context.ts';
import HistorySearchBar from '@client/page/management/appointment/history/component/history-search-bar.tsx';
import { useCallback, useEffect, useState } from 'react';
import { CompanyCategory } from '@client/graphql/types/graphql.ts';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useTranslate } from '@tolgee/react';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';

interface CompanyData {
  __typename?: 'Company' | undefined;
  id: string;
  name: string;
}

const CompanySelectionPage = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { setCompanyID } = useCompanyService();
  const { t } = useTranslate();

  const { data, loading, error } = useCompanies(0, 50);

  const [companies, setCompanies] = useState([] as CompanyData[]);

  useEffect(() => {
    if (data) {
      setCompanies(data.companies);
    }
  }, [data]);

  const onCompanyClick = (id: string) => {
    const companyData = data?.companies.find((company) => company.id === id);
    if (companyData) {
      localStorage.setItem('language', companyData.settings.customerDefaultLanguage);
    }
    setCompanyID(id);
    navigate('/', { replace: true });
  };

  const handleSearchBar = useCallback(
    (searchString: string) => {
      if (data) {
        const regex = new RegExp(searchString, 'i');
        setCompanies(data.companies.filter((company) => regex.test(company.name)));
      }
    },
    [data]
  );

  const handleFilterCategory = useCallback(
    (category: CompanyCategory) => {
      if (data) {
        setCompanies(data.companies.filter((company) => company.category.includes(category)));
      }
    },
    [data]
  );

  const filterAll = useCallback(() => {
    if (data) {
      setCompanies(data.companies);
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    throw error;
  }

  if (!data) {
    return <div>No companies found</div>;
  }

  const contractButtonAccept = ['ADMIN', 'SUPER_ADMIN', 'CONTRACT_MANAGER'].some((role) => auth?.role.includes(role));

  return (
    <div className="container max-w-4xl  mx-auto px-4 py-8">
      <Card>
        <CardHeader title="Company">
          {auth?.role.includes('ADMIN') && (
            <PrimaryButton
              onClick={() => {
                navigate('create');
              }}
            >
              Create
            </PrimaryButton>
          )}
        </CardHeader>

        <CardTableContent>
          <HistorySearchBar onChange={handleSearchBar} />

          {/* Category Filter */}
          <div className="min-w-full  flex gap-3 items-center my-2 pl-7 ">
            <PrimaryButton className="" onClick={filterAll}>
              Show all
            </PrimaryButton>
            {Object.values(CompanyCategory).map((category) => (
              <SecondaryButton
                key={category}
                onClick={() => {
                  handleFilterCategory(category);
                }}
              >
                {t(category)}
              </SecondaryButton>
            ))}
          </div>

          <Table
            data={companies}
            build={(item) => {
              return (
                <TableRow key={item.id}>
                  <TableRowColumn>
                    <div
                      onClick={() => {
                        onCompanyClick(item.id);
                      }}
                    >
                      {item.name}
                    </div>
                  </TableRowColumn>
                  {contractButtonAccept && (
                    <TableRowColumn alignment={TableCellAlignment.Right}>
                      <SecondaryButton
                        onClick={() => {
                          navigate(`/contract-management/${item.id}/contracts`);
                        }}
                      >
                        {t('company-selection.contact-button', 'Contracts')}
                      </SecondaryButton>
                    </TableRowColumn>
                  )}
                </TableRow>
              );
            }}
            loading={loading}
            error={error}
          >
            <TableHeaderColumn>Name</TableHeaderColumn>
            <TableHeaderColumn></TableHeaderColumn>
          </Table>
        </CardTableContent>
      </Card>
    </div>
  );
};

export default CompanySelectionPage;
