import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { ReactNode } from 'react';

export default function AlertError(props: {
  title: string;
  message: string;
  className?: string;
  children?: ReactNode;
}) {
  return (
    <div className={classNames(props.className, 'rounded-md bg-yellow-50 p-4')}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">{props.title}</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <pre className="text-wrap">{props.message}</pre>
          </div>

          <div className="pt-2">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
