import useCreateSchedule from '@client/page/management/availability-limitation/logic/use-create-schedule.ts';
import { Controller, useForm } from 'react-hook-form';
import { DaysOfWeek, Service } from '@client/graphql/types/graphql.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useTranslate } from '@tolgee/react';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/browser';
import CardHeader from '@packages/ui/card/card-header.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import SelectDaysOfWeek from '@client/page/management/menu/menu-section-create/component/select-days-of-week.tsx';
import Spinner from '@packages/ui/spinner.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';

interface AvailabilityScheduledData {
  capacity: number;
  message: string;
}

interface TimeRuleData {
  daysOfWeek: DaysOfWeek[];
  startTime: string;
  endTime: string;
}

interface AvailabilityScheduledCreateProps {
  capacity: number;
  daysOfWeek: DaysOfWeek[];
  startTime: string;
  endTime: string;
  message?: string;
}

export default function AvailabilityScheduledCreate(props: { service: Service; onClose: () => void }) {
  const branchId = useFocusedBranchContext().branch?.id ?? '';

  const { showAlert } = useNotificationCenter();
  const { t } = useTranslate();

  const [create, { loading, error }] = useCreateSchedule();

  const {
    handleSubmit,
    clearErrors,
    setError,
    control,
    formState: { errors },
  } = useForm<AvailabilityScheduledCreateProps>({
    defaultValues: {
      capacity: 0,
      daysOfWeek: [],
      startTime: '00:01',
      endTime: '23:59',
      message: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const onSubmit = (values: AvailabilityScheduledCreateProps) => {
    create({
      variables: {
        branchId: branchId,
        input: {
          service: props.service,
          capacity: Number(values.capacity),
          message: values.message,
        },
        timeRuleInput: {
          daysOfWeeks: values.daysOfWeek,
          startTime: values.startTime,
          endTime: values.endTime,
        },
      },
    })
      .then(() => {
        showAlert({
          title: t('availability-schedule.create.success', 'Successful'),
          status: 'success',
          message: t('availability-schedule.create.mess', 'Schedule availability limitation has been created'),
        });
        props.onClose();
      })
      .catch((err) => {
        if (err instanceof ApolloError) {
          const applicationErrors = formatGraphQlError(err.graphQLErrors);
          const validationError = validationErrors(applicationErrors);

          for (const field in validationError) {
            setError(
              field as keyof (AvailabilityScheduledData | TimeRuleData),
              {
                type: 'server',
                message: t(validationError[field] ?? 'validation-error.common.alert'),
              },
              { shouldFocus: true }
            );
          }
        }
        captureException(err);
      });
  };
  return (
    <Card>
      <CardHeader
        title={t('availability-schedule.create.header', 'Schedule capacity create for {service}', {
          service: t(props.service),
        })}
      />
      <CardContent>
        <FormLayout>
          <FormItem className="max-w-lg" title={t('availability-schedule.create.capacity.header')}>
            <Controller
              control={control}
              name="capacity"
              rules={{
                required: t('availability-schedule.create.capacity.validate.required', 'Capacity require number'),
                min: {
                  value: 1,
                  message: t(
                    'availability-schedule.create.capacity.validate.min',
                    'Capacity must be greater than {amount}',
                    {
                      amount: 0,
                    }
                  ),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextInput
                    data-testid="title-input"
                    type="number"
                    label="capacity"
                    name="capacity"
                    placeholder={t('availability-schedule.create.capacity.placeholder')}
                    value={value}
                    error={errors.capacity?.message}
                    autoComplete={'off'}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </FormItem>

          <FormItem title={t('availability-schedule.create.range-time.header')}>
            <div className="flex flex-col sm:flex-row gap-4">
              <Controller
                control={control}
                name="startTime"
                rules={{
                  required: t('availability-schedule.create.startTime.validate.required', 'Start time required'),
                  validate: (value, formValues) => {
                    if (value && formValues.endTime) {
                      return (
                        value < formValues.endTime ||
                        t(
                          'availability-schedule.create.endTime.validate.compare',
                          'Start time must be smaller than end time'
                        )
                      );
                    }
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <input
                      className="rounded-md max-w-full border-gray-300"
                      aria-label="Start time"
                      id="startHour"
                      name="startHour"
                      value={value}
                      type="time"
                      onChange={onChange}
                    />
                    {errors.startTime && (
                      <p className="text-red-500 text-sm mt-2 max-w-36">{errors.startTime.message}</p>
                    )}
                  </div>
                )}
              />

              <Controller
                control={control}
                name="endTime"
                rules={{
                  required: t('availability-schedule.create.endTime.validate.required', 'End time required'),
                  validate: (value, formValues) => {
                    if (value && formValues.startTime) {
                      return (
                        formValues.startTime < value ||
                        t(
                          'availability-schedule.create.endTime.validate.compare',
                          'End time must be greater than start time'
                        )
                      );
                    }
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <input
                      className="rounded-md max-w-full border-gray-300"
                      aria-label="End time"
                      id="endTime"
                      name="endTime"
                      value={value}
                      type="time"
                      onChange={onChange}
                    />
                    {errors.endTime && <p className="text-red-500 text-sm mt-2 max-w-36">{errors.endTime.message}</p>}
                  </div>
                )}
              />
            </div>
          </FormItem>

          <FormItem className="mt-8" title={t('availability-schedule.create.days-of-week.header')}>
            <Controller
              control={control}
              name="daysOfWeek"
              rules={{
                required: t('availability-schedule.create.dow.validate.required', 'Day of week required'),
              }}
              render={({ field: { onChange, value } }) => <SelectDaysOfWeek value={value} onChange={onChange} />}
            />
            {errors.daysOfWeek && <p className="text-red-500 text-sm mt-2">{errors.daysOfWeek.message}</p>}
          </FormItem>

          <FormItem className="max-w-md" title={t('availability-schedule.create.description.title')}>
            <Controller
              control={control}
              name="message"
              render={({ field: { onChange, value } }) => (
                <textarea
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                             focus:ring-indigo-600 sm:text-sm sm:leading-6 min-h-44"
                  defaultValue={value ?? ''}
                  onChange={(newValue) => {
                    onChange(newValue.target.value.trim());
                  }}
                />
              )}
            />
          </FormItem>
        </FormLayout>
      </CardContent>

      {validationError.dayOfWeekConflicts && (
        <AlertError
          title={t('availability.capacity-rule-conflict', 'Capacity rule conflict')}
          message={validationError.dayOfWeekConflicts}
        />
      )}

      <CardFooter>
        <SecondaryButton onClick={props.onClose}>{t('common.button.cancel')}</SecondaryButton>
        <PrimaryButton
          onClick={() => {
            clearErrors();
            handleSubmit(onSubmit)().catch(captureException);
          }}
        >
          {t('common.button.create')}
        </PrimaryButton>
        {loading && <Spinner />}
      </CardFooter>
    </Card>
  );
}
