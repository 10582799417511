import { ContractChangeItemFragment, ContractFeeChangeItemFragment } from '@client/graphql/types/graphql.ts';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import { useTranslate } from '@tolgee/react';
import moment from 'moment-timezone';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';

export interface ContractFeeChangeWithTitle {
  title: string;
  change: ContractFeeChangeItemFragment[];
}

interface CommonChangeData {
  label: string;
  contractFeeTitle?: string;
  timestamp: string;
  change: {
    key: string;
    oldValue: string;
    newValue: string;
  }[];
}

export default function ContractChange(props: {
  contractChange: ContractChangeItemFragment[];
  contractFeeChange: ContractFeeChangeWithTitle[];
}) {
  const { contractChange, contractFeeChange } = props;
  const { t } = useTranslate();

  const timezone = useFocusedCompany().settings.timezone;

  const convertedContractChange: CommonChangeData[] = contractChange.map((item1) => ({
    label: `${item1.user?.firstName ?? ''} ${item1.user?.lastName ?? ''}`,
    timestamp: item1.timestamp,
    change: item1.data.map((item2) => ({
      key: item2.key,
      newValue: item2.new ?? '',
      oldValue: item2.old ?? '',
    })),
  }));

  const convertedContractFeeChange: CommonChangeData[] = contractFeeChange.flatMap((item1) => {
    return item1.change.map((item2) => ({
      label: `${item2.user?.firstName ?? ''} ${item2.user?.lastName ?? ''}`,
      contractFeeTitle: item1.title,
      timestamp: item2.timestamp,
      change: item2.data.map((item3) => ({
        key: item3.key,
        newValue: item3.new ?? '',
        oldValue: item3.old ?? '',
      })),
    }));
  });

  const totalChangeList = convertedContractChange
    .concat(convertedContractFeeChange)
    .sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf());

  return (
    <Card>
      <CardHeader title={t('contract.contract-detail.contract-changes.title', 'Changes')} />
      <CardContent>
        {totalChangeList.map((item, index) => {
          const isContractFeeChange = !!item.contractFeeTitle;
          const changeCount = item.change.length;
          let data = <li></li>;

          if (changeCount > 0 && isContractFeeChange) {
            const firstElement = item.change.at(0);
            if (changeCount == 1 && firstElement && firstElement.key == 'isDeleted') {
              data = (
                <li>
                  {t('contract.contract-detail.contract-fee-changes.delete-des', 'Delete contract fee: {title}', {
                    title: item.contractFeeTitle,
                  })}
                </li>
              );
            } else {
              data = (
                <>
                  {item.change.map((data, dataIndex) => {
                    return (
                      <li key={dataIndex}>
                        {t(
                          'contract.contract-detail.contract-fee-changes.des',
                          '[Contract fee: {title}] Update field {field} from {oldValue} to value {value}',
                          {
                            title: item.contractFeeTitle,
                            field: t(`contract.detail.change.field.${data.key}`),
                            oldValue: data.oldValue !== '' ? data.oldValue : t('empty', 'empty'),
                            value: data.newValue !== '' ? data.newValue : t('empty', 'empty'),
                          }
                        )}
                      </li>
                    );
                  })}
                </>
              );
            }
          }

          if (changeCount > 0 && !isContractFeeChange) {
            data = (
              <>
                {item.change.map((data, dataIndex) => {
                  return (
                    <li key={dataIndex}>
                      {t(
                        'contract.contract-detail.contract-changes.des',
                        'Update field {field} from {oldValue} to value {value}',
                        {
                          field: t(`contract.detail.change.field.${data.key}`),
                          oldValue: data.oldValue !== '' ? data.oldValue : t('empty', 'empty'),
                          value: data.newValue !== '' ? data.newValue : t('empty', 'empty'),
                        }
                      )}
                    </li>
                  );
                })}
              </>
            );
          }

          if (changeCount == 0 && !isContractFeeChange) {
            data = <li>{t('contract.contract-detail.contract-changes.create-des', 'Create new contract')}</li>;
          }

          if (changeCount == 0 && isContractFeeChange) {
            data = (
              <li>
                {t('contract.contract-detail.contract-fee-changes.create-des', 'Create new contract fee: {title}', {
                  title: item.contractFeeTitle,
                })}
              </li>
            );
          }

          return (
            <div
              key={index}
              className="flex mb-4 border-b pb-4 flex-col md:flex-row justify-between items-start md:items-center sm:min-w-44"
            >
              <div className="flex-1">
                <p className="mb-1 text-sm text-gray-500">{item.label}</p>
                <ul className="text-sm ml-0.5 break-all text-ellipsis tracking-wide space-y-1 list-disc ml-4">
                  {data}
                </ul>
                <p className="text-sm text-gray-400 ml-4 mt-1 italic">
                  {moment.tz(item.timestamp, timezone).format('YYYY-MM-DD')}
                </p>
              </div>
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
}
