import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const CONTRACTS = graphql(`
    query Contract($contractId: ID!) {
        contract(id: $contractId) {
            ...ContractItem
        }
    }
`);

export function useContract(contractId: string) {
  return useQuery(CONTRACTS, {
    variables: {
      contractId,
    },
  });
}
