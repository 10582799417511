import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import { ContractFeeForm } from '@client/page/management/contract/contract-create/contract-create.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import ContractFeeCreate from '@client/page/management/contract/contract-create/component/contract-fee-create.tsx';
import ContractFeeUpdate from '@client/page/management/contract/contract-create/component/contract-fee-update.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import { ContractItemFragment } from '@client/graphql/types/graphql.ts';

export default function ContractFeeTable(props: {
  data: ContractFeeForm[];
  contractData?: ContractItemFragment;
  action?: {
    append: (item: ContractFeeForm) => void;
    update: (index: number, item: ContractFeeForm) => void;
    remove: (index: number) => void;
  };
}) {
  const { t } = useTranslate();
  const dialogManager = useModalManagerContext();

  const createContractFeeModal = () => {
    if (props.action) {
      dialogManager.showDialog((onClose) => <ContractFeeCreate onClose={onClose} create={props.action.append} />);
    }
  };

  const updateContractFeeModal = (index: number, data: ContractFeeForm) => {
    if (props.action) {
      dialogManager.showDialog((onClose) => (
        <ContractFeeUpdate
          data={data}
          onClose={onClose}
          update={props.action.update}
          index={index}
          delete={props.action.remove}
        />
      ));
    }
  };

  if (props.data.length == 0) {
    return (
      <Card>
        <CardHeader title={t('contract.contract-fee-table.header')}>
          {props.action && <PrimaryButton onClick={createContractFeeModal}>{t('common.button.create')}</PrimaryButton>}
        </CardHeader>

        <CardContent>
          <p className="text-base text-gray-500">{t('contract.table.empty')}</p>
        </CardContent>
      </Card>
    );
  }

  const profitValue = props.contractData
    ? props.contractData.paymentAmount -
      props.data.reduce((sum, cur) => {
        if (cur.currency == props.contractData?.paymentCurrency) {
          return cur.amount + sum;
        }
        return sum; // Giữ nguyên tổng nếu không khớp
      }, 0)
    : 0;

  return (
    <Card>
      <CardHeader title={t('contract.contract-fee-table.header')}>
        {props.action && <PrimaryButton onClick={createContractFeeModal}>{t('common.button.create')}</PrimaryButton>}
      </CardHeader>

      <CardTableContent>
        <Table
          data={props.data}
          build={(fee, index) => <ContractFeeRow index={index} contractFee={fee} update={updateContractFeeModal} />}
        >
          <TableHeaderColumn alignment={TableCellAlignment.Left}>
            {t('contract-fee.table-header-column.title')}
          </TableHeaderColumn>
          <TableHeaderColumn className={'hidden sm:table-cell'} alignment={TableCellAlignment.Center}>
            {t('contract-fee.table-header-column.note')}
          </TableHeaderColumn>
          <TableHeaderColumn alignment={TableCellAlignment.Right}>
            {t('contract-fee.table-header-column.amount')}
          </TableHeaderColumn>
        </Table>
      </CardTableContent>

      {!props.action && props.contractData && (
        <div className="px-1 py-2 sm:px-6 sm:py-4 flex items-center justify-between font-semibold bg-gray-50">
          <p>{t('contract.detail.profit', 'Profit')}</p>
          <p>{formatCurrency(profitValue, props.contractData.paymentCurrency)}</p>
        </div>
      )}
    </Card>
  );
}

function ContractFeeRow(props: {
  contractFee: ContractFeeForm;
  index: number;
  update?: (index: number, data: ContractFeeForm) => void;
}) {
  const { contractFee, index, update } = props;
  return (
    <TableRow
      onClick={() => {
        if (update) {
          update(index, contractFee);
        }
      }}
      key={index}
    >
      <TableHeaderColumn alignment={TableCellAlignment.Left}>{contractFee.title}</TableHeaderColumn>
      <TableHeaderColumn className={'hidden sm:table-cell'} alignment={TableCellAlignment.Center}>
        <p className="m-auto sm:max-w-48 lg:max-w-72 xl:max-w-96 truncate text-sm text-gray-500 font-light">
          {contractFee.note}
        </p>
      </TableHeaderColumn>
      <TableHeaderColumn alignment={TableCellAlignment.Right}>
        {formatCurrency(contractFee.amount, contractFee.currency)}
      </TableHeaderColumn>
    </TableRow>
  );
}
