import { NailAppointmentConfirmationStatus, NailAppointmentItemFragment } from '@client/graphql/types/graphql.ts';
import { useTranslate } from '@tolgee/react';

interface StatItem {
  name: string;
  value: string | number;
  unit?: string;
}

const NailAppointmentListStats = (props: { loading?: boolean; items: NailAppointmentItemFragment[] }) => {
  const { items } = props;
  const { t } = useTranslate();
  // const branchProvider = useFocusedBranchContext();
  // const branchID = branchProvider.branch?.id ?? '';
  // const { data: capacityData } = useGetCurrentAppointmentCapacity(branchID);

  const stats: StatItem[] = [
    {
      name: 'Bookings',
      value: items.filter((item) => item.confirmationStatus === NailAppointmentConfirmationStatus.Confirmed).length,
    },
    {
      name: 'Guests',
      value: items.filter((item) => item.confirmationStatus === NailAppointmentConfirmationStatus.Confirmed).length,
    },
    {
      name: 'Waiting',
      value: items.filter((item) => item.confirmationStatus === NailAppointmentConfirmationStatus.Waiting).length,
    },

    {
      name: 'Cancelled',
      value: items.filter((item) => item.confirmationStatus === NailAppointmentConfirmationStatus.Cancelled).length,
    },
  ];
  //
  // if (!capacityData) {
  //   return null;
  // }
  //
  // const capacity =
  //   capacityData.getCurrentAppointmentCapacity !== null
  //     ? // @ts-expect-error wrong validate
  //       capacityData.getCurrentAppointmentCapacity > 0
  //       ? capacityData.getCurrentAppointmentCapacity
  //       : 0
  //     : undefined;

  return (
    <div className="px-6 py-2 flex flex-col space-y-0">
      <div className="text-lg text-gray-700">
        <span className="">
          {stats[0]?.value} {t('reservation.schedule.stats.booking', 'Bookings')} / {stats[1]?.value}{' '}
          {t('reservation.schedule.stats.guests', 'Guests')}
        </span>
      </div>
      <div>
        <span className="text-sm text-gray-500">
          {stats[2]?.value} {t('reservation.schedule.stats.waiting', 'Waitings')} / {stats[3]?.value}{' '}
          {t('reservation.schedule.stats.cancellation', 'Cancellations')}
        </span>
      </div>
      {/*{capacity !== undefined && (*/}
      {/*  <div>*/}
      {/*    <span className="text-sm text-gray-500">*/}
      {/*      {t('reservation.schedule.capacity', 'Current capacity')}: {capacity}*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default NailAppointmentListStats;
