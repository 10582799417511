import { graphql } from '@client/graphql/types';

export const ContractFragment = graphql(`
  fragment ContractItem on CompanyContract {
    id
    companyId
    categoryCode
    hasPayed
    startTime
    endTime
    serviceTitle
    customerName
    customerNote
    paymentAmount
    paymentCurrency
    companyContractFee {
      ...ContractFeeItem
    }
    companyContractChange {
      ...ContractChangeItem
    }
    companyContractCategory {
      ...ContractCategoryItem
    }
  }
`);

export const ContractWithStatusFragment = graphql(`
  fragment ContractItemWithStatus on CompanyContractWithStatus {
    id
    companyId
    categoryCode
    hasPayed
    startTime
    endTime
    serviceTitle
    customerName
    customerNote
    status
    paymentAmount
    paymentCurrency
    companyContractFee {
      ...ContractFeeItem
    }
    companyContractChange {
      ...ContractChangeItem
    }
    companyContractCategory {
      ...ContractCategoryItem
    }
  }
`);

export const ContractFeeFragment = graphql(`
  fragment ContractFeeItem on CompanyContractFee {
    id
    title
    note
    amount
    currency
    isDeleted
    companyContractFeeChange {
      ...ContractFeeChangeItem
    }
  }
`);

export const ContractChangeFragment = graphql(`
  fragment ContractChangeItem on CompanyContractChange {
    id
    data {
      key
      new
      old
    }

    userId
    user {
      username
      firstName
      lastName
    }

    contractId
    timestamp
  }
`);

export const ContractFeeChangeFragment = graphql(`
  fragment ContractFeeChangeItem on CompanyContractFeeChange {
    id
    data {
      key
      new
      old
    }

    userId
    user {
      username
      firstName
      lastName
    }

    contractFeeId
    timestamp
  }
`);

export const ContractCategoryFragment = graphql(`
  fragment ContractCategoryItem on CompanyContractCategory {
    code
    title
  }
`);
