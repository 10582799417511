import { ContractItemWithStatusFragment, ContractStatus } from '@client/graphql/types/graphql.ts';
import { useTranslate } from '@tolgee/react';
import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import TableRow from '@packages/ui/table/table-row.tsx';
import { getFragmentData } from '@client/graphql/types';
import { ContractCategoryFragment } from '@client/graphql/contract-fragment.ts';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import { useNavigate } from 'react-router-dom';

export default function ContractTable(props: {
  contracts: readonly ContractItemWithStatusFragment[];
  loading: boolean;
  refetch: () => void;
  error?: string;
}) {
  const { t } = useTranslate();

  if (props.contracts.length === 0) {
    return <div className="p-4 border-t">{t('contract.list.no-orders-found')}</div>;
  }

  return (
    <Table
      loading={props.loading}
      data={props.contracts}
      error={props.error}
      refetch={props.refetch}
      build={(contract) => <ContractRow contract={contract} />}
    >
      <TableHeaderColumn className="hidden sm:table-cell" alignment={TableCellAlignment.Left}>
        {t('contract.table-header-column.category')}
      </TableHeaderColumn>
      <TableHeaderColumn alignment={TableCellAlignment.Center}>
        {t('contract.table-header-column.contract')}
      </TableHeaderColumn>
      <TableHeaderColumn alignment={TableCellAlignment.Center}>
        {t('contract.table-header-column.status')}
      </TableHeaderColumn>
      <TableHeaderColumn className="hidden sm:table-cell" alignment={TableCellAlignment.Center}>
        {t('contract.table-header-column.start')}
      </TableHeaderColumn>
      <TableHeaderColumn className="hidden sm:table-cell" alignment={TableCellAlignment.Center}>
        {t('contract.table-header-column.end')}
      </TableHeaderColumn>
      <TableHeaderColumn alignment={TableCellAlignment.Right}>
        {t('contract.table-header-column.payment')}
      </TableHeaderColumn>
      <TableHeaderColumn alignment={TableCellAlignment.Right}>
        {t('contract.table-header-column.manager')}
      </TableHeaderColumn>
    </Table>
  );
}

function ContractRow(props: { contract: ContractItemWithStatusFragment; navigationPrefix?: string }) {
  const { contract } = props;
  const navigate = useNavigate();
  const { t } = useTranslate();
  const contractCategory = getFragmentData(ContractCategoryFragment, contract.companyContractCategory);

  return (
    <TableRow
      onClick={() => {
        navigate(`../detail/${contract.id}`);
      }}
      key={contract.id}
    >
      <TableHeaderColumn className="hidden sm:table-cell" alignment={TableCellAlignment.Left}>
        {contractCategory.title}
      </TableHeaderColumn>
      <TableHeaderColumn className="" alignment={TableCellAlignment.Center}>
        {contract.id}
      </TableHeaderColumn>
      <TableHeaderColumn className="" alignment={TableCellAlignment.Center}>
        <ContractStatusMessage status={contract.status} t={t} />
      </TableHeaderColumn>
      <TableHeaderColumn className="hidden sm:table-cell" alignment={TableCellAlignment.Center}>
        {contract.startTime}
      </TableHeaderColumn>
      <TableHeaderColumn className="hidden sm:table-cell" alignment={TableCellAlignment.Center}>
        {contract.endTime}
      </TableHeaderColumn>
      <TableHeaderColumn className="" alignment={TableCellAlignment.Right}>
        {formatCurrency(contract.paymentAmount, contract.paymentCurrency)}
      </TableHeaderColumn>
      <TableHeaderColumn className="" alignment={TableCellAlignment.Right}>
        {contract.customerName}
      </TableHeaderColumn>
    </TableRow>
  );
}

function ContractStatusMessage(props: { status: ContractStatus; t: (value: string) => string }) {
  const { status, t } = props;
  let colorCode = '#000000';

  if (status === ContractStatus.NotPayed) {
    colorCode = '#FF4D4F';
  } else if (status === ContractStatus.ExpireSoon) {
    colorCode = '#FFC53D';
  } else if (status === ContractStatus.Ended) {
    colorCode = '#40A9FF';
  } else {
    colorCode = '#52C41A';
  }

  return (
    <div className="flex items-center justify-center gap-x-1">
      <div className="w-4 h-4 rounded-full" style={{ backgroundColor: colorCode }}></div>
      <p>{t(`contract.list.content-status.${status}`)}</p>
    </div>
  );
}
