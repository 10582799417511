import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation CreateTemporaryAvailabilityLimitation($branchId: ID!, $input: CreateTemporaryAvailabilityLimitationInput!) {
    createTemporaryAvailabilityLimitation(branchId: $branchId, input: $input) {
      id
      service
      startTime
      endTime
      capacity
      message
      branchId
      createdAt
    }
  }
`);

export default function useCreateTemporary() {
  return useMutation(MUTATION, {
    refetchQueries: ['getTemporaryAvailabilityLimitations'],
  });
}
