import Card from '@packages/ui/card/card.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import useCreateMenuProduct from '@client/page/management/menu/menu-product-create/logic/use-create-menu-product.ts';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import MenuSectionSelection from '@client/page/management/menu/menu-section/menu-section-selection-input/menu-section-selection.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import { ContentLayout, FullPrimaryContent } from 'ui/src/content-layout';
import { client } from '@client/graphql/client.ts';
import ImageInput from '@packages/core/uploading/image-input.tsx';
import { Helmet } from 'react-helmet-async';
import {
  MenuProductConfiguration,
  MenuProductLabel,
  MenuProductPriceMode,
  ProductConfigurationType,
} from '@client/graphql/types/graphql';
import MenuProductLabelSelect from '@client/page/management/menu/menu-product-create/component/menu-product-label-select.tsx';
import { useTranslate } from '@tolgee/react';
import { v4 as uuidv4 } from 'uuid';
import SimpleProductPriceModeConfiguration from '@client/page/management/menu/menu-product-create/component/simple-product-price-mode-configuration.tsx';
import ConvertPriceModeButton from '@client/page/management/menu/menu-product-create/component/covert-price-mode-button.tsx';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { captureException } from '@sentry/browser';
import { useCompanyCurrency, useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import CheckBoxInput from '@packages/ui/form/input/check-box-input.tsx';
import useGetAllergies from '@client/page/management/menu/allergy/logic/use-get-allergies.ts';
import Loading from '@packages/ui/loading.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import AllergyInput from '@client/page/management/menu/allergy/component/allergy-input.tsx';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import QuickCreateSection
  from '@client/page/management/menu/menu-product-create/component/menu-section-quick-create.tsx';
import {
  removeLeadingAndTrailingCommas,
} from '@client/page/management/menu/menu-product-create/logic/remove-leading-and-trailing-commas.tsx';
import MenuProductConfigurationCreateTable from './component/menu-product-configuration-create-table';

interface MenuProductForm {
  title: string;
  description: string;
  images: string[];
  ingredients: string[];
  sectionId: string | null;
  configurations: MenuProductConfiguration[];
  code: string;
  discountable: boolean;
  labels: MenuProductLabel[];
  allergics: string;
  priceMode: MenuProductPriceMode;
}

const DESCRIPTION_MAX_LENGTH = 200;

export default function MenuProductCreate() {
  const rawBaseConfigurationId = useRef(uuidv4());
  const rawBaseConfigurationValueId = useRef(uuidv4());
  const rawOptionConfigurationId = useRef(uuidv4());

  const companyId = useCompanyId();
  const menuId = useParams().menuId ?? '';
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { showAlert } = useNotificationCenter();
  const currency = useCompanyCurrency();
  const dialog = useDialog();
  const { t } = useTranslate();

  const { data: allergyData, loading: allergyLoading, error: allergyError } = useGetAllergies(companyId);
  const [create, { loading, error }] = useCreateMenuProduct();
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const {
    handleSubmit,
    setError,
    clearErrors,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<MenuProductForm>({
    defaultValues: {
      title: '',
      description: '',
      images: [],
      ingredients: [],
      sectionId: null,
      discountable: false,
      configurations: [
        {
          id: rawBaseConfigurationId.current,
          title: 'Base',
          type: ProductConfigurationType.Base,
          values: [
            {
              id: rawBaseConfigurationValueId.current,
              price: 0,
              name: '',
            },
          ],
        },
        {
          id: rawOptionConfigurationId.current,
          title: 'Option',
          type: ProductConfigurationType.Option,
          values: [],
        },
      ],
      code: '',
      allergics: '',
      labels: [],
      priceMode: searchParams.get('preferredProductPriceMode') as MenuProductPriceMode,
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { update } = useFieldArray({
    control,
    name: 'configurations',
  });

  const dialogManager = useModalManagerContext();
  const handleCreateNewCategory = () => {
    dialogManager.showDialog((onClose) => <QuickCreateSection onClose={onClose} menuId={menuId} setValue={setValue} />);
  };

  if (allergyLoading) {
    return <Loading />;
  }

  if (!allergyData) {
    return <>Allergies data not available.</>;
  }

  // Check configuation just have 2 values: base config and option config
  const baseAndOption =
    getValues('configurations').length == 2 &&
    getValues('configurations').find((config) => config.type == ProductConfigurationType.Base) &&
    getValues('configurations').find((config) => config.type == ProductConfigurationType.Option);

  const onSubmit = async (data: MenuProductForm) => {
    try {
      const result = await create({
        variables: {
          menuId: menuId,
          input: {
            ...data,
            allergics: removeLeadingAndTrailingCommas(data.allergics),
            discountable: !data.discountable,
            configurations: processConfigurationData(data.configurations),
          },
        },
      });
      if (result.data?.createMenuProduct) {
        showAlert({
          status: 'success',
          title: 'Success',
          message: 'New menu product has been created',
        });
        const sectionId = getValues('sectionId');
        reset(undefined, { keepDefaultValues: true });
        setValue('sectionId', sectionId);
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof MenuProductForm,
            {
              type: 'server',
              message: t(validationError[field] ?? 'validation-error.common.alert'),
            },
            { shouldFocus: true }
          );
        }
      }
      captureException(err);
    }
  };

  // convert between 2 price mode
  const converPriceMode = () => {
    const priceMode = watch('priceMode');
    if (priceMode == MenuProductPriceMode.Simple) {
      // setChangePriceMode(MenuProductPriceMode.Matrix);
      setValue('priceMode', MenuProductPriceMode.Matrix);
      return;
    }

    if (!baseAndOption) {
      dialog.destructAlert({
        title: 'Convert problem',
        message: 'You can not convert from matrix mode to simple mode',
        textButton: 'Done',
        onConfirm: async () => {
          return Promise.resolve();
        },
      });
      return;
    }
    // setChangePriceMode(MenuProductPriceMode.Simple);
    setValue('priceMode', MenuProductPriceMode.Simple);
  };

  function processConfigurationData(configurations: MenuProductConfiguration[]) {
    return configurations
    .map(({ title, type, values }) => {
      return {
        title,
        type,
        values: values.map(({ name, price }, valueIndex) => {
          let processName = name;
          if (
            getValues('priceMode') == MenuProductPriceMode.Simple &&
            type == ProductConfigurationType.Base &&
            valueIndex == 0 &&
            values.length == 1
          ) {
            processName = getValues('title');
          }
          return {
            name: processName,
            price: normalizeCurrency(String(price), currency),
          };
        }),
      };
    })
    .filter(({ type, values }) => !(type === ProductConfigurationType.Option && values.length === 0));
  }

  return (
    <>
      <div className="grid grid-cols-1  xl:grid-cols-7 w-full  gap-4">
        <ContentLayout className="xl:col-span-4">
          <Helmet>
            <title>{t('menu.menu-detail.product.create.helmet.title')}</title>
            <meta name="description" content="This page allows you to create a new product" />
          </Helmet>

          <ApplicationErrorView error={allergyError} />

          <FullPrimaryContent>
            <Card>
              <CardHeader title={t('menu.menu-detail.product.create.header')}>
                <ConvertPriceModeButton covertPriceMode={converPriceMode} />
              </CardHeader>

              <CardContent>
                <FormLayout>
                  <div className="flex gap-2">
                    <FormItem className="flex-1 max-w-lg" title={''}>
                      <Controller
                        control={control}
                        name="sectionId"
                        render={({ field: { onChange, value } }) => (
                          <MenuSectionSelection menuId={menuId} value={value} onChange={onChange} />
                        )}
                      />
                    </FormItem>
                    <PrimaryButton className="mt-10 h-9" onClick={() => {
                      handleCreateNewCategory();
                    }}>{t('menu.menu-detail.product.create.quick-create-category.button', 'New')}</PrimaryButton>
                  </div>

                  <div>
                    <div className="max-w-lg grid sm:grid-cols-4 gap-3" title={''}>
                      <FormItem
                        className="max-w-lg sm:col-span-1"
                        title={t('menu.menu-detail.product.create.form-input.code.title')}
                      >
                        <Controller
                          control={control}
                          rules={{
                            required: t(
                              'menu.menu-detail.product.create.form-input.code.validate.required',
                              'Code is required'
                            ),
                            minLength: {
                              value: 1,
                              message: t(
                                'menu.menu-detail.product.create.form-input.code.validate.min-length',
                                'Min length is 1'
                              ),
                            },
                          }}
                          name="code"
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              data-testid="code-input"
                              type="text"
                              label="code"
                              name=""
                              placeholder={t('menu.menu-detail.product.create.form-input.code.placeholder')}
                              value={value}
                              error={errors.code?.message}
                              autoComplete={'off'}
                              onChange={onChange}
                            />
                          )}
                        />
                      </FormItem>

                      <FormItem
                        className="max-w-lg sm:col-span-3"
                        title={t('menu.menu-detail.product.create.form-input.name.title', 'Title')}
                      >
                        <Controller
                          rules={{
                            required: t(
                              'menu.menu-detail.product.create.form-input.name.validate.required',
                              'Code is required'
                            ),
                            minLength: {
                              value: 3,
                              message: t(
                                'menu.menu-detail.product.create.form-input.name.validate.min-length',
                                'Min length is 3'
                              ),
                            },
                            maxLength: {
                              value: 100,
                              message: t(
                                'menu.menu-detail.product.create.form-input.name.validate.max-length',
                                'Max length is 100'
                              ),
                            },
                            pattern: {
                              value: /^\S.*\S$/,
                              message: t(
                                'menu.menu-detail.product.create.form-input.name.validate.no-whitespace',
                                'Menu product title must not start or end with whitespace'
                              ),
                            },
                          }}
                          control={control}
                          name="title"
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              data-testid="title-input"
                              type="text"
                              label="title"
                              name=""
                              placeholder={t('menu.menu-detail.product.create.form-input.name.placeholder')}
                              value={value}
                              error={errors.title?.message}
                              autoComplete={'off'}
                              onChange={onChange}
                            />
                          )}
                        />
                      </FormItem>
                    </div>
                  </div>

                  <FormItem
                    subtitle={`(${String(getValues('description').length)}/${String(DESCRIPTION_MAX_LENGTH)})`}
                    className="max-w-lg"
                    title={t('menu.menu-detail.product.create.form-input.description.title')}
                  >
                    <Controller
                      control={control}
                      name="description"
                      render={({ field: { onChange, value } }) => (
                        <textarea
                          className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 min-h-36"
                          placeholder={t('menu.menu-detail.product.create.form-input.description.placeholder')}
                          value={value}
                          name=""
                          onChange={onChange}
                          maxLength={DESCRIPTION_MAX_LENGTH}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem
                    className="max-w-lg"
                    title={t('menu.menu-detail.product.create.form-input.allergic', 'Allergic')}
                  >
                    <Controller
                      control={control}
                      name="allergics"
                      rules={{
                        validate: (value) =>
                          !value.includes(';') || t('validation.no-semicolon', 'Allergic field cannot contain ";"'),
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <AllergyInput
                          errorMessage={error?.message}
                          allergyString={value}
                          handleEachAllergyCode={(value: string) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem title="Label" className="max-w-md">
                    <Controller
                      control={control}
                      name="labels"
                      render={({ field: { onChange, value } }) => (
                        <MenuProductLabelSelect
                          label={value}
                          handleLabel={(value, selected) => {
                            if (selected) {
                              onChange([...getValues('labels'), value]);
                            } else {
                              onChange(getValues('labels').filter((label) => label !== value));
                            }
                          }}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem
                    className="max-w-lg"
                    title={t('menu.menu-detail.product.create.form-input.discountable.title', 'Additional options')}
                  >
                    <Controller
                      control={control}
                      name="discountable"
                      render={({ field: { onChange, value } }) => (
                        <CheckBoxInput
                          value={value}
                          onChange={onChange}
                          name={t(
                            'menu.menu-detail.product.create.form-input.discountable.checkbox',
                            'No Discountable',
                          )}
                        />
                      )}
                    />
                  </FormItem>

                  <div>
                    <FormItem title={t('menu.menu-detail.product.create.form-input.image.title', 'Logo')}>
                      <Controller
                        control={control}
                        name="images"
                        render={({ field: { onChange, value } }) => (
                          <ImageInput client={client} images={value} onChange={onChange} multiple={true} />
                        )}
                      />
                      <p className="text-red-500 text-sm mt-2">{validationError.images}</p>
                    </FormItem>
                  </div>

                  <div>
                    <Controller
                      control={control}
                      name="configurations"
                      render={({ field: { onChange, value } }) => (
                        <MenuProductConfigurationCreateTable
                          isDisplayed={getValues('priceMode') == MenuProductPriceMode.Matrix}
                          data={value}
                          configurationHandle={onChange}
                        />
                      )}
                    />

                    {validationError.configurations && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {validationError.configurations}
                      </p>
                    )}
                  </div>
                  {/*<ApplicationErrorView error={error} />*/}
                </FormLayout>
              </CardContent>

              <CardFooter>
                <div className="flex justify-center space-x-4 w-96">
                  <SecondaryButton
                    className="flex-1"
                    onClick={() => {
                      navigate('..');
                    }}
                  >
                    {t('common.button.cancel')}
                  </SecondaryButton>
                  <PrimaryButton
                    id="create-button"
                    data-testid="create-button"
                    className="w-32 flex-1"
                    onClick={() => {
                      clearErrors();
                      handleSubmit(onSubmit)().catch(captureException);
                    }}
                    loading={loading}
                  >
                    {t('common.button.create')}
                  </PrimaryButton>
                </div>
              </CardFooter>
            </Card>
          </FullPrimaryContent>
        </ContentLayout>

        <div className=" xl:col-span-3 [1300px]:col-span-2 xl:max-w-[600px]">
          <SimpleProductPriceModeConfiguration
            fields={getValues('configurations')}
            update={update}
            control={control}
            errors={errors}
            isDisplayed={getValues('priceMode') == MenuProductPriceMode.Simple}
          />
        </div>
      </div>
    </>
  );
}

