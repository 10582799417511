import { Feature, Service } from '@client/graphql/types/graphql';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useTranslate } from '@tolgee/react';
import useTableReservationSetting from '@client/page/management/table-reservation/setting/logic/use-table-reservation-setting.ts';
import useUpdateTableReservationSetting from '@client/page/management/table-reservation/setting/logic/use-update-table-reservation-setting.ts';
import FormItem from '@packages/ui/form/form-item.tsx';
import ToggleInput from '@packages/ui/form/input/toggle-input.tsx';
import useAppointmentBranchConfiguration from '@client/page/management/appointment/configuration/logic/use-appointment-branch-configuration.ts';
import useUpdateAppointmentConfiguration from '@client/page/management/appointment/configuration/logic/use-update-appointment-configuration.ts';
import { getFragmentData } from '@client/graphql/types';
import { NailAppointmentConfigurationFragment } from '@client/graphql/nail-appointment-configuration-fragment.ts';
import useOnlineOrderBranchSetting from '@client/page/management/online-order/setting/logic/use-online-order-branch-setting.ts';
import useUpdateOnlineOrderConfiguration from '@client/page/management/online-order/setting/logic/use-update-online-order-branch-setting.ts';
import { OnlineOrderBranchSettingFragment } from '@client/graphql/online-order-branch-setting-fragment.ts';
import { useCompanyService } from 'core/src/company/company-hook';
import mapCapacityServiceToFeature from '@client/page/management/availability-limitation/utils/map-capacity-service-to-feature.ts';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import Loading from '@packages/ui/loading.tsx';

export default function CapacitySetting(props: { service: Service }) {
  const company = useCompanyService();
  const { t } = useTranslate();
  const features = company.company?.features;
  const currentFeature = mapCapacityServiceToFeature(props.service);

  if (!features?.some((item) => item.name == currentFeature)) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={t('capacity-setting.header')} />
      <CardContent>
        {currentFeature == Feature.TableReservationCapacity && <ReservationCapacitySetting />}
        {currentFeature == Feature.OnlineOrderCapacity && <OnlineOrderCapacitySetting />}
        {currentFeature == Feature.NailAppointmentCapacity && <AppoitnmentCapacitySetting />}
      </CardContent>
    </Card>
  );
}

function ReservationCapacitySetting() {
  const notificationCenter = useNotificationCenter();
  const dialog = useDialog();

  const { t } = useTranslate();

  const branchProvider = useFocusedBranchContext();
  const branchId = branchProvider.branch?.id ?? '';
  const { data, refetch, loading } = useTableReservationSetting(branchId);
  const [update] = useUpdateTableReservationSetting();

  const handleSubmit = (value: boolean) => {
    if (!data) {
      notificationCenter.showAlert({
        title: t('alert.title.success'),
        message: t('reservation.setting.alert.message.error'),
        status: 'error',
      });
      return;
    }
    dialog.confirmAlert({
      title: t('reservation.setting.alert.confirm.title'),
      message: t('reservation.setting.alert.confirm.message'),
      textButton: t('common.button.update'),
      onConfirm: async () => {
        await update({
          variables: {
            id: data.tableReservationConfiguration.id,
            input: {
              capacityEnabled: value,
            },
          },
        });

        await refetch();

        notificationCenter.showAlert({
          title: t('alert.title.success'),
          message: t('reservation.setting.alert.message.success'),
          status: 'success',
        });
      },
    });
  };

  if (!data) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <FormItem title={t('reservation.setting.form-input.flex-time.capacity-enable-toggle.title')}>
        <p className="text-sm text-gray-500 italic my-4">
          {t('reservation.setting.form-input.flex-time.capacity-enable-toggle.description')}
        </p>
        <ToggleInput
          value={data.tableReservationConfiguration.capacityEnabled}
          onChange={(newValue) => {
            handleSubmit(newValue);
          }}
        />
      </FormItem>
    </>
  );
}

function AppoitnmentCapacitySetting() {
  const notificationCenter = useNotificationCenter();
  const dialog = useDialog();

  const { t } = useTranslate();

  const branchProvider = useFocusedBranchContext();
  const branchId = branchProvider.branch?.id ?? '';
  const { data, refetch, loading } = useAppointmentBranchConfiguration(branchId);
  const [update] = useUpdateAppointmentConfiguration();

  const handleSubmit = (value: boolean) => {
    if (!data) {
      notificationCenter.showAlert({
        title: t('alert.title.success'),
        message: t('appointment.setting.alert.message.error'),
        status: 'error',
      });
      return;
    }
    dialog.confirmAlert({
      title: t('appointment.setting.alert.confirm.title'),
      message: t('appointment.setting.alert.confirm.message'),
      textButton: t('common.button.update'),
      onConfirm: async () => {
        await update({
          variables: {
            branchId: branchId,
            input: {
              capacityEnabled: value,
            },
          },
        });

        await refetch();

        notificationCenter.showAlert({
          title: t('alert.title.success'),
          message: t('appointment.setting.alert.message.success'),
          status: 'success',
        });
      },
    });
  };

  if (!data) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  const appointmentSetting = getFragmentData(NailAppointmentConfigurationFragment, data.appointmentBranchConfiguration);

  return (
    <>
      <FormItem title={t('appointment.setting.form-input.flex-time.capacity-enable-toggle.title')}>
        <p className="text-sm text-gray-500 italic my-4">
          {t('appointment.setting.form-input.flex-time.capacity-enable-toggle.description')}
        </p>
        <ToggleInput
          value={appointmentSetting.capacityEnabled}
          onChange={(newValue) => {
            handleSubmit(newValue);
          }}
        />
      </FormItem>
    </>
  );
}

function OnlineOrderCapacitySetting() {
  const notificationCenter = useNotificationCenter();
  const dialog = useDialog();

  const { t } = useTranslate();

  const branchProvider = useFocusedBranchContext();
  const branchId = branchProvider.branch?.id ?? '';
  const { data, refetch, loading } = useOnlineOrderBranchSetting(branchId);
  const [update] = useUpdateOnlineOrderConfiguration();

  const handleSubmit = (value: boolean) => {
    if (!data) {
      notificationCenter.showAlert({
        title: t('alert.title.success'),
        message: t('online-order.setting.alert.message.error'),
        status: 'error',
      });
      return;
    }
    dialog.confirmAlert({
      title: t('online-order.setting.alert.confirm.title'),
      message: t('online-order.setting.alert.confirm.message'),
      textButton: t('common.button.update'),
      onConfirm: async () => {
        await update({
          variables: {
            branchID: branchId,
            input: {
              capacityEnabled: value,
            },
          },
        });

        await refetch();

        notificationCenter.showAlert({
          title: t('alert.title.success'),
          message: t('online-order.setting.alert.message.success'),
          status: 'success',
        });
      },
    });
  };

  if (!data) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  const onlineOrderSetting = getFragmentData(OnlineOrderBranchSettingFragment, data.onlineOrderBranchConfiguration);

  if (!onlineOrderSetting) {
    return null;
  }

  return (
    <>
      <FormItem title={t('online-order.setting.form-input.flex-time.capacity-enable-toggle.title')}>
        <p className="text-sm text-gray-500 italic my-4">
          {t('online-order.setting.form-input.flex-time.capacity-enable-toggle.description')}
        </p>
        <ToggleInput
          value={onlineOrderSetting.capacityEnabled}
          onChange={(newValue) => {
            handleSubmit(newValue);
          }}
        />
      </FormItem>
    </>
  );
}
