import { Service, TemporaryAvailabilityLimitationItemFragment } from '@client/graphql/types/graphql.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useTranslate } from '@tolgee/react';
import useUpdateTemporary from '@client/page/management/availability-limitation/logic/use-update-temporary.ts';
import { Controller, useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/browser';
import { useEffect } from 'react';
import { useFocusedCompanyContext } from '@packages/core/company/focused-company-context.ts';
import moment from 'moment-timezone';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import useDeleteTemporary from '@client/page/management/availability-limitation/logic/use-delete-temporary.ts';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';

interface AvailabilityTemporaryUpdateProps {
  capacity: number;
  startHour: string;
  endHour: string;
  startDate: string;
  endDate: string;
  message?: string;
}

export default function AvailabilityTemporaryUpdate(props: {
  service: Service;
  data: TemporaryAvailabilityLimitationItemFragment;
  onClose: () => void;
}) {
  const timezone = useFocusedCompanyContext().settings.timezone;

  const { showAlert } = useNotificationCenter();
  const { t } = useTranslate();

  const [update, { loading, error }] = useUpdateTemporary();
  const [deleteTemporary, { error: deleteError }] = useDeleteTemporary();

  const {
    handleSubmit,
    clearErrors,
    setError,
    control,
    reset,
    formState: { errors },
  } = useForm<AvailabilityTemporaryUpdateProps>({
    defaultValues: {
      capacity: 0,
      startHour: '',
      endHour: '',
      startDate: '',
      endDate: '',
      message: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  useEffect(() => {
    reset({
      capacity: props.data.capacity,
      startHour: moment.tz(props.data.startTime, timezone).format('HH:mm'),
      endHour: moment.tz(props.data.endTime, timezone).format('HH:mm'),
      startDate: moment.tz(props.data.startTime, timezone).format('YYYY-MM-DD'),
      endDate: moment.tz(props.data.endTime, timezone).format('YYYY-MM-DD'),
      message: props.data.message ?? '',
    });
  }, [props.data, reset, timezone]);

  const onSubmit = (values: AvailabilityTemporaryUpdateProps) => {
    update({
      variables: {
        id: props.data.id,
        input: {
          capacity: Number(values.capacity),
          message: values.message,
          startTime: `${values.startDate}T${values.startHour}`,
          endTime: `${values.endDate}T${values.endHour}`,
        },
      },
    })
      .then(() => {
        showAlert({
          title: t('availability-temporary.create.success', 'Successful'),
          status: 'success',
          message: t('availability-temporary.update.mess', 'Temporary availability limitation has been updated'),
        });
        props.onClose();
      })
      .catch((err) => {
        if (err instanceof ApolloError) {
          const applicationErrors = formatGraphQlError(err.graphQLErrors);
          const validationError = validationErrors(applicationErrors);

          for (const field in validationError) {
            setError(
              field as keyof AvailabilityTemporaryUpdateProps,
              {
                type: 'server',
                message: t(validationError[field] ?? 'validation-error.common.alert'),
              },
              { shouldFocus: true }
            );
          }
        }
        captureException(err);
      });
  };

  return (
    <Card>
      <CardHeader
        title={t('availability-temporary.update.header', 'Temporary capacity update for {service}', {
          service: t(props.service),
        })}
      />

      <CardContent>
        <FormLayout>
          <FormItem className="max-w-lg" title={t('availability-temporary.create.capacity.header')}>
            <Controller
              control={control}
              name="capacity"
              rules={{
                required: t('availability-temporary.create.capacity.validate.required', 'Capacity require number'),
                min: {
                  value: 1,
                  message: t(
                    'availability-temporary.create.capacity.validate.min',
                    'Capacity must be greater than {amount}',
                    {
                      amount: 0,
                    }
                  ),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextInput
                    data-testid="title-input"
                    type="number"
                    label="capacity"
                    name="capacity"
                    placeholder={t('availability-temporary.create.capacity.placeholder')}
                    value={value}
                    error={errors.capacity?.message}
                    autoComplete={'off'}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </FormItem>

          <FormItem title={t('availability-temporary.create.range-date.header')}>
            <div className="flex flex-col sm:flex-row gap-4">
              <Controller
                control={control}
                name="startDate"
                rules={{
                  required: t('availability-temporary.create.startDate.validate.required', 'Start date required'),
                  validate: (value, formValues) => {
                    if (value && formValues.endDate) {
                      return (
                        formValues.endDate >= value ||
                        t(
                          'availability-temporary.create.endTime.validate.compare',
                          'Start date must be smaller than end date'
                        )
                      );
                    }
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <input
                      className="rounded-md max-w-full border-gray-300"
                      aria-label="Start date"
                      id="startDate"
                      name="startDate"
                      value={value}
                      type="date"
                      onChange={(value) => {
                        clearErrors('endDate');
                        onChange(value);
                      }}
                    />
                    {errors.startDate && (
                      <p className="text-red-500 text-sm mt-2 max-w-36">{errors.startDate.message}</p>
                    )}
                  </div>
                )}
              />

              <Controller
                control={control}
                name="endDate"
                rules={{
                  required: t('availability-temporary.create.endDate.validate.required', 'End date required'),
                  validate: (value, formValues) => {
                    if (value && formValues.startDate) {
                      return (
                        formValues.startDate <= value ||
                        t(
                          'availability-temporary.create.endTime.validate.compare',
                          'End date must be greater than start date'
                        )
                      );
                    }
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <input
                      className="rounded-md max-w-full border-gray-300"
                      aria-label="End date"
                      id="endDate"
                      name="endDate"
                      value={value}
                      type="date"
                      onChange={(value) => {
                        clearErrors('startDate');
                        onChange(value);
                      }}
                    />
                    {errors.endDate && <p className="text-red-500 text-sm mt-2 max-w-36">{errors.endDate.message}</p>}
                  </div>
                )}
              />
            </div>
          </FormItem>

          <FormItem title={t('availability-temporary.create.range-time.header')}>
            <div className="flex flex-col sm:flex-row gap-4">
              <Controller
                control={control}
                name="startHour"
                rules={{
                  required: t('availability-temporary.create.startHour.validate.required', 'Start hour required'),
                  validate: (value, formValues) => {
                    if (value && formValues.endHour) {
                      return (
                        formValues.endHour > value ||
                        t(
                          'availability-temporary.create.startHour.validate.compare',
                          'Start hour must be smaller than end hour'
                        )
                      );
                    }
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <input
                      className="rounded-md max-w-full border-gray-300"
                      aria-label="Start hour"
                      id="startHour"
                      name="startHour"
                      value={value}
                      type="time"
                      onChange={(value) => {
                        clearErrors('endHour');
                        onChange(value);
                      }}
                    />
                    {errors.startHour && (
                      <p className="text-red-500 text-sm mt-2 max-w-36">{errors.startHour.message}</p>
                    )}
                  </div>
                )}
              />

              <Controller
                control={control}
                name="endHour"
                rules={{
                  required: t('availability-temporary.create.endHour.validate.required', 'End hour required'),
                  validate: (value, formValues) => {
                    if (value && formValues.startHour) {
                      return (
                        formValues.startHour < value ||
                        t(
                          'availability-temporary.create.endHour.validate.compare',
                          'End hour must be greater than start hour'
                        )
                      );
                    }
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <input
                      className="rounded-md max-w-full border-gray-300"
                      aria-label="End hour"
                      id="endHour"
                      name="endHour"
                      value={value}
                      type="time"
                      onChange={(value) => {
                        clearErrors('startHour');
                        onChange(value);
                      }}
                    />
                    {errors.endHour && <p className="text-red-500 text-sm mt-2 max-w-36">{errors.endHour.message}</p>}
                  </div>
                )}
              />
            </div>
          </FormItem>

          <FormItem className="max-w-md" title={t('availability-temporary.create.description.title')}>
            <Controller
              control={control}
              name="message"
              render={({ field: { onChange, value } }) => (
                <textarea
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                             focus:ring-indigo-600 sm:text-sm sm:leading-6 min-h-44"
                  defaultValue={value ?? ''}
                  onChange={(newValue) => {
                    onChange(newValue.target.value.trim());
                  }}
                />
              )}
            />
          </FormItem>
        </FormLayout>
      </CardContent>

      {validationError.rangeTimeConflicts && (
        <AlertError
          title={t('availability.capacity-rule-conflict', 'Capacity rule conflict')}
          message={validationError.rangeTimeConflicts}
        />
      )}

      <ApplicationErrorView error={deleteError} />

      <CardFooter>
        <div className="flex items-center w-full justify-between">
          <DestroyButton
            onClick={() => {
              deleteTemporary({
                variables: {
                  id: props.data.id,
                },
              })
                .then(() => {
                  showAlert({
                    title: t('temporary.remove-alert.title', 'Successfull'),
                    message: t('temporary.remove-alert.message', 'Availability limitation temporary has been deleted'),
                    status: 'success',
                  });
                  props.onClose();
                })
                .catch((err: Error) => {
                  showAlert({
                    title: t('temporary.remove-alert.title.error', 'Error'),
                    message: err.message,
                    status: 'error',
                  });
                });
            }}
          >
            {t('common.button.delete')}
          </DestroyButton>
          <div className="flex items-center justify-end gap-x-2">
            <SecondaryButton onClick={props.onClose}>{t('common.button.cancel')}</SecondaryButton>
            <PrimaryButton
              loading={loading}
              onClick={() => {
                clearErrors();
                handleSubmit(onSubmit)().catch(captureException);
              }}
            >
              {t('common.button.update')}
            </PrimaryButton>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
