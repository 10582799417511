import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation UpdateScheduleAvailabilityLimitation(
    $id: ID!
    $timeRuleInput: ScheduledLimitationTimeRuleInput!
    $input: UpdateScheduledAvailabilityLimitationInput!
  ) {
    updateScheduleAvailabilityLimitation(id: $id, timeRuleInput: $timeRuleInput, input: $input) {
      id
      service
      timeRule {
        daysOfWeeks
        startTime
        endTime
      }
      capacity
      message
      isDeleted
      branchId
      createdAt
    }
  }
`);

export default function useUpdateSchedule() {
  return useMutation(MUTATION, {
    refetchQueries: ['getScheduleAvailabilityLimitations'],
  });
}
