import { Feature } from '@client/graphql/types/graphql.ts';
import { useSetFeature } from '@client/page/management/organisation-manager/company-feature/logic/use-set-feature.ts';
import { useTranslate } from '@tolgee/react';
import ToggleInput from '@packages/ui/form/input/toggle-input.tsx';
import { useEffect, useState } from 'react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import Spinner from '@packages/ui/spinner.tsx';

export default function FeatureEnable(props: { companyId: string; feature: Feature; enabled: boolean }) {
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();

  const [enabled, setEnabled] = useState<boolean | null>(null);
  const [update, { loading }] = useSetFeature();

  useEffect(() => {
    setEnabled(props.enabled);
  }, [props.enabled, setEnabled]);

  if (enabled == null) {
    return null;
  }

  const handleChange = (value: boolean) => {
    update({
      variables: {
        companyId: props.companyId,
        name: props.feature,
        enabled: value,
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.success'),
          message: t(`organisation.alert.${props.feature}`),
        });
        // navigate('..');
      })
      .catch((err: Error) => {
        showAlert({
          status: 'error',
          title: t('alert.error'),
          message: err.message,
        });
      });
  };

  return (
    <div>
      {/*Header*/}
      <div className="p-4 bg-gray-100">
        <p className="text-xl font-semibold">{t(`feature-enable.header.${props.feature}`)}</p>
      </div>

      {/*Content*/}
      <div className="p-4 flex justify-between">
        <div>
          <p>{t(`feature-enable.header.capacity`)}</p>
        </div>
        <div className="flex items-center gap-x-1">
          {loading && <Spinner />}
          <ToggleInput
            value={enabled}
            onChange={(value) => {
              handleChange(value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
