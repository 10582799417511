import { useMutation } from '@apollo/client';
import { graphql } from '@client/graphql/types';

export const CREATE_MENU_SECTION = graphql(`
  mutation createMenuSection($menuId: ID!, $input: CreateMenuSection!, $timeRuleInput: CreateMenuSectionTimeRule!) {
    createMenuSection(menuId: $menuId, input: $input, timeRuleInput: $timeRuleInput) {
      id
      name
    }
  }
`);

export function useCreateMenuSection() {
  return useMutation(CREATE_MENU_SECTION, {refetchQueries:['GetMenuSections']});
}
