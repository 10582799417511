import { Service } from '@client/graphql/types/graphql.ts';
import { useCompanyService } from '@packages/core/company/company-hook.ts';
import mapCapacityServiceToFeature from '@client/page/management/availability-limitation/utils/map-capacity-service-to-feature.ts';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useTranslate } from '@tolgee/react';

export default function CapacityPremiumAlert(props: { service: Service }) {
  const company = useCompanyService();
  const { t } = useTranslate();

  const companyFeatures = company.company?.features;
  if (companyFeatures?.some((feature) => feature.name == mapCapacityServiceToFeature(props.service))) {
    return null;
  }

  return (
    <div className={'rounded-md bg-green-100 p-4'}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium font-semibold">{t('premium.capacity.alert.header')}</h3>
          <div className="mt-2 text-sm text-gray-700">
            <pre className="text-wrap">{t('premium.capacity.alert.message')}</pre>
          </div>
        </div>
      </div>
    </div>
  );
}
