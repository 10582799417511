import { Link } from 'react-router-dom';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import Table from '@packages/ui/table/table.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { FragmentType, getFragmentData } from '@client/graphql/types';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import { MenuSectionFragment } from '@packages/network-graphql/common/menu-section-fragment.ts';
import { MenuProductConfigurationFragment } from '@packages/network-graphql/common/menu-product-configuration-fragment.ts';
import { MenuProductConfigurationValueFragment } from '@packages/network-graphql/common/menu-product-configuration-value-fragment.ts';
import { useTranslate } from '@tolgee/react';
import { naturalCompare } from '@client/module/utils/natural-compare.ts';
import { PencilIcon } from '@heroicons/react/24/outline';
import {
  groupServiceItemsBySection,
  ServiceBySection,
} from '@client/page/management/appointment/service/service-detail/component/group-service-item-by-section.tsx';
import { ServiceItemFragment } from '@packages/network-graphql/graphql/types/graphql.ts';

export default function ServiceTable(props: {
  products: ServiceItemFragment[];
  sections: FragmentType<typeof MenuSectionFragment>[];
}) {
  const { t } = useTranslate();
  const sections = getFragmentData(MenuSectionFragment, props.sections);
  const sortedProductMenuBySections = groupServiceItemsBySection(props.products, sections).sort((a, b) =>
    naturalCompare(a.section?.code ?? '', b.section?.code ?? '')
  );
  return (
    <Table
      data={sortedProductMenuBySections}
      build={(group) => <Group key={group.section?.id ?? 'no-section'} group={group} />}
    >
      <TableHeaderColumn className="w-24">{t('common.table-header-column.code', 'Code')}</TableHeaderColumn>
      <TableHeaderColumn>{t('common.table-header-column.name', 'Name')}</TableHeaderColumn>
      <TableHeaderColumn alignment={TableCellAlignment.Right}>
        {' '}
        {t('common.table-header-column.price', 'Price')}
      </TableHeaderColumn>
    </Table>
  );
}

function Group(props: { group: ServiceBySection }) {
  const { group } = props;
  const currency = useCompanyCurrency();

  return (
    <>
      <TableSection key={group.section?.id} colSpan={4}>
        <div className="flex justify-center">
          {group.section && (
            <Link className="flex space-x-2 items-center" to={`sections/${group.section.id}/edit`}>
              <p>{group.section.name.length > 0 ? group.section.name : 'No name'}</p>
              <PencilIcon className="w-4 h-4" />
            </Link>
          )}
        </div>
      </TableSection>

      {group.items
        .sort((a, b) => naturalCompare(a.code, b.code))
        .map((prop) => (
          <TableRow key={prop.id}>
            <TableRowColumn className="w-24">
              <Link to={`products/${prop.id}`}>
                <p className="font-semibold text-gray-800">{prop.code}</p>
              </Link>
            </TableRowColumn>

            <TableRowColumn>
              <Link to={`products/${prop.id}`}>
                <p className="">{prop.title}</p>
              </Link>
            </TableRowColumn>

            <TableRowColumn alignment={TableCellAlignment.Right}>
              <Link to={`products/${prop.id}`}>
                <div className="flex space-x-2 justify-end items-end">
                  {prop.configurations.some(
                    (config) => getFragmentData(MenuProductConfigurationFragment, config).values.length > 1
                  ) && <p className="text-gray-400">Start at</p>}
                  <p className="text-gray-500">
                    {formatCurrency(
                      getFragmentData(MenuProductConfigurationFragment, prop.configurations)
                        .find((config) => config.type.includes('BASE'))
                        ?.values.map((configValue) =>
                          getFragmentData(MenuProductConfigurationValueFragment, configValue)
                        )
                        .at(0)?.price ?? 0,
                      currency
                    )}
                  </p>
                </div>
              </Link>
            </TableRowColumn>
          </TableRow>
        ))}
    </>
  );
}
