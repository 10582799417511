import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const CONTRACTS = graphql(`
  query ContractWithAllFees($contractId: ID!) {
    contractWithAllFees(id: $contractId) {
      ...ContractItem
    }
  }
`);

export function useContractWithAllFee(contractId: string) {
  return useQuery(CONTRACTS, {
    variables: {
      contractId,
    },
  });
}
