import { useParams, useNavigate } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';
import { useAuth } from '@client/module/auth/auth-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useCompanySettings } from '@client/page/management/company-settings/logic/use-company-setting.ts';
import { useUpdateContract } from '@client/page/management/contract/contract-update/logic/use-update-contract.ts';
import { Controller, useForm } from 'react-hook-form';
import { useContract } from '@client/page/management/contract/contract-detail/logic/use-contract.ts';
import { useEffect } from 'react';
import { getFragmentData } from '@client/graphql/types';
import { ContractCategoryFragment, ContractFeeFragment, ContractFragment } from '@client/graphql/contract-fragment.ts';
import { availableCurrencies, formatRawCurrency, normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/browser';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import moment from 'moment-timezone';
import Loading from '@packages/ui/loading.tsx';
import ContractFeeUpdateTable from '@client/page/management/contract/contract-update/component/contract-fee-update-table.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { ContractFeeForm } from '@client/page/management/contract/contract-create/contract-create.tsx';
import {
  ContractHasPayedItem,
  ContractItem,
} from '@client/page/management/contract/contract-detail/contract-detail.tsx';
import { paymentData } from '@client/page/management/contract/contract-detail/component/payment-data.ts';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';

interface ContractForm {
  hasPayed: boolean;
  startTime: string;
  endTime: string;
  serviceTitle: string;
  customerName: string;
  customerNote?: string;
  paymentAmount: number;
  paymentCurrency: string;
  contractFeeData: ContractFeeForm[];
}

const DESCRIPTION_LIMIT = 500;

export default function ContractUpdate() {
  const companyId = useParams().companyId ?? '';
  const contractId = useParams().contractId ?? '';

  const { t } = useTranslate();
  const { auth } = useAuth();
  const { showAlert } = useNotificationCenter();
  const navigate = useNavigate();
  const dialog = useDialog();

  const { data: companySettingData, loading: settingLoading } = useCompanySettings(companyId);
  const { data: contractRawData, loading: contractLoading } = useContract(contractId);

  const [update] = useUpdateContract();

  const {
    handleSubmit,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<ContractForm>({
    defaultValues: {
      hasPayed: false,
      startTime: '',
      endTime: '',
      serviceTitle: '',
      customerName: '',
      customerNote: '',
      paymentAmount: 0,
      paymentCurrency: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  useEffect(() => {
    if (!contractRawData || !companySettingData) return;
    const contractData = getFragmentData(ContractFragment, contractRawData.contract);

    reset({
      hasPayed: contractData.hasPayed,
      startTime: moment
        .tz(contractData.startTime, companySettingData.company?.settings.timezone ?? 'Europe/Berlin')
        .format('YYYY-MM-DD'),
      endTime: moment
        .tz(contractData.endTime, companySettingData.company?.settings.timezone ?? 'Europe/Berlin')
        .format('YYYY-MM-DD'),
      serviceTitle: contractData.serviceTitle,
      customerName: contractData.customerName,
      customerNote: contractData.customerNote ?? '',
      paymentAmount: parseInt(formatRawCurrency(contractData.paymentAmount, contractData.paymentCurrency).value),
      paymentCurrency: contractData.paymentCurrency,
    });
  }, [contractRawData, companySettingData, reset]);

  const loading = contractLoading || settingLoading;

  if (loading) {
    return <Loading />;
  }

  if (!companySettingData) {
    return <>Company data is empty</>;
  }

  if (!contractRawData) {
    return <>Contract data is empty</>;
  }

  const contractData = getFragmentData(ContractFragment, contractRawData.contract);
  const contractCategoryData = getFragmentData(ContractCategoryFragment, contractData.companyContractCategory);
  const contractFees = getFragmentData(ContractFeeFragment, contractData.companyContractFee);

  const isContractManager = auth?.role.includes('CONTRACT_MANAGER');

  if (!isContractManager) {
    return <>Permission denied</>;
  }

  const discard = () => {
    dialog.confirmAlert({
      title: t('contract-update.discard.header'),
      message: t('contract-update.discard.message'),
      textButton: t('common.button.confirm'),
      onConfirm: () => {
        reset(undefined, { keepDefaultValues: false, keepDirty: false });
        navigate('..');
        return Promise.resolve();
      },
    });
  };

  const onSubmit = async (form: ContractForm) => {
    try {
      const result = await update({
        variables: {
          id: contractId,
          contractData: {
            hasPayed: form.hasPayed,
            startTime: form.startTime,
            endTime: form.endTime,
            serviceTitle: form.serviceTitle,
            customerName: form.customerName,
            customerNote: form.customerNote,
            paymentAmount: normalizeCurrency(form.paymentAmount.toString(), form.paymentCurrency),
            paymentCurrency: form.paymentCurrency,
          },
        },
      });

      if (result.data?.updateContract) {
        showAlert({
          status: 'success',
          title: t('contract.update.success.title'),
          message: t('contract.update.success.message'),
        });

        if (result.data.updateContract.id !== contractId) {
          navigate(`../../detail/${result.data.updateContract.id}`);
          return;
        }

        navigate(`../../detail/${result.data.updateContract.id}`);
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof ContractForm,
            {
              type: 'server',
              message: t(validationError[field] ?? 'validation-error.common.alert'),
            },
            { shouldFocus: true }
          );
        }
      }
      captureException(err);
    }
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader
            title={t('contract.update.header', 'Update contract')}
            withBackButton={true}
            backTarget={`../../detail/${contractId}`}
          >
            <SecondaryButton onClick={discard}>{t('common.button.discard', 'Discard')}</SecondaryButton>
            <PrimaryButton
              disabled={!isDirty}
              onClick={() => {
                clearErrors();
                handleSubmit(onSubmit)().catch(captureException);
              }}
            >
              {t('common.button.Save', 'Save')}
            </PrimaryButton>
          </CardHeader>
        </Card>

        <Card>
          <CardHeader title={t('contract.create.data.header')} />
          <CardContent>
            <FormLayout>
              <div className="flex flex-col sm:flex-row gap-y-8 gap-x-20">
                <ContractItem
                  label={t('contract.contract-detail.contract-number.title', 'Contract number')}
                  value={contractData.id}
                />
                <ContractItem
                  label={t('contract.contract-detail.contract-category.title', 'Contract category')}
                  value={contractCategoryData.title}
                />
              </div>

              {/*paymentAmount*/}
              {/*paymentCurrency*/}
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-6 max-w-lg">
                  <FormItem className="max-w-lg" title={t('contract.create.currency.header')}>
                    <Controller
                      control={control}
                      name="paymentCurrency"
                      rules={{
                        required: t(
                          'contract-create.paymentCurrency.validate.required',
                          'Payment currency is required'
                        ),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <MenuSelectionInput
                            title={''}
                            data={availableCurrencies}
                            value={value}
                            onChange={onChange}
                            build={(item) => {
                              return {
                                id: item,
                                name: item,
                              };
                            }}
                          />
                          {errors.paymentCurrency && (
                            <p className="text-red-500 text-sm mt-2">{errors.paymentCurrency.message}</p>
                          )}
                        </>
                      )}
                    />
                  </FormItem>

                  <FormItem className="max-w-lg" title={t('contract.create.amount.header')}>
                    <Controller
                      control={control}
                      name="paymentAmount"
                      rules={{
                        required: t('contract-create.amount.validate.required', 'Payment amount require number'),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextInput
                            data-testid="title-input"
                            type="number"
                            label="paymentAmount"
                            name="paymentAmount"
                            placeholder={t('contract.create.amount.placeholder')}
                            value={value}
                            error={undefined}
                            autoComplete={'off'}
                            onChange={onChange}
                          />
                          {errors.paymentAmount && (
                            <p className="text-red-500 text-sm mt-2">{errors.paymentAmount.message}</p>
                          )}
                        </>
                      )}
                    />
                  </FormItem>

                  <FormItem className="max-w-lg -mt-1" title={''}>
                    <Controller
                      control={control}
                      name="hasPayed"
                      render={({ field: { onChange, value } }) => (
                        <>
                          <ContractHasPayedItem
                            label={t('contract.contract-detail.payment-status.title', 'Payment status')}
                          >
                            <MenuSelectionInput
                              title={''}
                              data={paymentData}
                              value={paymentData.find((item) => item.value == value) ?? paymentData[0]}
                              onChange={(newValue) => {
                                onChange(newValue.value);
                              }}
                              build={(item: { title: string; value: boolean }) => {
                                return {
                                  id: item.value.toString(),
                                  name: t('contract.contract-detail.payment-status.' + item.title),
                                  suffixElement: item.value ? (
                                    <CheckCircleIcon className="w-6 h-6 text-green-600" />
                                  ) : (
                                    <ExclamationCircleIcon className="w-6 h-6 text-red-600" />
                                  ),
                                };
                              }}
                            />
                          </ContractHasPayedItem>
                          {errors.hasPayed && <p className="text-red-500 text-sm mt-2">{errors.hasPayed.message}</p>}
                        </>
                      )}
                    />
                  </FormItem>
                </div>
              </div>

              {/*startTime*/}
              {/*endTime*/}
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-x-4 gap-y-6 max-w-lg">
                  <FormItem title={t('contract.create.start-date.header')}>
                    <Controller
                      control={control}
                      name="startTime"
                      rules={{
                        required: t('contract-create.startTIime.validate.required', 'Start time is required'),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <input
                          className="rounded-md w-full border-gray-300"
                          aria-label="Start Date"
                          id="start"
                          name="start"
                          value={value}
                          type="date"
                          onChange={(newValue) => {
                            if (watch('endTime') && moment(newValue.target.value).isAfter(moment(watch('endTime')))) {
                              setError('startTime', {
                                type: 'validate',
                                message: t('contract.create.time-error.message', 'Start time must be before end time'),
                              });
                              return;
                            }
                            onChange(newValue);
                          }}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem title={t('contract.create.end-date.header')}>
                    <Controller
                      control={control}
                      name="endTime"
                      rules={{
                        required: t('contract-create.endTime.validate.required', 'End time is required'),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <input
                          className="rounded-md w-full border-gray-300"
                          aria-label="Start Date"
                          id="end"
                          name="end"
                          value={value}
                          type="date"
                          onChange={(newValue) => {
                            if (
                              watch('startTime') &&
                              moment(newValue.target.value).isBefore(moment(watch('startTime')))
                            ) {
                              setError('endTime', {
                                type: 'validate',
                                message: t('contract.create.time-error.message', 'End time must be after start time'),
                              });
                              return;
                            }
                            onChange(newValue);
                          }}
                        />
                      )}
                    />
                  </FormItem>
                </div>
                {errors.startTime && <p className="text-red-500 text-sm mt-2">{errors.startTime.message}</p>}
                {errors.endTime && <p className="text-red-500 text-sm mt-2">{errors.endTime.message}</p>}
              </div>

              {/*serviceTitle*/}
              <FormItem className="max-w-lg" title={t('contract.create.serviceTitle.header')}>
                <Controller
                  control={control}
                  name="serviceTitle"
                  rules={{
                    required: t('contract-create.serviceTitle.validate.required', 'Service title is required'),
                    validate: (value) =>
                      value.trim() !== '' ||
                      t('contract-create.serviceTitle.validate.required', 'Service title is required'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      data-testid="title-input"
                      type="text"
                      label="serviceTitle"
                      name="serviceTitle"
                      placeholder={t('contract.create.serviceTitle.placeholder')}
                      value={value}
                      error={errors.serviceTitle?.message}
                      autoComplete={'off'}
                      onChange={onChange}
                    />
                  )}
                />
              </FormItem>

              {/*customerNote*/}
              <FormItem className="max-w-lg" title={t('contract.create.customer-note.header')}>
                <Controller
                  control={control}
                  name="customerNote"
                  render={({ field: { onChange, value } }) => (
                    <textarea
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                             focus:ring-indigo-600 sm:text-sm sm:leading-6 min-h-44"
                      maxLength={DESCRIPTION_LIMIT}
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormItem>

              {/*customerName*/}
              <FormItem className="max-w-lg" title={t('contract.create.customerName.header')}>
                <Controller
                  control={control}
                  name="customerName"
                  rules={{
                    required: t('contract-create.customerName.validate.required', 'Customer name is required'),
                    validate: (value) =>
                      value.trim() !== '' ||
                      t('contract-create.customerName.validate.required', 'Customer name is required'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      data-testid="title-input"
                      type="text"
                      label="customerName"
                      name="customerName"
                      placeholder={t('contract.create.customerName.placeholder')}
                      value={value}
                      error={errors.customerName?.message}
                      autoComplete={'off'}
                      onChange={onChange}
                    />
                  )}
                />
              </FormItem>
            </FormLayout>
          </CardContent>
        </Card>

        <ContractFeeUpdateTable data={contractFees ?? []} />
      </PrimaryContent>
    </ContentLayout>
  );
}
